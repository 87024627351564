import React, { useState, useEffect } from 'react'
import CloudModal from './CloudModal'
import { Button, Dimmer, Header, Icon, Loader, Message, MessageContent, MessageHeader, Segment, Select, Step, StepContent, StepDescription, StepGroup, StepTitle, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react'
import { localStorageCloudValue } from '../../../Config/appConstant'
import { generateReport, getAccountsList, getReportStatus, getReportTaskHistory, parseStateMachineDefinitions } from './service'

export default function OktaReport(props) {
    const [selectCloud, setSelectCloud] = useState("")
    const [openModal, setOpenModal] = useState(true)
    const [accounts, setAccounts] = useState({ data: [], isLoading: false, errMsg: null })
    const [searchedResult, setSearchedResult] = useState({ isLoading: false, data: null, errMsg: "", status: "" })
    const [searchedStateResult, setSearchedStateResult] = useState({ isLoading: false, data: null, errMsg: "", status: "" })
    const [searchedTaskHistory, setSearchedTaskHistory] = useState({ isLoading: false, data: [], errMsg: "", status: "", currentState: null })
    const [account, setAccount] = useState([])
    const [message, setMessage] = useState({ color: 'red', message: "", active: false })
    const [intervalState, setIntervalState] = useState(null)
    const [nodes, setNodes] = useState(null)
    const [edges, setEdges] = useState(null)
    const [loading, setLoading] = useState(false)
    const [errorHistory, setErrorHistory] = useState([])
    const [invokeResult, setInvokeResult] = useState([])

    useEffect(() => {
        let value = localStorage.getItem(localStorageCloudValue)
        if (value !== null && value !== "") {
            setSelectCloud(value)
            setOpenModal(false)
        }
    }, [])

    const getAccounts = () => {
        setAccounts({ data: [], isLoading: true, errMsg: null })
        getAccountsList(localStorage.getItem(localStorageCloudValue), props?.authState?.accessToken?.accessToken).then((result) => {
            setAccounts({ data: result?.data?.body?.data, isLoading: false, errMsg: null })
        }).catch(err => {
            console.error(err)
            setAccounts({ data: [], isLoading: false, errMsg: err?.message })
            setMessage({ color: 'red', message: "Something went wrong!", active: true })
        })
    }

    useEffect(() => {
        if (props.authState.accessToken.accessToken) {
            getAccounts()
        }
    }, [selectCloud])

    const handleSubmit = () => {
        setErrorHistory([])
        setMessage({ color: 'red', message: "", active: false })
        setSearchedResult({ isLoading: true, data: null, errMsg: "", status: "" })
        setSearchedStateResult({ isLoading: false, data: null, errMsg: "", status: "" })
        setSearchedTaskHistory({ isLoading: false, data: [], errMsg: "", status: "", currentState: null })
        setInvokeResult([])
        if (account?.length === 0) {
            alert("Please select inputs.")
            return
        }
        generateReport(selectCloud, props.authState.accessToken.accessToken, { Accounts: account, Source: "cem-portal" }).then(res => {

            if (res?.data && JSON.parse(res.data?.data?.result)?.statusCode === 200) {
                let url = JSON.parse(res.data?.data?.result)?.StateMachineUrl
                setTimeout(() => {
                    getReportStatus(selectCloud, props.authState.accessToken.accessToken,
                        `aws-okta-report?status=${url?.split("/")[8]}`).then((item) => {
                            const { nodes, edges } = parseStateMachineDefinitions(item?.data?.data?.definition)
                            setSearchedStateResult({ ...searchedStateResult, data: item?.data })
                            fetchCurrentState(item?.data?.data?.sm_arn)
                            setNodes(nodes)
                            setEdges(edges)
                            setSearchedResult({
                                isLoading: false,
                                data: res.data?.data,
                                status: JSON.parse(res.data?.data?.result)?.statusCode,
                                errMsg: '',
                            })
                            
                            let invokedAccounts = Object.keys(JSON.parse(JSON.parse(res.data?.data?.result)?.body))
                            let keyVaueResults = JSON.parse(JSON.parse(res.data?.data?.result)?.body)
                            let storeResult = []
                            
                            invokedAccounts?.map(accountItem=>{
                                if(!keyVaueResults[accountItem]?.includes("Access Report generation has been started")){
                                    storeResult.push({
                                        "error_info": { 
                                        "error": keyVaueResults[accountItem],
                                        "cause": "",
                                        "account_id": accountItem,
                                        "account_name": accounts?.data?.find((i)=>i?.key===accountItem)?.accountName
                                        }
                                    })
                                }
                            })
                            setInvokeResult(storeResult)
                        })
                        .catch(err => {
                            console.error(err)
                            setMessage({ color: 'red', active: true, message: 'Something went wrong! Please try again later.' })
                        })
                }, 3000)
            }
            else {
                setSearchedResult({
                    isLoading: false,
                    data: res.data?.data,
                    errMsg: 'Something went wrong! Please try again later.',
                    status: JSON.parse(res.data?.data?.result)?.statusCode
                })

                if (JSON.parse(res.data?.data?.result)?.body?.includes("ExecutionAlreadyExists")) {
                    setMessage({ color: 'red', active: true, message: 'Execution Already Exists. Please wait...' })
                }
                else {
                    setMessage({ color: 'red', active: true, message: 'Something went wrong! Please try again later.' })
                }
            }
        }).catch(err => {
            console.error(err)
            setSearchedResult({ isLoading: false, data: null, errMsg: err?.message })
            setMessage({ color: 'red', active: true, message: err?.response?.data?.message || err?.message })
        })
    }

    const fetchCurrentState = (url) => {
        const intervalId = setInterval(() => {
            getReportTaskHistory(selectCloud, props.authState.accessToken.accessToken,
                `aws-okta-report-status?sm_arn=${url}`)
                .then((response) => {
                    if (!["ReceiveMessage", "CheckMessage", "DeleteMessage"]?.includes(response.data?.data?.current_state)) {
                        setSearchedTaskHistory((prevState) => ({
                            ...prevState,
                            data: prevState.data.concat([response.data?.data]),
                            currentState: response.data?.data
                        }))
                    }
                }).catch(err => {
                    console.error(err)
                    setSearchedTaskHistory({ isLoading: false, data: [], errMsg: "", status: "", currentState: null })
                    clearInterval(intervalId)
                    setMessage({ color: 'red', active: true, message: err?.message })
                })
        }, 5000)
        setIntervalState(intervalId)
    }

    useEffect(() => {
        if (searchedTaskHistory?.data?.some(item => item?.status === "SUCCEEDED")) {
            clearInterval(intervalState)
            getReportTaskHistory(selectCloud, props.authState.accessToken.accessToken,
                `aws-okta-report-history?sm_arn=${searchedStateResult?.data?.data?.sm_arn}`)
                .then((response) => {
                    setErrorHistory(response?.data?.data?.error_state)
                }).catch(err => {
                    console.error(err)
                })
            setLoading(false)
        }
    }, [searchedTaskHistory])

    return (
        <React.Fragment>
            <Segment
                size="mini"
                style={{
                    padding: "30px",
                    borderColor: "blue",
                    borderStyle: "Solid",
                    margin: "6px",
                }}>
                <Dimmer active={searchedResult?.isLoading || loading} inverted>
                    <Loader>Please wait...</Loader>
                </Dimmer>
                {message.active ? (
                    <Message color={message.color} content={message.message} onDismiss={() => setMessage((pre)=>({ ...pre,color: 'red', active: false }))} />
                ) : null}
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
                <Header as="h3" color="blue">Okta Access Report</Header>
               <div>
               <CloudModal openModal={openModal} selectCloud={selectCloud} setOpenModal={setOpenModal} setSelectCloud={setSelectCloud} />
                </div>
                </div>
                <div style={{ display: "flex" }}>
                    <Select
                        loading={accounts?.isLoading}
                        search style={{ minHeight: "40px", width: "300px" }}
                        placeholder='Select Accounts'
                        options={accounts?.data?.map((item)=>{
                            return{
                                key:item?.key,
                                text:item?.text,
                                value:item?.value
                            }
                        })}
                        onChange={(e, d) => {
                            setAccount(d.value)
                            setErrorHistory([])
                            setInvokeResult([])
                            setSearchedResult({ isLoading: false, data: null, errMsg: "", status: "" })
                            setSearchedStateResult({ isLoading: false, data: null, errMsg: "", status: "" })
                            setSearchedTaskHistory({ isLoading: false, data: [], errMsg: "", status: "", currentState: null })
                        }}
                        multiple
                        value={account}
                    >
                    </Select>
                    <Button style={{ margin: "0px 0px 0px 20px", height: 40 }} onClick={() => {
                        handleSubmit()
                    }}>Submit</Button>
                </div>

                {searchedTaskHistory?.currentState?.current_state === "Finish" ? (
                    <div style={{ marginTop: "50px", marginBottom: "10px" }}>
                        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                            <Table celled fixed singleLine>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell>Account Name</TableHeaderCell>
                                        <TableHeaderCell>Account ID</TableHeaderCell>
                                        <TableHeaderCell>Status</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {account?.map((item, key) => {
                                        let success = [...errorHistory,...invokeResult]?.some((failed)=>failed?.error_info?.account_id === item)
                                        if(success){
                                            return (
                                                <TableRow key={key} negative >
                                                    <TableCell>{accounts?.data?.find((account)=>account?.key === item)?.accountName}</TableCell>
                                                    <TableCell>{accounts?.data?.find((account)=>account?.key === item)?.key}</TableCell>
                                                    <TableCell>
                                                        <Icon name='cancel' />
                                                        Failed</TableCell>
                                                </TableRow>
                                            )
                                        }
                                        else{
                                            return (
                                                <TableRow key={key} positive>
                                                    <TableCell>{accounts?.data?.find((account)=>account?.key === item)?.accountName}</TableCell>
                                                    <TableCell>{accounts?.data?.find((account)=>account?.key === item)?.key}</TableCell>
                                                    <TableCell>
                                                        <Icon name='checkmark'/>Report Generated</TableCell>
                                                </TableRow>
                                            )
                                        }
                                        
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                ) : null}

                {((searchedTaskHistory?.currentState !== null) && (searchedTaskHistory?.currentState?.current_state !== "Finish")) ? (
                    <Message icon>
                        {searchedTaskHistory?.currentState?.current_state !== "Finish" ? (<Icon name='circle notched' color='blue' loading />)
                            : (<Icon name='check' color='green' />)}
                        <MessageContent>
                            <MessageHeader>{nodes?.find(item => searchedTaskHistory?.currentState?.current_state === item?.id)?.text} for {searchedTaskHistory?.currentState?.input_data !== null ? (JSON.parse(searchedTaskHistory?.currentState?.input_data?.Messages[0]?.Body)?.okta_profile_name?.replace(/-/g, ' ')) : null}</MessageHeader>
                            {searchedTaskHistory?.currentState?.current_state === "Finish" ? 'Successfully report generated!' : 'Please wait...'}
                        </MessageContent>
                    </Message>
                ) : null}

                {
                    ((searchedStateResult?.data !== null && searchedTaskHistory?.currentState === null) && message.message !== "Network Error") ? (
                        <Message icon>
                            <Icon name='circle notched' color='blue' loading />
                            <MessageContent>
                                <MessageHeader>In progress</MessageHeader>
                                Please wait...
                            </MessageContent>
                        </Message>
                    ) : null
                }               
            </Segment>
        </React.Fragment>
    )
}
