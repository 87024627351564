import axios from "axios";
import config from "../../../Config/Config";
import { commercialCloud } from "../../../Config/appConstant";

export const getAccountsList = (selectedCloud, token) => new Promise((resolve,reject)=>{
    const selectedUrl = selectedCloud?.toLowerCase() === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
        headers: {
            'Authorization': token
        }
    };
    axios.get(selectedUrl + 'account/account-list', options)
        .then(res => {
            resolve(res)
        })
        .catch(function (error) {
            reject(error)
        })
})

export const generateReport = (selectedCloud, token,data)=> new Promise((resolve, reject)=>{
    const selectedUrl = selectedCloud?.toLowerCase() === commercialCloud ? config.apiendpoint : config.govCloudApi
    axios.post(`${selectedUrl}aws-okta-report`,data,{
        headers: {
            'Authorization': token
        } 
    }).then(res=>resolve(res)).catch(err=>{
        console.error(err)
        reject(err)
    })
})

export const getReportStatus = (selectedCloud, token, url)=> new Promise((resolve,reject)=>{
    const selectedUrl = selectedCloud?.toLowerCase() === commercialCloud ? config.apiendpoint : config.govCloudApi
    axios.get(`${selectedUrl}${url}`, {
        headers:{
            'Authorization':token
        }
    })
        .then(res => {
            resolve(res)
        })
        .catch(function (error) {
            reject(error)
        })
})

export const getReportTaskHistory = (selectedCloud,token,url) => new Promise((resolve, reject)=>{
    const selectedUrl = selectedCloud?.toLowerCase() === commercialCloud ? config.apiendpoint : config.govCloudApi
    axios.get(`${selectedUrl}${url}`,{
        headers:{
            'Authorization':token
        }
    }).then((res)=>{
        resolve(res)
    }).catch(err=>{
        reject(err)
    })
})

export const parseStateMachineDefinitions = (definition) => {
    const states = definition.States;
    const nodes = [];
    const edges = [];
    for (const stateName in states) {
        const state = states[stateName];
        if(!["ReceiveMessage","CheckMessage","DeleteMessage"]?.includes(stateName)){

            if("ProcessOktaGroups"=== stateName){
                nodes.push({
                    id: stateName,
                    label: stateName,
                    type: state.Type,
                    text:"Processing Okta Groups",
                    ...(state.Resource && { resource: state.Resource })
                });
            }
            else if("ProcessOktaUsers" === stateName){
                nodes.push({
                    id: stateName,
                    label: stateName,
                    type: state.Type,
                    text:"Processing Okta Users",
                    ...(state.Resource && { resource: state.Resource })
                });
            }
            else if("GenerateReport" === stateName){
                nodes.push({
                    id: stateName,
                    label: stateName,
                    type: state.Type,
                    text:"Generating Report",
                    ...(state.Resource && { resource: state.Resource })
                });
            } 
        }
        if (state.Next) {
            edges.push({
                from: stateName,
                to: state.Next
            });
        }


        if (state.Type === "Choice" && state.Choices) {
            state.Choices.forEach(choice => {
                if (choice.Next) {
                    edges.push({
                        from: stateName,
                        to: choice.Next
                    });
                }
            });

            if (state.Default) {
                edges.push({
                    from: stateName,
                    to: state.Default
                });
            }
        }
    }

    return { nodes, edges };
};