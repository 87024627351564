import React, { useState,useEffect } from 'react'
import { Button, Dropdown, Segment, Grid, Message } from 'semantic-ui-react'
import AddVendor from './AddVendor'
import UpdateLayout from './UpdateVendor'
import DeleteLayout from './DeleteVendor'
import GlobalTrustedVendor from './GlobalDeleteVendor'
import CloudModal from './CloudModal'
import GlobalUpdateTrustedVendor from './GlobalUpdateVendor'
import { localStorageCloudValue } from '../../../../Config/appConstant'
const operationName = {
    ADD: "ADD",
    UPDATE: "UPDATE",
    DELETE: "DELETE"
}
const vendorType = {
    LOCAL:"LOCAL",
    GLOBAL:"GLOBAL"
}
export default function TrustedVendorLayout(props) {
    const [selectedOperation, setSelectedOperation] = useState(operationName.ADD)
    const [selectedVendorType, setSelectedVendorType] = useState(vendorType.LOCAL)
    const [selectCloud, setSelectCloud] = useState("")
    const [openModal, setOpenModal] = useState(true)
    useEffect(() => {
        let value = localStorage.getItem(localStorageCloudValue)
        if(value !== null && value !==""){
            setSelectCloud(value)
            setOpenModal(false)
        }
    }, [])
    return (
        <React.Fragment>
            <Segment
                size="mini"
                style={{
                    padding: "30px",
                    borderColor: "blue",
                    borderStyle: "Solid",
                    margin: "6px",
                }}>
                    <CloudModal openModal={openModal} selectCloud={selectCloud} setOpenModal={setOpenModal} setSelectCloud={setSelectCloud}/>
                <Grid columns={6} stackable>
                    <Grid.Row>
                    <Grid.Column>
                            <label style={{ fontWeight: 700, fontSize: "16px" }}>Select Trusted Vendor</label>
                            <Dropdown
                                placeholder='Select'
                                fluid
                                selection
                                options={[
                                    {
                                        key: vendorType.LOCAL,
                                        text: "Local",
                                        value: vendorType.LOCAL
                                    },
                                    {
                                        key: vendorType.GLOBAL,
                                        text: "Global",
                                        value: vendorType.GLOBAL
                                    }
                                ]}
                                value={selectedVendorType}
                                style={{ marginTop: "10px", width: "100%" }}
                                onChange={(e, { value }) => setSelectedVendorType(value)}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <label style={{ fontWeight: 700, fontSize: "16px" }}>Select Trusted Vendor Action</label>
                            <Dropdown
                                placeholder='Select'
                                fluid
                                selection
                                options={[
                                    {
                                        key: operationName.ADD,
                                        text: "Add",
                                        value: operationName.ADD
                                    },
                                    {
                                        key: operationName.UPDATE,
                                        text: "Update",
                                        value: operationName.UPDATE
                                    },
                                    {
                                        key: operationName.DELETE,
                                        text: "Delete",
                                        value: operationName.DELETE
                                    }
                                ]}
                                value={selectedOperation}
                                style={{ marginTop: "10px", width: "100%" }}
                                onChange={(e, { value }) => setSelectedOperation(value)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {(selectedOperation === operationName.ADD && selectedVendorType == vendorType.LOCAL && selectCloud !=="") ? (<AddVendor vendorType={selectedVendorType} {...props} selectCloud={selectCloud}/>) : null}
                {(selectedOperation === operationName.UPDATE && selectedVendorType == vendorType.LOCAL && selectCloud !=="") ? (<UpdateLayout {...props} selectCloud={selectCloud}/>) : null}
                {(selectedOperation === operationName.DELETE && selectedVendorType == vendorType.LOCAL && selectCloud !=="") ? (<DeleteLayout vendorType={selectedVendorType} {...props} selectCloud={selectCloud} />) : null}
                {(selectedOperation === operationName.ADD && selectedVendorType == vendorType.GLOBAL && selectCloud !=="") ? (<AddVendor vendorType={selectedVendorType} {...props} selectCloud={selectCloud} />) : null}
                {(selectedOperation === operationName.DELETE && selectedVendorType == vendorType.GLOBAL && selectCloud !=="") ? (<GlobalTrustedVendor vendorType={selectedVendorType} {...props} selectCloud={selectCloud} />) : null}
                {(selectedOperation === operationName.UPDATE && selectedVendorType == vendorType.GLOBAL && selectCloud !=="") ? (<GlobalUpdateTrustedVendor vendorType={selectedVendorType} {...props} selectCloud={selectCloud} />) : null}
            </Segment>
        </React.Fragment>
    )
}  
