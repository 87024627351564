import axios from "axios";
import config from "../../../../Config/Config";
import { commercialCloud } from "../../../../Config/appConstant";

const getAccountLists = (token,cloud) => new Promise((resolve, reject) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.get(`${url}account/account-list`, {
        headers: {
            'Authorization': token
        }
    }).then((response) => {
        resolve(response)
    }).catch(error => {
        reject(error)
    })
})

const getMetadataLists = (token,query,cloud) => new Promise((resolve, reject) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.get(`${url}metadata/trusted-partner?MetadataKey=${query}`,
        {
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
})

const createTrustedPartner = (token, payload,cloud) => new Promise((resolve, reject) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.post(`${url}metadata/trusted-partner`, payload,
        {
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
})

const updateTrustedPartner = (token,payload,cloud) => new Promise((resolve,reject)=>{
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.put(`${url}metadata/trusted-partner`,payload,{headers: {
        'Authorization': token
    }}).then((response) => {
        resolve(response)
    }).catch(error => {
        reject(error)
    })
})

const deleteTrustedPartner = (token,payload,cloud) => new Promise((resolve,reject)=>{
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.delete(`${url}metadata/trusted-partner?sortKey=${payload.key}&updateBy=${payload.updateBy}
    &type=${payload.type}&RuleId=${payload?.RuleId}&MetadataKey=${payload?.MetadataKey}`,{
        headers: {
        'Authorization': token
    }}).then((response) => {
        resolve(response)
    }).catch(error => {
        reject(error)
    })
})
const getAccountDetail = (token,payload,cloud)=> new Promise((reject,resolve) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    const {table,key,value} = payload
    axios.get(`${url}query?Table=${table}&Key=${key}&Value=${value}`,{headers:{
        'Authorization': token
    }})
    .then(res=>{
        resolve(res)
    }).catch(err=>{
        reject(err)
    })
})

const getMetadataDetailsFromKey = (token,query,cloud) => new Promise((resolve, reject) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.get(`${url}metadata/search?MetadataKey=${query}`,
        {
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
})

const getAccountList = (token,cloud)=> new Promise((resolve,reject)=>{
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.get(`${url}account/account-list`,{
        headers: {
            'Authorization': token
        }
    }).then((response) => {
        resolve(response)
    }).catch(error => {
        reject(error)
    })
})

export { 
    getAccountLists,
    getMetadataLists, 
    createTrustedPartner,
    updateTrustedPartner, 
    deleteTrustedPartner, 
    getAccountDetail ,
    getMetadataDetailsFromKey,
    getAccountList
}