import React, { useState, useEffect } from 'react'
import { Button, Dimmer, Grid, Icon, Input, Loader, Message } from 'semantic-ui-react'
import { getMetadataLists, createTrustedPartner, getAccountDetail, getMetadataDetailsFromKey, getAccountList } from '../service'
import config from '../../../../../Config/Config'
import axios from "axios"
import { commercialCloud, metadataTableKeys } from '../../../../../Config/appConstant'

export default function AddTrustedPartner(props) {

  const [value, setValue] = useState({ partnerName: "", error: false, message: "" })
  const [partnerIds, setPartnerIds] = useState([{ id: 0, value: '', error: false, message: "" }])
  const [metadata, setMetadata] = useState({
    isLoading: false,
    error: null,
    data: null,
    sortKey: 0,
    listOfTrustedPartner: []
  })
  const [searchedResult, setSearchedResult] = useState({
    data: [],
    isLoading: false,
    message: "",
    openMessage: false,
    error: ""
  })
  const [whiteListTrustedPartner, setWhiteListTrustedPartner] = useState({
    data: [],
    isLoading: false,
    message: "",
    error: ""
  })
  const [blackListTrustedPartner, setBlackListTrustedPartner] = useState({
    data: [],
    isLoading: false,
    message: "",
    error: ""
  })
  const [accountDetail, setAccountDetail] = useState({
    data: [],
    isLoading: false,
    message: "",
    error: ""
  })
  const [accountLists, setAccountLists] = useState({
    data: [],
    isLoading: false,
    message: "",
    error: ""
  })

  const handleChangePartnerName = (e, data) => {
    const newState = { ...value }
    const newAccountIds = [...partnerIds]
    newState.partnerName = data?.value
    newState.error = false

    const findPartnerRecord = metadata.data?.filter((item) => item?.GroupKey?.toLowerCase() === data.value?.toLowerCase())
    if (findPartnerRecord?.length > 0) {
      newState.partnerName = findPartnerRecord[0]?.GroupKey
    }
    else {
      newState.partnerName = data?.value
    }

    if (newState.partnerName.length < 3) {
      newState.error = true
      newState.message = "Length must be >= 3."
      partnerIds.map((partnerItem, index) => {
        newAccountIds[index].error = false
        newAccountIds[index].message = ""
      })
    }
    else if (partnerIds.length > 0) {
      partnerIds.map((partnerItem, index) => {
        if (findPartnerRecord?.some((item) => item?.MetadataValue === partnerItem?.value)) {
          newAccountIds[index].error = true
          newAccountIds[index].message = "Partner account already exists!"
        }
        else if (whiteListTrustedPartner?.data?.some(item => parseInt(item) === parseInt(partnerItem?.value))) {
          newAccountIds[index].error = false
          newAccountIds[index].message = ""
        }
        else if (blackListTrustedPartner?.data?.some(item => parseInt(item) === parseInt(partnerItem?.value))) {
          newAccountIds[index].error = true
          newAccountIds[index].message = "Partner account is not approved!"
        }
        else if (accountLists?.data?.some((item) => parseInt(item?.value) === parseInt(partnerItem?.value))) {
          newAccountIds[index].error = true
          newAccountIds[index].message = "Please enter a valid trusted partner account!"
        }
        else {
          newAccountIds[index].error = false
          newAccountIds[index].message = ""
        }
      })
    }
    setValue(newState)
  }

  const fetchMetadataLists = (token) => {
    const {
      iamGlobalPartner,
      iamPartner,
      s3GlobalPartner,
      s3Partner,
    } = metadataTableKeys

    setMetadata((preData) => ({ ...preData, isLoading: true }))
    let getPartnerType = props?.metaKey
    getMetadataLists(token, getPartnerType, props?.selectCloud).then((response) => {

      if (response?.data?.data.length > 0) {
        let listOfTrustedPartner = []
        let trustedPartnerUniqueName = new Set()
        let sortKeys = []
        response?.data?.data?.map((item, index) => {
          trustedPartnerUniqueName.add(item?.GroupKey)
          sortKeys.push(parseInt(item.SortKey))
        })

        trustedPartnerUniqueName.forEach((item) => {
          listOfTrustedPartner.push({
            key: item,
            text: item,
            value: item
          })
        })

        sortKeys.sort((a, b) => b - a)
        setMetadata((preData) => ({
          ...preData, isLoading: false,
          data: response?.data?.data,
          listOfTrustedPartner: listOfTrustedPartner,
          sortKey: sortKeys[0] + 1
        }))
      }
      else {
        let tableSortKey;
        if(props?.metaKey === iamPartner){
          tableSortKey = 2000
        }
        else if(props?.metaKey === s3Partner){
          tableSortKey = 4000
        }
        else if(props?.metaKey === iamGlobalPartner ){
          tableSortKey = 6000
        }
        else if(props?.metaKey === s3GlobalPartner){
          tableSortKey = 8000
        }

        setMetadata((preData) => ({
          ...preData, isLoading: false,
          sortKey: tableSortKey
        }))
      }

    }).catch(error => {
      if (error?.response?.status === 404) {
        let tableSortKey;
        if(props?.metaKey === iamPartner){
          tableSortKey = 2000
        }
        else if(props?.metaKey === s3Partner){
          tableSortKey = 4000
        }
        else if(props?.metaKey === iamGlobalPartner ){
          tableSortKey = 6000
        }
        else if(props?.metaKey === s3GlobalPartner){
          tableSortKey = 8000
        }
        setMetadata((preData) => ({
          ...preData,
          isLoading: false,
          data: null,
          error: error.message,
          listOfTrustedPartner: [],
          sortKey: tableSortKey
        }))
      }
      else {
        setMetadata((preData) => ({
          ...preData,
          isLoading: false,
          data: null,
          error: error.message,
          listOfTrustedPartner: []
        }))
      }

      console.log(error)
    })
  }

  useEffect(() => {
    let token = props.authState.accessToken.accessToken
    if (token) {
      fetchMetadataLists(token)
      fetchMetadataDetailsFromKey(token)
      fetchAccountList(token)
    }
  }, [props?.selectCloud,props?.ruleId])

  const handleAddMoreInputFields = () => {
    const newInputFields = [...partnerIds]
    newInputFields.push({ id: Date.now(), value: "" })
    setPartnerIds(newInputFields)
  }
  const handleRemoveInputFields = (index) => {
    const newInputFields = partnerIds.filter((_, i) => i !== index);
    setPartnerIds(newInputFields)
  }
  const handleRemoveMessageBox = () => {
    setSearchedResult((prevState) => ({ ...prevState, openMessage: false, error: "", message: "" }))
  }

  const handleSubmit = () => {
    setSearchedResult((preState) => ({ ...preState, isLoading: true, error: "", data: [], openMessage: false, message: "" }))
    let token = props.authState.accessToken.accessToken
    let payload = partnerIds.map((item, index) => {
      return ({
        MetadataKey: props?.metaKey,
        SortKey: metadata?.sortKey + index,
        GroupKey: value?.partnerName,
        LastModifiedBy: props?.authState?.accessToken?.claims?.sub,
        MetadataType: "Portal",
        MetadataValue: item.value,
        Associations: [],
        RuleList: []
      })
    })

    createTrustedPartner(token, payload, props?.selectCloud).then(result => {
      if (result.status === 201) {
        setSearchedResult((preState) => ({
          ...preState,
          isLoading: false,
          error: "",
          data: result,
          message: "Record created successfully!",
          openMessage: true
        }))
        setPartnerIds([{ id: 0, value: '' }])
        setValue({ partnerName: "" })
        fetchMetadataLists(token)
        setTimeout(() => { handleRemoveMessageBox() }, 3000)
      }
      else {
        setSearchedResult((preState) => ({
          ...preState,
          isLoading: false,
          error: "Failed to create record!",
          data: result,
          message: "",
          openMessage: true
        }))
      }
    }).catch(err => {
      setSearchedResult((preState) => ({
        ...preState,
        isLoading: false,
        error: err.message,
        data: [],
        message: "",
        openMessage: true
      }))
      console.error(err)
    })
  }
  const fetchMetadataDetailsFromKey = (token) => {
    let blackListMetaKey = props?.ruleId === 1007 ? metadataTableKeys?.s3BlackList : metadataTableKeys?.iamBlackList
    let whiteListMetaKey = props?.ruleId === 1007 ? metadataTableKeys?.s3WhiteList : metadataTableKeys?.iamWhiteList

    getMetadataDetailsFromKey(token, blackListMetaKey, props?.selectCloud).then((response) => {
      const result = response?.data?.body?.data
      if (result?.length > 0) {

        setBlackListTrustedPartner({ data: result[0]?.Metadata[blackListMetaKey]?.TrustedPartners[0]?.MetadataValue })
      }
      getMetadataDetailsFromKey(token, whiteListMetaKey, props?.selectCloud).then((response) => {
        const reponseData = response?.data?.body?.data
        setWhiteListTrustedPartner({ data: reponseData[0]?.Metadata[whiteListMetaKey]?.TrustedPartners[0]?.MetadataValue })
      }).catch(err => {
        console.error(err)
      })
    }).catch(err => {
      console.error(err)
    })
  }
  const fetchAccountList = (token) => {
    getAccountList(token, props?.selectCloud).then((res) => {
      setAccountLists({ data: res.data?.body?.data })
    }).catch(err => console.error(err))
  }

  const { partnerName } = value
  return (
    <React.Fragment>
      <div>
        <Dimmer active={searchedResult.isLoading || accountDetail.isLoading || metadata.isLoading} inverted>
          <Loader />
        </Dimmer>
      </div>
      <div style={{ width: "100%", top: 0, position: "absolute", left: 0 }}>
        <div style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }} >
          {searchedResult.openMessage ? <Message size="large" style={{ width: "40%" }} onDismiss={handleRemoveMessageBox}
            content={searchedResult.message === "" ? searchedResult.error : searchedResult.message}
            color={searchedResult.message === "" ? "red" : "green"} /> : null}
        </div>
      </div>
      <fieldset style={{ marginTop: 40, border: "1px solid #cfcfcf" }}>
        <legend style={{ padding: "5px 10px", backgroundColor: "rgb(42, 125, 225)", color: "#fff" }}>Add Trusted Partner</legend>
        <div style={{ marginTop: "30px", padding: 10 }}>
          <Grid columns={3} stackable>
            <Grid.Row>
              <Grid.Column>
                <div><label style={{ fontWeight: 700, fontSize: "12px" }}>Enter Partner Name<span style={{ color: '#db2828' }}>*</span></label></div>
                <Input
                  name="partnerName"
                  placeholder="e.g., Databricks (Partner Name)"
                  style={{ minWidth: "90%" }}
                  value={partnerName}
                  onChange={handleChangePartnerName}
                  error={value.error}
                />
                {value.error ? (<div className="ui basic" style={{ color: "#db2828", fontSize: "10px" }}>{value.message}</div>) : null}
              </Grid.Column>
              <Grid.Column>
                <div><label style={{ fontWeight: 700, fontSize: "12px" }}>
                  Enter Partner Account: <span style={{ color: '#db2828' }}>*</span></label></div>
                {partnerIds?.map((item, index) => (
                  <div key={index}>
                    <div style={{ display: "flex" }}>
                      <Input
                        name={`partnerId-${item.id}`}
                        placeholder="e.g., 601306020600"
                        value={item.value}
                        style={{ minWidth: "90%", marginBottom: "10px" }}
                        onChange={(e, data) => {
                          if (!isNaN(data?.value)) {
                            if (data?.value.length <= 12) {
                              const newInputFields = [...partnerIds]
                              newInputFields[index].value = data?.value
                              newInputFields[index].error = false
                              newInputFields[index].message = ""

                              let token = props.authState.accessToken.accessToken
                              if (data.value === "") {
                                newInputFields[index].error = false
                                newInputFields[index].message = ""
                              }
                              else if (data?.value?.length < 12) {
                                newInputFields[index].error = true
                                newInputFields[index].message = "Length must be equal to 12."
                              }
                              else if(partnerIds?.filter((item)=>(item?.value?.toLowerCase() === data.value?.toLowerCase()))?.length>1){
                                newInputFields[index].error = true
                                newInputFields[index].message = "Partner Account Already Entered!"
                              }
                              else if (metadata.data?.some((item) => (item?.MetadataValue === data.value)
                                && (item?.GroupKey?.toLowerCase() === value.partnerName?.toLowerCase()))) {
                                newInputFields[index].error = true
                                newInputFields[index].message = "Partner Account Already Exists!"
                              }
                              else if (data?.value?.length === 12) {

                                if (whiteListTrustedPartner?.data?.some((item) => parseInt(item) === parseInt(data?.value))) {
                                  newInputFields[index].error = false
                                }
                                else if (blackListTrustedPartner?.data?.some((item) => parseInt(item) === parseInt(data?.value))) {
                                  newInputFields[index].error = true
                                  newInputFields[index].message = "Partner account is not approved!"
                                }
                                else {
                                  setAccountDetail({ data: [], isLoading: true })
                                  //To check account is blacklisted or not.
                                  let url = commercialCloud === props?.selectCloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
                                  axios.get(`${url}query?Table=${'ACCOUNTS_TABLE'}&Key=${'AccountId'}&Value=${data?.value}`,
                                    {
                                      headers: {
                                        'Authorization': token
                                      }
                                    }).then((res => {

                                      if (res.status === 200) {
                                        newInputFields[index].error = true
                                        newInputFields[index].message = "Please enter a valid trusted partner account!"
                                        setAccountDetail({ data: res.data, isLoading: false })
                                      }
                                      else {
                                        setAccountDetail({ data: [], isLoading: false })
                                      }

                                    })).catch(error => {
                                      setAccountDetail({ data: [], isLoading: false })
                                    })
                                }
                              }
                              setPartnerIds(newInputFields)
                            }
                          }
                          else {
                            const newInputFields = [...partnerIds]
                            newInputFields[index].value = data?.value
                            newInputFields[index].error = true
                            newInputFields[index].message = "Enter valid input!"
                            setPartnerIds(newInputFields)

                          }
                        }}
                        error={item.error}
                      />
                      {partnerIds.length > 1 ?
                        (<Icon style={{ padding: "5px 10px 0px 10px", cursor: "pointer" }} onClick={() => handleRemoveInputFields(index)} name="delete" />
                        ) : null}
                    </div>
                    {item.error ? (<div className="ui basic"
                      style={{ color: "#db2828", marginBottom: "7px", marginTop: "-7px", fontSize: "10px" }}>
                      {item.message}</div>) :
                      null}
                  </div>
                ))}
                <div style={{ width: "90%", display: "flex", flexDirection: "row-reverse" }}>
                  <Button
                    disabled={((partnerIds[partnerIds?.length - 1].value?.length >= 12) && (!partnerIds[partnerIds?.length - 1].error)) ? false : true}
                    onClick={handleAddMoreInputFields}
                    style={{
                      fontSize: "x-small",
                      color: "#2A7DE1",
                      backgroundColor: "#fff",
                      padding: "0px",
                      marginTop: "15px"
                    }}>Add more</Button>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button content='Reset' style={{ width: "100px", fontSize: "small" }} />
                <Button onClick={handleSubmit} content='Submit' style={{ width: "100px", fontSize: "small", backgroundColor: "#2A7DE1", color: "#fff" }}
                  disabled={(partnerIds.some(item => item.error) || value.error) ||
                    (value?.partnerName?.length < 2) || partnerIds.some((item) => item.value === "") || accountDetail.error} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </fieldset>
    </React.Fragment>
  )
}
