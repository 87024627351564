import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Segment, Grid, Message, Header, Select, Form, Radio } from 'semantic-ui-react'
import AddTrustedPartner from './Add'
import UpdateTrustedPartner from './Update'
import DeleteTrustedPartner from './Delete'

import CloudModal from './CloudModal'
import { localStorageCloudValue, metadataTableKeys } from '../../../../Config/appConstant'
import GlobalDeleteTrustedPartner from './GlobalDeleteTrustedPartner'
import GlobalUpdateTrustedPartner from './GlobalUpdatePartner'

const getEnvType = process.env.REACT_APP_RUN_ENVIRONMENT || process.env.REACT_APP_API_ENDPOINT
getEnvType?.toLowerCase()?.includes("prod")

const getStage = (stage) => {
    if (stage?.toLowerCase()?.includes("prod")) {
        return "VA"
    }
    else if (stage?.toLowerCase()?.includes("qa")) {
        return "QA"
    }
    else if (stage?.toLowerCase()?.includes("dev")) {
        return "DEV"
    }
}
const operationName = {
    ADD: "ADD",
    UPDATE: "UPDATE",
    DELETE: "DELETE"
}

const partnerType = {
    LOCAL:"LOCAL",
    GLOBAL:"GLOBAL"
}

const operationalRule = {
    S3_RULE: `${getStage(getEnvType)}-s3-external-perms`,
    IAM_RULE: `${getStage(getEnvType)}-iam-role-external-perms`,
}

const ruleOption =[
    {
        key: operationalRule.IAM_RULE,
        text: `${operationalRule.IAM_RULE}-1036`,
        value: 1036,
        metaKey:metadataTableKeys.iamPartner
    },
    {
        key: operationalRule.S3_RULE,
        text: `${operationalRule.S3_RULE}-1007`,
        value: 1007,
        metaKey:metadataTableKeys?.s3Partner
    }
]

const ruleOptionGlobal =[
    {
        key: operationalRule.IAM_RULE,
        text: `${operationalRule.IAM_RULE}-1036`,
        value: 1036,
        metaKey:metadataTableKeys.iamGlobalPartner
    },
    {
        key: operationalRule.S3_RULE,
        text: `${operationalRule.S3_RULE}-1007`,
        value: 1007,
        metaKey:metadataTableKeys.s3GlobalPartner
    }
]

export default function TrustedPartnerLayout(props) {
    const [selectedOperation, setSelectedOperation] = useState(operationName.ADD)
    const [selectedRule, setSelectedRule] = useState(1036)
    const [selectCloud, setSelectCloud] = useState("")
    const [metaKey, setMetaKey] = useState(metadataTableKeys.iamPartner)
    const [openModal, setOpenModal] = useState(true)
    const [selectedPartnerType, setSelectedPartnerType] = useState(partnerType.LOCAL)

    useEffect(() => {
        let value = localStorage.getItem(localStorageCloudValue)
        if(value !== null && value !==""){
            setSelectCloud(value)
            setOpenModal(false)
        }
    }, [])



    return (
        <React.Fragment>
            <Segment
                size="mini"
                style={{
                    padding: "30px",
                    borderColor: "blue",
                    borderStyle: "Solid",
                    margin: "6px",
                }}>
                <CloudModal openModal={openModal} selectCloud={selectCloud} setOpenModal={setOpenModal} setSelectCloud={setSelectCloud}/>
                <Grid columns={6} stackable>
                    <Grid.Row>
                    <Grid.Column>
                            <label style={{ fontWeight: 700, fontSize: "16px" }}>Select Trusted Partner</label>
                            <Dropdown
                                placeholder='Select'
                                fluid
                                selection
                                options={[
                                    {
                                        key: partnerType.LOCAL,
                                        text: "Local",
                                        value: partnerType.LOCAL
                                    },
                                    {
                                        key: partnerType.GLOBAL,
                                        text: "Global",
                                        value: partnerType.GLOBAL
                                    }
                                ]}
                                value={selectedPartnerType}
                                style={{ marginTop: "10px", width: "100%" }}
                                onChange={(e, { value }) => {
                                    setSelectedPartnerType(value)
                                    if(partnerType.LOCAL === value){
                                        setMetaKey(ruleOption?.find(i=>i.value===selectedRule)?.metaKey)
                                    }
                                    else if(partnerType.GLOBAL === value){
                                        setMetaKey(ruleOptionGlobal?.find(i=>i.value===selectedRule)?.metaKey)
                                    }
                                }}
                            />
                    </Grid.Column>
                        <Grid.Column>
                            <label style={{ fontWeight: 700, fontSize: "16px" }}>Select Action</label>
                            <Dropdown
                                placeholder='Select'
                                fluid
                                selection
                                options={[
                                    {
                                        key: operationName.ADD,
                                        text: "Add",
                                        value: operationName.ADD
                                    },
                                    {
                                        key: operationName.UPDATE,
                                        text: "Update",
                                        value: operationName.UPDATE
                                    },
                                    {
                                        key: operationName.DELETE,
                                        text: "Delete",
                                        value: operationName.DELETE
                                    }
                                ]}
                                value={selectedOperation}
                                style={{marginTop: "10px", width: "100%" }}
                                onChange={(e, { value }) => setSelectedOperation(value)}
                            />
                        </Grid.Column>
                        <Grid.Column >
                                <label style={{ fontWeight: 700, fontSize: "16px" }}>Select Rule</label>
                                <Dropdown
                                    placeholder='Select'
                                    fluid
                                    selection
                                    options={partnerType.LOCAL === selectedPartnerType ? ruleOption : ruleOptionGlobal}
                                    value={selectedRule}
                                    style={{ marginTop: "10px",width: "100%" }}
                                    onChange={(e, { value }) =>{
                                        if(partnerType.LOCAL === selectedPartnerType){
                                            setMetaKey(ruleOption?.find(i=>i.value===value)?.metaKey)
                                        }
                                        else if(partnerType.GLOBAL === selectedPartnerType){
                                            setMetaKey(ruleOptionGlobal?.find(i=>i.value===value)?.metaKey)
                                        }
                                        setSelectedRule(value)
                                    }}
                                />
                            </Grid.Column>
                    </Grid.Row>
                </Grid>

                {(selectedOperation === operationName.ADD    && selectedPartnerType === partnerType.LOCAL  && selectCloud !== "") 
                ? (<AddTrustedPartner selectCloud={selectCloud} metaKey={metaKey} ruleId={selectedRule} partnerType={selectedPartnerType}  {...props}  />) 
                : null}
                {(selectedOperation === operationName.UPDATE && selectedPartnerType === partnerType.LOCAL  && selectCloud !== "") 
                ? (<UpdateTrustedPartner selectCloud={selectCloud} metaKey={metaKey} ruleId={selectedRule} partnerType={selectedPartnerType} {...props}/>) 
                : null}
                {(selectedOperation === operationName.DELETE && selectedPartnerType === partnerType.LOCAL  && selectCloud !== "") 
                ? (<DeleteTrustedPartner selectCloud={selectCloud} metaKey={metaKey} ruleId={selectedRule} partnerType={selectedPartnerType}  {...props}/>) 
                : null}
                {(selectedOperation === operationName.ADD    && selectedPartnerType === partnerType.GLOBAL && selectCloud !== "") 
                ? (<AddTrustedPartner selectCloud={selectCloud} metaKey={metaKey} ruleId={selectedRule} partnerType={selectedPartnerType} {...props}/>) 
                : null}
                {(selectedOperation === operationName.DELETE && selectedPartnerType === partnerType.GLOBAL && selectCloud !== "") 
                ? (<GlobalDeleteTrustedPartner selectCloud={selectCloud} metaKey={metaKey} ruleId={selectedRule} partnerType={selectedPartnerType} {...props}/>) 
                : null}
                 {(selectedOperation === operationName.UPDATE && selectedPartnerType === partnerType.GLOBAL && selectCloud !== "") 
                ? (<GlobalUpdateTrustedPartner selectCloud={selectCloud} metaKey={metaKey} ruleId={selectedRule} partnerType={selectedPartnerType} {...props}/>) 
                : null}
            </Segment>
        </React.Fragment>
    )
}  
