import React, { Component } from 'react';
import { Form, Segment,Confirm,Button} from 'semantic-ui-react';

class ConfigRuleDelete extends Component {

  createConfirmMsg=()=>{
    return 'Do you want to delete the rule '+this.props.ruleId +'?'
  }

  render() {
    return (
      <Segment size='mini' style={{padding:'20px',borderColor:'blue',borderStyle:'Solid',margin:'6px'}}>
        <Confirm
              content = {
                this.createConfirmMsg()
              }
              open={this.props.openDeletePopUp}
              onCancel={this.props.handleDeletePopUpClose}
              onConfirm={this.props.handleDeleteConfirm}
            />
      </Segment>
      
    )
  }
}

export default ConfigRuleDelete;