import React, { Component } from 'react';
import ConfigRuleSearchComp from './ConfigRuleSearchComp';
import axios from 'axios';
import SortableTable from "../../../../Components/ReUsableComponent/SortableTable";
import ConfigRuleEditFormContainer from './Edit/ConfigRuleEditFormContainer';
import ConfigRuleDelete from './delete/ConfigRuleDelete';
import config from '../../../../Config/Config';
import ToastMessage from '../../../ReUsableComponent/ToastMessageComp';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

class ConfigRuleSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleId: '0000',
      ruleSelected:'',
      optionlistdata:[],
      searchStatus:'',
      searchResultData:'',
      data:[],
      toEdit:'no',
      dataEdited:'no',
      toDelete:'no',
      dataToEdit:'',
      dataToDelete:'',
      openModal:false,
      headers:['RuleId', 'RuleName', 'Severity', 'Category', 'RuleReleaseDate', 'CcsReporting', 'TriggerType', 'ExceptionType','ApplicableResources','ActionAvailable','PeriodicFrequency'],
      toastMessage:'',
      onToastMsg:false,
      toastColour:'green',
      dimmerActive:false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.readDataFromApi = this.readDataFromApi.bind(this);
    this.handleEditPopUpClose=this.handleEditPopUpClose.bind(this);
    this.handleDeleteConfirm=this.handleDeleteConfirm.bind(this);
  }

  handleDropDownChange=(event,data)=>{
    this.setState({[data.name]: data.value});
  }

  dataEditedLoading=()=>{
    this.getDataForSearch();
  }

  handleSubmit=(event)=>{
    if (this.state.ruleId === '0000'){
      this.setState({
        onToastMsg:true,
        toastMessage:'Select a rule id and click on search',
        toastColour:'red'
      })
    }
    else {
        this.getDataForSearch();
    }
  }

  dismissToastMessage=()=>{
    this.setState({onToastMsg:false})
  }

getDataForSearch=async()=>{
    const data = {
      params: {RuleId:parseInt(this.state.ruleId)},
      headers:
      {
        'Authorization': this.props.authState.accessToken.accessToken
    }
  }
    await axios.get(config['apiendpoint']+'configrules/search',data)
      .then(res => {

        var ruleidList = res.data.body.data;
        var statusCode = res.data.status_code;

        if (statusCode === 200){
          this.setState({['data']: ruleidList})
          this.setState({['searchStatus']:''})
          this.setState({['searchStatus']:statusCode})
          this.setState({['searchResultData']:ruleidList})
        }
        else{
          this.setState({
            onToastMsg:true,
            toastMessage:res.data.body.message,
            toastColour:'red'
          })
        }
      })
      .catch(function (error) {
        console.log(error);
    })
  }

  readDataFromApi=()=>{
    const options = {
      headers:{
        'Authorization': this.props.authState.accessToken.accessToken
      }
    }
    axios.get(config['apiendpoint']+'configrules',options)
      .then(res => {
        var ruleid_list = res.data.body.data;
        let option_list=[];

        ruleid_list.map(myFunction)
        function myFunction(element) {
          var new_element={};
          new_element['key'] =element['RuleId']
          new_element['text'] =element['RuleId']+" "+element['RuleName']
          new_element['value'] =element['RuleId']
          option_list.push(new_element);
        }
        this.setState({['optionlistdata']:option_list});
      })
      .catch(function (error) {
        console.log(error);
    })
  }

  handleEdit=(selectedRuleData)=>{
    this.setState({['toEdit']:'yes'});
    this.setState({['dataToEdit']:selectedRuleData});
    this.setState({['openModal']:true})
  }

  handleDelete=(selectedRuleData)=>{
    this.setState({['toDelete']:'yes'});
    this.setState({['openDeletePopUp']:true})
    this.setState({['dataToDelete']:selectedRuleData});
  }

  handleDeletePopUpClose=()=>{
    this.setState({['toDelete']:'no',openDeletePopUp: false})
  }

  handleEditPopUpClose=()=>{
    this.setState({['openModal']:false});
    this.setState({['toEdit']:'no'});
  }

  handleDeleteConfirm=async()=>{
    this.setState((prevState)=>({...prevState,dimmerActive:true}))
    const data = {
      params:{"RuleId": parseInt(this.state.ruleId),"LastModifiedBy": this.props.authState.accessToken.claims.sub},
      headers:{
        'Authorization': this.props.authState.accessToken.accessToken
      }
    };
    this.handleDeletePopUpClose();
    try{
      const deleteConfigRules = Promise.all([
        axios.delete(`${config.apiendpoint}configrules`, data),
        axios.delete(`${config.govCloudApi}configrules`, data)
      ]);
      
      deleteConfigRules.then(([apiResponse, govCloudApiResponse]) => {
        if(apiResponse?.data.status_code === 204 && govCloudApiResponse?.data.status_code=== 204){
          this.setState((prevState)=>({
            ...prevState,
            onToastMsg:true,
            toastMessage:apiResponse.data.body.message,
            toastColour:'green',
            ruleId:'0000',
            searchStatus:'',
            dimmerActive:false
          }))
          this.readDataFromApi();
        }
        else{
          this.setState((prevState)=>({
            ...prevState,
            onToastMsg:true,
            toastMessage:apiResponse.data.body.message,
            toastColour:'red',
            dimmerActive:false
          }))
        }
      }).catch(error => {
        console.error('Error:', error);
        this.setState((prevState)=>({...prevState,
          onToastMsg:true,
          toastMessage:error?.message,
          toastColour:'red',
          dimmerActive:false }))
      });
    }
    catch(error){
      console.error(error)
      this.setState((prevState)=>({...prevState,
        onToastMsg:true,
        toastMessage:error?.message,
        toastColour:'red',
        dimmerActive:false }));
    }
  }

  render() {
    let searchresult;
    let editformpopup;
    let deleteformpopup;
    if (this.state.searchStatus === 200 && this.state.ruleId != '0000'){

      searchresult = <SortableTable {...this.state} handleEdit={this.handleEdit} handleDelete={this.handleDelete} headers={this.state.headers} />
    }
    if (this.state.toEdit ==='yes'){
      editformpopup = <ConfigRuleEditFormContainer {...this.state} dataEditedLoading={this.dataEditedLoading} handleEditPopUpClose={this.handleEditPopUpClose} accessData={this.props}/>
    }
    if (this.state.toDelete==='yes'){
      deleteformpopup = <ConfigRuleDelete {...this.state} handleDeletePopUpClose={this.handleDeletePopUpClose} handleDeleteConfirm={this.handleDeleteConfirm} accessData={this.props}/>
      // delete module goes here 
    }
    return (
        <Segment>
          <ConfigRuleSearchComp handleSubmit = {this.handleSubmit} handleDropDownChange = {this.handleDropDownChange} {...this.state} readDataFromApi={this.readDataFromApi} 
          dismissToastMessage={this.dismissToastMessage}/>
          {searchresult}
          {editformpopup}
          {deleteformpopup}
          <Dimmer active={this.state.dimmerActive} inverted>
            <Loader size='large'>Please wait! Deleting Rule...</Loader>
         </Dimmer>
        </Segment>
    )
  }
}

export default ConfigRuleSearchContainer;

          {/* {searchresult} */}