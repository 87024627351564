import React, { Component } from 'react';
import { Form, Segment, Button, Select,Header,Input,Checkbox,Message, Radio,Confirm} from 'semantic-ui-react';
import ToastMessage from '../../../ReUsableComponent/ToastMessageComp';
import ReactTooltip from "react-tooltip";
import Modal from '../../../Modal';
import { cloudCompatibilityOption, commercialCloud,labelAws, labelAwsUsGov, usGovCloud } from '../../../../Config/appConstant';

const rule_enabled = [
  { key: 't', text: 'True', value: true },
  { key: 'f', text: 'False', value: false }
]

const action_enabled = [
  { key: 't', text: 'True', value: 'True' },
  { key: 'f', text: 'False', value: 'False'},
  { key: 'e', text: 'Email', value: 'Email'},
  { key: 'd', text: 'Delete', value: 'Delete'}
]


class GlobalExceptionFormCorp extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      // <Modal
      // closeIcon
      // open={this.props.openModal}
      // onClose={()=>this.props.openOrCloseModalFunc(false)}
      // // onOpen={() =>this.props.openOrCloseModalFunc(true)}
      // >
      <Segment size='mini' style={{padding:'30px',borderColor:'blue',borderStyle:'Solid',margin:'6px'}}>
        {/* <Segment.Group> */}
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
        <Header as='h3' color='blue'>Create/Edit Global Exceptions</Header>
        <div>
          <b style={{fontSize:13,marginBottom:2}}>Selected organization:</b>
        <Select
          fluid
          label='Select Cloud'
          options={cloudCompatibilityOption}
          value={this.props.selectCloud}
          name="selectCloud"
          onChange={this.props.handleSelectCloudSpace}
          placeholder='Select Cloud'
          style={{position: "centre", width: "150px" }}
          />
          </div>
        </div>
          <Modal openModal={this.props.isModalOpen} closeModal={()=>this.props.handleCloseModal()} title={'Choose organization:'}>
        <Form>
            <Form.Group widths='equal'>
              <Form.Field>
                <Radio
                  label={labelAws.label}
                  name="selectCloud"
                  value={labelAws.value}
                  checked={this.props.selectCloud === commercialCloud}
                  onChange={this.props.handleSelectCloudSpace}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={labelAwsUsGov.label}
                  name="selectCloud"
                  value={labelAwsUsGov.value}
                  checked={this.props.selectCloud === usGovCloud}
                  onChange={this.props.handleSelectCloudSpace}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal>
        {this.props.onToastMsg &&<ToastMessage toastMessage={this.props.toastMessage} colour={this.props.toastColour} dismissToastMessage={this.props.dismissToastMessage}/>}
      <Form style={{width:'1300px'}}>
      <Form.Group style={{marginTop:'25px'}}>

      <Form.Field>
        <label>Rule Identifier</label>
      <Select
              fluid
              required
              label=''
              onChange={this.props.handleDropDownChange}
              options={this.props.optionlistdata}
              name='ruleIdName'
              search
              value={this.props.ruleIdName}
              placeholder='Select a config rule'
              style ={{height:'50px',width:'300px',marginTop:'15px'}}
            />
      </Form.Field>
      {/*<Form.Field>
       <Checkbox
          label='Composite resource type'
          onChange={()=>this.props.handleCheckBoxChange()}
          checked={this.props.checkbox}
          style ={{height:'50px',width:'200px',marginTop:'45px'}}
        />
      </Form.Field> */}
      <div>
      {/* <label>Resource Identifier</label> */}
          { this.props.attributes.map((inputField,index) => (
            <Form.Group>

              <Form.Field>
                {index===0 && <label>Resource Identifier</label>}
                <Input type='text'
                name="resource_id"
                value={inputField.resource_id}
                onChange={event => this.props.handleChangeInput(index, event)}
                style ={{height:'50px',width:'260px',marginTop:'10px'}} />
              </Form.Field>
              {this.props.checkbox && 
              <Form.Field>
                {index===0 &&<label>Entity</label>}
                <Input
                name="entity"
                data-for="entity"
                data-tip
                value={inputField.entity}
                onChange={event => this.props.handleChangeInput(index, event)}
                style ={{height:'50px',width:'260px',marginTop:'10px'}}
              />
              <ReactTooltip id="entity" type='info' place="top" effect="solid">
              Entity can be {this.props.global_exception_format_dict[this.props.ruleId].join('/')}
            </ReactTooltip>
              </Form.Field>}
              
              {index ===0 && 
              <Button onClick={event=>this.props.handleRemoveFields(index,event)} style ={{marginTop:'33px',height:'50px'}}>
                -
              </Button>
              }
              {index ===0 && 
              <Button
                onClick={this.props.handleAddFields}
                style ={{marginTop:'33px',height:'50px'}}
              >
                +
              </Button>}
              {index !=0 && 
              <Button onClick={event=>this.props.handleRemoveFields(index,event)} style ={{marginTop:'10px',height:'50px'}}>
                -
              </Button>
              }
              {index !=0 && 
              <Button
                onClick={this.props.handleAddFields}
                style ={{marginTop:'10px',height:'50px'}}
              >
                +
              </Button>}
          </Form.Group>
          )) }
          </div>
        </Form.Group>
        <Form.Group style={{marginTop:'25px'}}>
          {this.props?.OperationMode?.toLowerCase() !== "delete" ? (
            <Button size = 'medium' style={{backgroundColor:'#13a6d8'}} disabled={!this.props.enableButton || (this.props?.attributes?.length > 0 && this.props?.attributes[0].resource_id==="")}
             onClick={this.props.onConfirmHandler}>{this.props.OperationMode}</Button>
          ) : (
            <Button size = 'medium' style={{backgroundColor:'#13a6d8'}} disabled={!this.props.enableButton} onClick={this.props.onConfirmHandler}>{this.props.OperationMode}</Button>
          )}
          
          <Button type ='submit' size = 'medium' value ='Submit' style={{backgroundColor:'#13a6d8'}} onClick={this.props.clearState}>Clear</Button>
        </Form.Group>
        </Form>
        <Confirm
              open={this.props.open}
              content = {`Are you sure you want to ${this.props?.OperationMode?.toLowerCase()} in ${this.props.selectCloud === commercialCloud ? labelAws.label : labelAwsUsGov.label}?`}
              onCancel={this.props.handleCancel}
              onConfirm={()=>{
                this.props.handleCancel()
                this.props.handleSubmit()
              }}
            />
        {!this.props.enableButton && <Message
        error
          onDismiss={this.props.handleDismiss}
          header='Correct the mandatory field!'
          content='Resource Identifier and Resource entity are mandatory.'
        />}
      </Segment>
    )
  }
}

export default GlobalExceptionFormCorp;