import React, { Component } from 'react';
import { Form, Segment,Confirm,Button} from 'semantic-ui-react';
import { commercialCloud, labelAws,labelAwsUsGov } from '../../../../../Config/appConstant';
class ResourceExceptionDelete extends Component {

  render() {

    return (
      <Segment size='mini' style={{padding:'20px',borderColor:'blue',borderStyle:'Solid',margin:'6px'}}>
        <Confirm
              content={`Are you sure you want to delete record in ${this.props.selectCloud === commercialCloud? labelAws.label : labelAwsUsGov.label}?`}
              open={this.props.openDeletePopUp}
              onCancel={this.props.handleDeletePopUpClose}
              onConfirm={this.props.handleDeleteConfirm}
            />
      </Segment>
    )
  }
}

export default ResourceExceptionDelete;