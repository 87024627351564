import React, { Component } from "react";
import ConfigAccountEditForm from "./ConfigAccountEditForm";
import axios from "axios";
import update from "react-addons-update";
import * as yup from "yup";
import config from '../../../../../Config/Config';
import { commercialCloud } from "../../../../../Config/appConstant";

//toast message

const tooltip_for_rule = {
  "ec2-rogue-ami":{
    "aws_backup_ami_enabled": 'The allowed value is either true or false ',
    "trusted_vendors": 'It is a list of trusted vendors'
  },
  "dbclusters-unencrypted":{
    "abcd":"ehfhefhdfhdf"
  },
  "s3-external-perms":{
    "trusted_partners":"List of Trusted Partner"
  },
  "iam-role-external-perms":{
    "trusted_partners":"List of Trusted Partner"
  }
}

class ConfigAccountEditFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleId: "",
      ruleName: "",
      accId: "",
      ruleEnabled: "",
      actionEnabled: "",
      exceptionCheckEnabled: "",
      attributeKey: "",
      attributeValue: "",
      result: "show the modal to capture a result",
      open: false,
      errorPaths: [],
      errors: [],
      openOrCloseModal: true,
      attributes: [{attributeKey: "", attributeValue: [""] }],
      attributeOptions:[],
      toastMessage:'',
      onToastMsg:false,
      toastColour:'green',
      noAttributesOptions:false,
      trimmedRuleName:'',
      toolTipList:[],
      serviceNowTicketId:"",
      errorPaths: [],
      errors: [],
      copyServiceNowTicketId:"",
      isButtonDisabled:false,
      aws_backup: false,
      copy_aws_backup: false,
      copyActionEnabled: '',
      copyExceptionCheckEnabled: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.readDataRuleIdFromApi = this.readDataRuleIdFromApi.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.openOrCloseModalFunc = this.openOrCloseModalFunc.bind(this);
    this.setEditData = this.setEditData.bind(this);
    this.handleAWSBackupAmiEnabled = this.handleAWSBackupAmiEnabled.bind(this)
  }
  getDropDownValue=async(ruleNameDropdown)=>{
    const selectedUrl = this.props.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const myArr = ruleNameDropdown.split("-");
    var ruleNameSplitted = myArr.shift();
    let result = myArr.join('-')
    this.setState({['trimmedRuleName']:result})
    const options = {
      params: {GroupKey:result},
      headers:{
        'Authorization': this.props.accessData.authState.accessToken.accessToken
      }
    };

    await axios.get(selectedUrl+'metadata/special-attributes',options)
      .then(res => {
        var metadatavalue_list = res.data.body.data;
        if (Object.keys(metadatavalue_list[0]).length != 0) {
          let option_list = [];
          metadatavalue_list.map(myFunction)

          function myFunction(element) {
            var new_element = {};
            new_element['key'] = element
            new_element['text'] = element
            new_element['value'] = element
            option_list.push(new_element);

          }
          if (option_list != []) {
            this.setState({
              ['attributeOptions']: option_list,
              ['noAttributesOptions']: true
            });
          }
          else {
            this.setState({
              ['noAttributesOptions']: false
            });
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        this.setState({['noAttributesOptions']:false})
      })
  }

  handleAddFields = () => {
    this.setState({
      attributes: [
        ...this.state.attributes,
        {
          attributeKey: "",
          attributeValue: [""],
        },
      ],
    });
  };

  handleValueAdd = (index) => {
    var updated_list = this.state.attributes[index]['attributeValue']
    updated_list.push('')
    // this.setState({
    //   attributes: [
    //     ...this.state.attributes,
    //     {
    //       attributeKey: "",
    //       attributeValue: [""],
    //     },
    //   ],
    // });
    this.setState(
      update(this.state, {
        attributes: {
          [index]: {
            ['attributeValue']: {
              $set: updated_list,
            },
          },
        },
      })
    );
  };

  handleChangeInput = (index, e) => {
    this.setState(
      update(this.state, {
        attributes: {
          [index]: {
            [e.target.name]: {
              $set: e.target.value,
            },
          },
        },
      })
    );
  };

  handleValueInputChange = (index,innerindex,e) => {
    this.setState(
      update(this.state, {
        attributes: {
          [index]: {
            ['attributeValue']: {
              [innerindex]:{
                $set: e.target.value,
              },
            },
          },
        },
      })
    );
  };


  handleChangeDropdown =(index, e,data)=>{
    this.setState(
      update(this.state, {
        attributes: {
          [index]: {
            [data.name]: {
              $set: data.value,
            },
          },
        },
      })
    );
  }
  show = () => {
    this.setState({ open: true });
  };

  handleSubmit(event) {
    this.schema
        .validate(this.state, { abortEarly: false })
        .then(valid => {
          this.setState((_p)=>({..._p,errorPaths: [], errors: [] }),()=>{
            this.show();
          })
        })
        .catch(err =>
          this.setState((_p)=>({
            ..._p,
            errors: err.errors,
            errorPaths: err.inner.map(i => i.path),
          }))
        )
  }

  handleCancel = () => {
    this.setState({ open: false });
  };

  handleRemoveFields = (index, event) => {
    this.setState({
      attributes: this.state.attributes.filter((_, i) => i !== index),
    });
  };

  handleRemoveFieldsForEachAttribute = (index,innerindex,event) =>{
    var updatedvaluelist = this.state.attributes[index]['attributeValue']
    this.setState(
      update(this.state, {
        attributes: {
          [index]: {
            ['attributeValue']: {
              $set: updatedvaluelist.filter((_, i) => i !== innerindex),
            },
          },
        },
      })
    );
  }

  setEditData = async() => {
    await this.getDropDownValue(this.props.dataToEdit.RuleName);

    var tooltiplist=[]
    if (this.state.attributeOptions) {
      var newArr =[]
      var trimmed_rule_name = this.state.trimmedRuleName
      var attributeKeyList = []
      try{
        attributeKeyList = (this.props.dataToEdit.Attributes).map(myFunction)

        function myFunction(receivedDict) {
          var keysvalue = receivedDict['attributeKey']

          tooltiplist.push(tooltip_for_rule[trimmed_rule_name][keysvalue])
          return receivedDict['attributeKey'];

        }
        newArr = this.props.dataToEdit.Attributes
      }
      catch(err){
        newArr = []
        console.error(err)
      }
      var iterator = this.state.attributeOptions.entries();

      for (const [index, element] of iterator) {

        if(attributeKeyList.includes(element['value'])){

        }
        else{
          newArr.push({attributeKey:element['value'], attributeValue: []})
        }
      }

      this.setState({
        ["ruleId"]: this.props.dataToEdit.RuleId,
        ["ruleName"]: this.props.dataToEdit.RuleName,
        ["accId"]: this.props.dataToEdit.AccountId,
        ["exceptionCheckEnabled"]: this.props.dataToEdit.ExceptionsCheckEnabled,
        ["ruleEnabled"]: this.props.dataToEdit.RuleEnabled,
        ["actionEnabled"]: this.props.dataToEdit.ActionEnabled,
        ["attributes"]: newArr,
        ["serviceNowTicketId"]:this.props.dataToEdit.ServiceNowTicketId,
        ["copyServiceNowTicketId"]:this.props.dataToEdit.ServiceNowTicketId,
        ['aws_backup']: (typeof newArr?.find((i) => i.attributeKey === "aws_backup_ami_enabled")?.attributeValue === "boolean")
          ? newArr?.find((i) => i.attributeKey === "aws_backup_ami_enabled")?.attributeValue : false,
        ['copy_aws_backup']: (typeof newArr?.find((i) => i.attributeKey === "aws_backup_ami_enabled")?.attributeValue === "boolean")
          ? newArr?.find((i) => i.attributeKey === "aws_backup_ami_enabled")?.attributeValue : false,
        ['copyExceptionCheckEnabled']: this.props.dataToEdit.ExceptionsCheckEnabled,
        ["copyActionEnabled"]: this.props.dataToEdit.ActionEnabled
      });
      this.setState({['toolTipList']:tooltiplist})
    }
    else{
      this.setState({
        ["ruleId"]: this.props.dataToEdit.RuleId,
        ["ruleName"]: this.props.dataToEdit.RuleName,
        ["accId"]: this.props.dataToEdit.AccountId,
        ["exceptionCheckEnabled"]: this.props.dataToEdit.ExceptionsCheckEnabled,
        ["ruleEnabled"]: this.props.dataToEdit.RuleEnabled,
        ["actionEnabled"]: this.props.dataToEdit.ActionEnabled,
        ["serviceNowTicketId"]:this.props.dataToEdit.ServiceNowTicketId,
        ["copyServiceNowTicketId"]:this.props.dataToEdit.ServiceNowTicketId,
        ['copyExceptionCheckEnabled']: this.props.dataToEdit.ExceptionsCheckEnabled,
        ["copyActionEnabled"]: this.props.dataToEdit.ActionEnabled
      });
    }

  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDropDownChange = (event, data) => {
    this.setState({ [data.name]: data.value },()=>{
      this.schema
        .validate(this.state, { abortEarly: false })
        .then(valid => this.setState((_p)=>({..._p,errorPaths: [], errors: [] })))
        .catch(err =>
          this.setState((_p)=>({
            ..._p,
            errors: err.errors,
            errorPaths: err.inner.map(i => i.path),
          }))
        )
    });
  };

  dismissToastMessage=()=>{
    this.setState({onToastMsg:false})
  }

  handleConfirm = () => {
    const selectedUrl = this.props.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    this.setState({ open: false });
    var newArr =[]

    let _attributes = this.state.attributes
    _attributes?.forEach((item, index) => {
      if (item?.attributeKey === "aws_backup_ami_enabled") {
        let result = this.state.attributes[index]
        result.attributeValue = this.state.aws_backup
      }
    })

    var data = {
      AccountId: this.state.accId,
      ExceptionsCheckEnabled: this.state.exceptionCheckEnabled,
      RuleEnabled: this.state.ruleEnabled,
      ActionEnabled: this.state.actionEnabled,
      RuleId: parseInt(this.state.ruleId),
      RuleName: this.state.ruleName,
      LastModifiedBy: this.props.accessData.authState.accessToken.claims.sub,
      CreateTimeStamp:this.props.dataToEdit.CreateTimeStamp,
      UpdateTimeStamp:this.props.dataToEdit.UpdateTimeStamp,
      Attributes: _attributes,
      AccountName: this.props.data[0]?.AccountName,
      ServiceNowTicketId: this.state.serviceNowTicketId
    };

    const options = {
      headers: {
        Authorization: this.props.accessData.authState.accessToken.accessToken,
      },
    };
    axios
      .put(
        selectedUrl + 'account',
        data,
        options
      )
      .then((res) => {
        if (res.data.status_code === 201) {
          this.setState({
            onToastMsg:true,
            toastMessage:res.data.body.message,
            toastColour:'green',
            copyServiceNowTicketId:this.state.serviceNowTicketId,
            copyExceptionCheckEnabled:this.state.exceptionCheckEnabled,
            copyActionEnabled:this.state.actionEnabled,
            copy_aws_backup:this.state.aws_backup
          })
        }
        else{
          this.setState({
            onToastMsg:true,
            toastMessage:res.data.body.message,
            toastColour:'red'
          })
        }
        this.props.handleSubmit();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  readDataRuleIdFromApi = () => {
    const selectedUrl = this.props.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
      headers: {
        Authorization: this.props.authState.accessToken.accessToken,
      },
    };
    axios
      .get(selectedUrl +'configrules/RuleId',
        options
      )
      .then((res) => {
        var ruleid_dict = res.data.body.data[0].RuleId;
        this.setState({ ["ruleId"]: ruleid_dict });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  openOrCloseModalFunc = (booldata) => {
    this.props.handleEditPopUpClose();
  };
  schema = yup.object().shape({
    serviceNowTicketId: yup.string()
      .test(
        'allChecks',
        'ServiceNow Ticket ID should meet all validation requirements',
        function (value) {
          const {
            copyServiceNowTicketId,
            copyExceptionCheckEnabled,
            exceptionCheckEnabled,
            copyActionEnabled,
            actionEnabled,
          } = this.parent;

          if (((copyExceptionCheckEnabled !== exceptionCheckEnabled) || (copyActionEnabled !== actionEnabled))) {
            if (!value) {
              return this.createError({ message: 'ServiceNow Ticket ID is a required field' });
            }

            if (!value.startsWith('RITM')) {
              return this.createError({ message: 'ServiceNow Ticket ID should start with RITM' });
            }

            if (value.length !== 11) {
              return this.createError({ message: 'ServiceNow Ticket ID must be exactly 11 letters long' });
            }

            const alphanumericRegex = /^[a-zA-Z0-9,-]*$/;
            if (!alphanumericRegex.test(value)) {
              return this.createError({ message: 'Ticket ID should not contain spaces' });
            }
       
            const ritmPart = value.substring(4);
            if (isNaN(ritmPart)) {
              return this.createError({ message: 'ServiceNow Ticket ID should contain digits after RITM.' });
            }

            if (copyServiceNowTicketId === value) {
              return this.createError({ message: 'Provide new ServiceNow Ticket Id.' });
            }
          }
          return true;
        }
      )
  });
  handleAWSBackupAmiEnabled = (checked) => {
    this.setState((prevState) => ({ ...prevState, aws_backup: checked }))
  }
  render() {
    return (
      <ConfigAccountEditForm
        isButtonActive={this.props?.isButtonActive}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        handleDropDownChange={this.handleDropDownChange}
        {...this.state}
        setEditData={this.setEditData}
        handleConfirm={this.handleConfirm}
        handleCancel={this.handleCancel}
        readDataRuleIdFromApi={this.readDataRuleIdFromApi}
        openModal={this.props.openModal}
        openOrCloseModalFunc={this.openOrCloseModalFunc}
        stringToDate={this.stringToDate}
        handleChangeInput={this.handleChangeInput}
        handleAddFields={this.handleAddFields}
        handleRemoveFields={this.handleRemoveFields}
        getDropDownValue={this.getDropDownValue}
        handleChangeDropdown={this.handleChangeDropdown}
        handleValueAdd={this.handleValueAdd}
        handleValueInputChange={this.handleValueInputChange}
        handleRemoveFieldsForEachAttribute={this.handleRemoveFieldsForEachAttribute}
        selectCloud={this.props.selectCloud}
        rulesStore={this.props.rulesStore}
        dismissToastMessage={this.dismissToastMessage}
        handleAWSBackupAmiEnabled={this.handleAWSBackupAmiEnabled}
      />
    );
  }
}

export default ConfigAccountEditFormContainer;
