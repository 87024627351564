import React, { Component } from "react";
import ConfigRuleEditForm from "./ConfigRuleEditForm";
import axios from "axios";
import config from '../../../../../Config/Config';
import { commercialCloud, usGovCloud } from "../../../../../Config/appConstant";


class ConfigRuleEditFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleId: "",
      ruleName: "",
      severity: "",
      actionAvailable: "",
      exceptionType: "",
      ccsReporting: false,
      applicableResources: [],
      category: "",
      exceptionFormat: "",
      triggerType: [],
      periodicFrequency: "",
      ruleReleaseDate: "",
      open: false,
      result: "show the modal to capture a result",
      errorPaths: [],
      errors: [],
      openOrCloseModal: true,
      toastMessage:'',
      onToastMsg:false,
      toastColour:'green',
      globalExceptionType:'',
      globalExceptionFormat:[],
      dimmerActive:false,
      cloudCompatibility:[],
      defaultActionEnabled:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.readDataRuleIdFromApi = this.readDataRuleIdFromApi.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.openOrCloseModalFunc = this.openOrCloseModalFunc.bind(this);
    this.stringToDate = this.stringToDate.bind(this);
    this.setEditData = this.setEditData.bind(this);
    this.handleCalendarChangeToDbSchema=this.handleCalendarChangeToDbSchema.bind(this);
    this.onChangeActionEnable = this.onChangeActionEnable.bind(this)
  }

  dismissToastMessage=()=>{
    this.setState({
      onToastMsg:false,
    })
    this.props.dataEditedLoading();
  }

  setOpen = () => {};

  setEditData = () => {
    const {CloudCompatibility} = this.props.dataToEdit
    let cloudType = []

    if(CloudCompatibility !== undefined){
      if(CloudCompatibility?.hasOwnProperty(usGovCloud) && CloudCompatibility?.hasOwnProperty(commercialCloud)){
        if(CloudCompatibility[usGovCloud] && CloudCompatibility[commercialCloud]){
          cloudType.push(commercialCloud)
          cloudType.push(usGovCloud)
        }
        else if(CloudCompatibility[usGovCloud]){
          cloudType.push(usGovCloud)
        }
        else if(CloudCompatibility[commercialCloud]){
          cloudType.push(commercialCloud)
        }
      }
      else if(CloudCompatibility?.hasOwnProperty(commercialCloud)){
        if(CloudCompatibility[commercialCloud]){
          cloudType.push(commercialCloud)
        }
      }
      else if(CloudCompatibility?.hasOwnProperty(usGovCloud)){
        if(CloudCompatibility[usGovCloud]){
          cloudType.push(usGovCloud)
        }
      }
    }

    this.setState({
      ["ruleId"]: this.props.dataToEdit.RuleId,
      ["ruleName"]: this.props.dataToEdit.RuleName,
      ["severity"]: this.props.dataToEdit.Severity,
      ["actionAvailable"]: this.props.dataToEdit.ActionAvailable,
      ["exceptionType"]: this.props.dataToEdit.ExceptionType,
      ["ccsReporting"]: this.props.dataToEdit.CcsReporting,
      ["category"]: this.props.dataToEdit.Category,
      ["triggerType"]: this.props.dataToEdit.TriggerType,
      ["exceptionFormat"]: this.props.dataToEdit.ExceptionFormat,
      ["periodicFrequency"]: this.props.dataToEdit.PeriodicFrequency,
      ["applicableResources"]: this.props.dataToEdit.ApplicableResources,
      ['globalExceptionFormat']:this.props.dataToEdit.GlobalExceptionFormat,
      ['globalExceptionType']:this.props.dataToEdit.GlobalExceptionType,
      ['cloudCompatibility']:cloudType,
      ['defaultActionEnabled']:this.props.dataToEdit?.DefaultActionEnabled
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDropDownChange = (event, data) => {
    this.setState({ [data.name]: data.value });
    if(data.name === 'exceptionType' && data.value==='Not_Applicable'){
      this.setState({'exceptionFormat':''})
    }
    else if(data.name === 'globalExceptionType' && data.value==='Not_Applicable'){
      this.setState({'globalExceptionFormat':[]})
    }
  };

  handleCalendarChangeToDbSchema()
  {
    var dateMonthYear= (this.state.ruleReleaseDate).split('-')
    var convertedDate= dateMonthYear[1]+'/'+dateMonthYear[2]+'/'+dateMonthYear[0];
    return convertedDate;
  };

  show = () => {
    this.setState({ open: true });
  };

  handleSubmit(event) {
    this.show();
  }

  handleCancel = () => {
    this.setState({ open: false });
  };

  handleConfirm = () => {

    this.setState({ open: false });
    var convertedDate = this.handleCalendarChangeToDbSchema();
    this.setState((prevState)=>({...prevState,dimmerActive:true}))
    const data = {
      ActionAvailable: this.state.actionAvailable,
      Category: this.state.category,
      CcsReporting: this.state.ccsReporting,
      ExceptionType: this.state.exceptionType,
      RuleId: parseInt(this.state.ruleId),
      RuleName: this.state.ruleName,
      RuleReleaseDate: convertedDate,
      Severity: this.state.severity,
      TriggerType: this.state.triggerType,
      ApplicableResources: this.state.applicableResources,
      PeriodicFrequency: this.state.periodicFrequency,
      ExceptionFormat: this.state.exceptionFormat,
      LastModifiedBy: this.props.accessData.authState.accessToken.claims.sub,
      CreateTimeStamp:this.props.dataToEdit.CreateTimeStamp,
      UpdateTimeStamp:this.props.dataToEdit.UpdateTimeStamp,
      GlobalExceptionFormat:this.state.globalExceptionFormat,
      GlobalExceptionType:this.state.globalExceptionType,
      GlobalExceptionEnabled:this.props.dataToEdit.GlobalExceptionEnabled,
      CloudCompatibility:{
        "aws":this.state.cloudCompatibility.includes(commercialCloud),
        "aws-us-gov": this.state.cloudCompatibility.includes(usGovCloud)
      },
      DefaultActionEnabled: this.state.defaultActionEnabled
    };
    const options = {
      headers: {
        Authorization: this.props.accessData.authState.accessToken.accessToken,
      },
    };
    try{
      const updateConfigRule = Promise.all([
        axios.put(`${config.apiendpoint}configrules`,data,options),
        axios.put(`${config.govCloudApi}configrules`,data,options)
      ])

      updateConfigRule.then(([apiendpoint,govCloudApi])=>{
        if (apiendpoint?.data?.status_code === 201 && govCloudApi?.data?.status_code === 201) 
        {
          this.setState((prevState)=>({
            ...prevState,
            onToastMsg:true,
            toastMessage:apiendpoint?.data.body.message,
            toastColour:'green',
            dimmerActive:false,
          }),()=>{
            this.props.dataEditedLoading()
          })
        }
        else 
        {
          this.setState({
            onToastMsg:true,
            toastMessage:apiendpoint?.data.body.message,
            toastColour:'red',
            dimmerActive:false,
          })
        }
      }).catch(err=>{
        console.error(err)
        this.setState((prevState)=>({...prevState,
          onToastMsg:true,
          toastMessage:err?.message,
          toastColour:'red',
          dimmerActive:false }));
      })
    }
    catch(error){
      console.error(error)
      this.setState((prevState)=>({...prevState,
        onToastMsg:true,
        toastMessage:error?.message,
        toastColour:'red',
        dimmerActive:false }));
    }
  };

  readDataRuleIdFromApi = () => {
    const options = {
      "Content-Type": "application/json",
      // 'Access-Control-Allow-Origin': '*',
      // // 'Access-Control-Allow-Methods': 'POST',
      // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      // headers:{
      //   'Authorization': 'Bearer '+`"eyJraWQiOiJvR21mMVkyTzE1ZlRMNHc5SnNTZTg3bFpSbTZJcVJZQnEyVTBFdWlkUHlnIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkV4cWd1cWxHMzV1eTItQm5RUF9BSUhjZVdtekNld3lrR0hJQXhlVEhWUmMiLCJpc3MiOiJodHRwczovL3Zlcmlzay5va3RhcHJldmlldy5jb20iLCJhdWQiOiJodHRwczovL3Zlcmlzay5va3RhcHJldmlldy5jb20iLCJzdWIiOiJJOTk3NTFAdmVyaXNrLmNvbSIsImlhdCI6MTYxMTI0MzM4MiwiZXhwIjoxNjExMjQ2OTgyLCJjaWQiOiIwb2F3Mnc4dTh0TG1zeE9wQzBoNyIsInVpZCI6IjAwdXNkb2VsdmdUcVcyRHdVMGg3Iiwic2NwIjpbIm9wZW5pZCIsImVtYWlsIiwicHJvZmlsZSJdfQ.jFMyr2ksk_G9rLMaIUUZof0jcN0UTuDlTYqF_I7dQYv-9WVkFHqdYLSOmNxmGmelLbGB5rHwvhHQApJds5Ubp3xFIwZzvvfnnbb_WwSDt_EgpPaD2uKbhxQYzBi8PhokFF2xPRmMizrJhJ3AJKERVyZWRJBqyJsU_Sqr61PYoRQu_D1zGqe4dv3UKIbsnUyHoFZKDC4187LpKEpGMz3MbSPBakSHWH5iAUG92HXkC98Pq9EyjywLutePOQdZOMo4yTqWhV7wPdmhKQjL0NrQxhuKJqTKUT-mFUB3BVmQnbcrF2chjwpwet40XQP9THJv53l0HcgsPW2xg5GA6KwIVw"`
      // }
    };
    axios
      .get(
        config['apiendpoint']+'configrules/RuleId',
        {},
        options
      )
      .then((res) => {
        var ruleid_dict = res.data.body.data[0].RuleId;
        this.setState({ ["ruleId"]: ruleid_dict });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // handleSubmit(event) {
  //   this.handleConfirm();
  // }

  stringToDate = () => {
    var dateString = this.props.dataToEdit.RuleReleaseDate;
    var parts = dateString.split("/");
    var convertedDate = parts[2] + "-" + parts[0] + "-" + parts[1];
    this.setState({["ruleReleaseDate"]: convertedDate });
  };

  openOrCloseModalFunc = (booldata) => {
    this.props.handleEditPopUpClose();
  };
  onChangeActionEnable = (event, data)=>{
    this.setState((...prevState)=>({...prevState,defaultActionEnabled:data.value}))
  }

  render() {
    const { data } = this.state;
    return (
      <ConfigRuleEditForm
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        handleDropDownChange={this.handleDropDownChange}
        {...this.state}
        setEditData={this.setEditData}
        handleConfirm={this.handleConfirm}
        handleCancel={this.handleCancel}
        readDataRuleIdFromApi={this.readDataRuleIdFromApi}
        openModal={this.props.openModal}
        openOrCloseModalFunc={this.openOrCloseModalFunc}
        stringToDate={this.stringToDate}
        dismissToastMessage={this.dismissToastMessage}
        onChangeActionEnable={this.onChangeActionEnable}
      />
    );
  }
}

export default ConfigRuleEditFormContainer;
