import React, { Component } from "react";
import ResourceExceptionEditForm from "./ResourceExceptionEditForm";
import axios from "axios";
import config from "../../../../../Config/Config";
import * as yup from "yup";
import { commercialCloud } from "../../../../../Config/appConstant";
//toast-message

class ResourceExceptionEditFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleId: "",
      ruleName: "",
      accId: "",
      resourceId: "",
      peraId: "",
      approvalDate: "",
      lastRecertificationDate: "",
      expiryDate: "",
      status: "RECERTIFIED",
      accIdRuleId: "",
      ticketId: "",
      open: false,
      result: "show the modal to capture a result",
      errorPaths: [],
      errors: [],
      openOrCloseModal: true,
      ruleSeverity: "",
      toastMessage: "",
      onToastMsg: false,
      toastColour: "green",
      minDate: "",
      todayDate: new Date().getFullYear() + "-" + String(new Date().getMonth() + 1).padStart(2, "0") + "-" + String(new Date().getDate()).padStart(2, "0"),
      showUpdateButton: false,
      resourceRegion:[],
      resourceRegionName:"",
      isResourceRegionNameExists:false,
      accountName:""
    };
    this.mounted = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkUpdateButtton = this.checkUpdateButtton.bind(this);
    this.checkUpdateButttonPeraId = this.checkUpdateButttonPeraId.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.readDataRuleIdFromApi = this.readDataRuleIdFromApi.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.openOrCloseModalFunc = this.openOrCloseModalFunc.bind(this);
    // this.dateToString =this.dateToString.bind(this);
    this.setEditData = this.setEditData.bind(this);
    this.stringToDate = this.stringToDate.bind(this);
    this.getAwsRegionsFromMetadata=this.getAwsRegionsFromMetadata.bind(this)
    this.handleResourceRegionName = this.handleResourceRegionName.bind(this)
  }
  
  componentDidMount(){
    this.mounted = true
    this.getAwsRegionsFromMetadata()
  }
  dismissToastMessage = () => {
    this.setState({ onToastMsg: false });
  };

  isDate = date => {
    return date instanceof Date && !isNaN(date.valueOf());
  };
  parseDateString = (value, originalValue) => {
    const parsedDate = this.isDate(originalValue)
      ? originalValue
      : new Date(originalValue);
    return parsedDate;
  };

  oneYearAheadDate = (value, originalValue) => {
    if (this.isDate(originalValue)) {
      const date = originalValue.setFullYear(originalValue.getFullYear() + 1);
      //console.log('originalValue date',date)
      return date;
    } else {
      const aYearFromNow = new Date(originalValue);
      const date = new Date(
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
      );
      return date;
    }
  };
  today = new Date();

  schema = yup.object().shape({
    accId: yup
      .string()
      .required("Account ID is a required field")
      .matches(/^[0-9]+$/, "Account ID must be only digits")
      .min(12, "Account ID must be exactly 12 digits")
      .max(12, "Account ID must be exactly 12 digits"),
    peraId: yup.string().test({
      name: "severitybasedenablement",
      exclusive: false,
      params: {},
      message: "PeraId is a required field for high/critical severity rule",
      test: function (value) {
        if (
          (this.parent.ruleSeverity === "High" ||
            this.parent.ruleSeverity === "Critical") &&
          !this.parent.peraId
        ) {
          //console.log("this.parent.ruleSeverity", this.parent.ruleSeverity);
          return false;
        }
        return true;
      },
    }),
    resourceId: yup.string().required("Resource ID is a required field"),
    ticketId: yup
      .string()
      .required("Ticket ID is a required field")
      .min(11, "Ticket ID must be exactly 11 letters long")
      .max(11, "Ticket ID must be exactly 11 letters long")
      .matches(/^[a-zA-Z0-9,-]*$/, "Ticket ID should not contain spaces")
      .test(
        "RITM",
        "Ticket ID should start with RITM",
        val => val && val.startsWith("RITM")
      )
      .test(
        "RITMNUM",
        "Ticket ID should contain digits after RITM",
        val => val && !isNaN(val.substring(4))
      ),
    approvalDate: yup
      .date()
      .transform(this.parseDateString)
      .max(this.today, "The approval Date should not be in future"),
    lastRecertificationDate: yup
      .date()
      .transform(this.parseDateString)
      .max(this.today, "The recertification date should not be from the future."),
    expiryDate: yup
      .date()
      .transform(this.parseDateString)
      .min(
        this.today.setDate(this.today.getDate()),
        "The difference between Last Recertification Date and expiry date should be at least one day"
      )
      .test({
        name: "max",
        exclusive: false,
        params: {},
        message:
          "The Expiry date should not be greater than one year of the Last Recertification Date",
        test: function (value) {
          const currentDate = this.parent.lastRecertificationDate
          const endDate = value  
          const oneDayMilliseconds = 24 * 60 * 60 * 1000;
          const diffInMilliseconds = Math.abs(currentDate - endDate);
          const diffInDays = Math.round(diffInMilliseconds / oneDayMilliseconds);
          return diffInDays <= 365 ? true : false;
        },
      }),
      resourceRegionName:yup
      .string()
      .required("Region input field required!")
  });

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () =>
      this.schema
        .validate(this.state, { abortEarly: false })
        .then(valid => this.setState({ errorPaths: [], errors: [] })) //called if the entire form is valid
        .catch(err =>
          this.setState({
            errors: err.errors,
            errorPaths: err.inner.map(i => i.path),
          })
        )
    ); 
  };

  checkUpdateButtton() {
    const { ticketId,  } = this.state;
    const changed = ticketId !== this.props.dataToEdit.ServiceNowTicketId;
    return changed ? false : true;
  }
  checkUpdateButttonPeraId() {
    const { peraId } = this.state;
    const changed = peraId !== this.props.dataToEdit.PeraId;
    return changed ? false : true;
  }

  show = () => {
    this.setState({ open: true });
  };

  handleSubmit(event) {
    this.show();
  }

  handleCancel = () => {
    this.setState({ open: false });
  };

  todayDate = () =>{
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var todayDate = yyyy + "-" + mm + "-" + dd;
    return todayDate;
  }

  

  handleCalendarChangeToTodayDate(todayDate) {
    var dateMonthYear = todayDate.split("-");
    var convertedDate =
      dateMonthYear[1] + "/" + dateMonthYear[2] + "/" + dateMonthYear[0];
    return convertedDate;
  }



  setEditData = () => {
    
    var convApprovalDate = this.stringToDate(
      this.props.dataToEdit.ApprovalDate
    );

    var convLastRecertificationDate = this.stringToDate(this.handleCalendarChangeToTodayDate(this.state.todayDate));
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var todayDate = yyyy + "-" + mm + "-" + dd;
    var convExpiryDate = this.stringToDate(this.props.dataToEdit.ExpiryDate);
    this.setState(
      {
        ["ruleId"]: this.props.dataToEdit.RuleId,
        ["ruleName"]: this.props.dataToEdit.RuleName,
        ["accId"]: this.props.dataToEdit.AccountId,
        ["resourceId"]: this.props.dataToEdit.Exception,
        ["peraId"]: this.props.dataToEdit.PeraId,
        ["ticketId"]: this.props.dataToEdit.ServiceNowTicketId,
        ["approvalDate"]: convApprovalDate,
        ["expiryDate"]: convExpiryDate,
        ["lastRecertificationDate"]:  convLastRecertificationDate,
        ["accIdRuleId"]: this.props.dataToEdit.AccountId_RuleId,
        ["minDate"]: todayDate,
        ["resourceRegionName"]:this.props.dataToEdit.ExceptionRegion,
        ["isResourceRegionNameExists"]:this.props.dataToEdit.ExceptionRegion ? true : false
      },
      () => {
        this.readDataFromApiForRuleId();
      }
    );
  };

  handleDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var todayDate = yyyy + "-" + mm + "-" + dd;
    this.setState({ ["todayDate"]: todayDate });
  };

  readDataFromApiForRuleId = () => {
    const selectedUrl = this.props.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
      params: { RuleId: parseInt(this.state.ruleId) },
      headers: {
        Authorization: this.props.accessData.authState.accessToken.accessToken,
      },
    };
    axios
      .get(selectedUrl + "configrules/search", options)
      .then(res => {
        var ruleidList = res.data.body.data;
        var statusCode = res.data.status_code;

        if (statusCode === 200) {
          this.setState({ ["ruleSeverity"]: ruleidList[0]["Severity"] });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // handleChange = (event) => {
  //   this.setState({ [event.target.name]: event.target.value });
  // };

  handleDropDownChange = (event, data) => {
    this.setState({ [data.name]: data.value });
  };

  handleCalendarChangeToDbSchema(receivedData) {
    var dateMonthYear = receivedData.split("-");
    var convertedDate =
      dateMonthYear[1] + "/" + dateMonthYear[2] + "/" + dateMonthYear[0];
    return convertedDate;
  }

  handleConfirm = () => {
    const selectedUrl = this.props.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    this.setState((...preState)=>({...preState,open:false}))
    var convApprovalDate = this.handleCalendarChangeToDbSchema(
      this.state.approvalDate
    );

    var convLastRecertificationDate = this.handleCalendarChangeToDbSchema(
      this.state.lastRecertificationDate
    );

    var convExpiryDate = this.handleCalendarChangeToDbSchema(
      this.state.expiryDate
    );
    const {resourceRegionName,isResourceRegionNameExists} = this.state

    let getAccountName = this.props.acc_options?.find((item)=>item.value === this.state.accId)?.accountName
    const data = {
      AccountId: this.state.accId,
      Exception: this.state.resourceId,
      PeraId: this.state.peraId,
      ApprovalDate: convApprovalDate,
      RuleId: parseInt(this.state.ruleId),
      RuleName: this.state.ruleName,
      ExpiryDate: convExpiryDate,
      LastRecertificationDate: convLastRecertificationDate,
      ServiceNowTicketId: this.state.ticketId,
      AccountId_RuleId: this.state.accIdRuleId,
      LastModifiedBy: this.props.accessData.authState.accessToken.claims.sub,
      CreateTimeStamp: this.props.dataToEdit.CreateTimeStamp,
      UpdateTimeStamp: this.props.dataToEdit.UpdateTimeStamp,
      ExceptionStatus: this.state.status,
      ExceptionRegion: isResourceRegionNameExists ? resourceRegionName : resourceRegionName?.split('(')[0].split('+')[1],
      AccountName:getAccountName
    };
    const options = {
      headers: {
        Authorization: this.props.accessData.authState.accessToken.accessToken,
      },
    };
    axios
      .put(selectedUrl + "exception/RESOURCE", data, options)
      .then(res => {
        if (res.data.status_code === 201) {
          this.setState({
            onToastMsg: true,
            toastMessage: res.data.body.message,
            toastColour: "green",
          });
          this.props.dataEditedLoading();
        } else {
          this.setState({
            onToastMsg: true,
            toastMessage: res.data.body.message,
            toastColour: "red",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  readDataRuleIdFromApi = () => {
    const selectedUrl = this.props.selectCloud === commercialCloud ? config["apiendpoint"] : config.govCloudApi
    const options = {
      headers: {
        Authorization: this.props.accessData.authState.accessToken.accessToken,
      },
    };
    axios
      .get(selectedUrl + "configrules/RuleId", options)
      .then(res => {
        var ruleid_dict = res.data.body.data[0].RuleId;
        this.setState({ ["ruleId"]: ruleid_dict });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  stringToDate = receivedData => {
    var dateString = receivedData;
    var parts = dateString.split("/");
    var convertedDate = parts[2] + "-" + parts[0] + "-" + parts[1];
    //console.log(parts[2], parts[0], parts[1]);
    return convertedDate;
  };

  openOrCloseModalFunc = booldata => {
    this.props.handleEditPopUpClose();
  };
  getAwsRegionsFromMetadata=()=>{
    const selectedUrl = this.props.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    axios.get(`${selectedUrl}metadata/search?MetadataKey=ConfigEnabledRegions`,{
      headers:{
        'Authorization': this.props.accessData.authState.accessToken.accessToken
      }
    }).then((res)=>{
      const result = res.data?.body?.data
      if (result.length > 0) {
        let configEnabledRegions = result[0].Metadata.ConfigEnabledRegions.AwsRegions[0].MetadataValue
        let keyValueConfigEnabledRegions = []
        const size = configEnabledRegions.length
        if (this.state.applicableResourcesExists) {
          let globalValue = configEnabledRegions.find(item => item === "Global+global")
          let splitGlobalValue = globalValue.split('+')
          keyValueConfigEnabledRegions.push({
            key: 0,
            text: `${splitGlobalValue[0].split('-').join(' ')} (${splitGlobalValue[1]})`,
            value: globalValue,
            content: (
              <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>{splitGlobalValue[0].split('-').join(' ')}</div>
                <div>{splitGlobalValue[1]}</div>
              </div>
            )
          })
          if(this.mounted){
            this.setState({ resourceRegion: keyValueConfigEnabledRegions })
          }
        } else {
          for (var i = 0; i < size; i++) {
            let storeRegion = configEnabledRegions[i].split('+')
            keyValueConfigEnabledRegions.push({
              key: i,
              text: `${storeRegion[0].split('-').join(' ')} (${storeRegion[1]})`,
              value: configEnabledRegions[i],
              content: (
                <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>{storeRegion[0].split('-').join(' ')}</div>
                  <div>{storeRegion[1]}</div>
                </div>
              )
            })
          }
          if(this.mounted){
            this.setState({ resourceRegion: keyValueConfigEnabledRegions })
          }
        }
      }
    }).catch(e=>{
      console.log(e)
    })
  }
  handleResourceRegionName = (event, data) => {
    this.setState({ resourceRegionName: data.value },()=>{
      this.schema
      .validate(this.state, { abortEarly: false })
      .then(valid => this.setState({ errorPaths: [], errors: [] })) 
      .catch(err =>
        this.setState({
          errors: err.errors,
          errorPaths: err.inner.map(i => i.path),
        })
      )
    })
  }
  componentWillUnmount(){
    this.mounted = false
  }
  render() {
    const { data } = this.state;
    return (
      <ResourceExceptionEditForm
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        checkUpdateButtton={this.checkUpdateButtton}
        checkUpdateButttonPeraId={this.checkUpdateButttonPeraId}
        handleDropDownChange={this.handleDropDownChange}
        {...this.state}
        setEditData={this.setEditData}
        handleConfirm={this.handleConfirm}
        handleCancel={this.handleCancel}
        readDataRuleIdFromApi={this.readDataRuleIdFromApi}
        openModal={this.props.openModal}
        openOrCloseModalFunc={this.openOrCloseModalFunc}
        dateToString={this.dateToString}
        dismissToastMessage={this.dismissToastMessage}
        handleResourceRegionName={this.handleResourceRegionName}
        accountOptions={this.props.acc_options}
        selectCloud={this.props.selectCloud}
      />
    );
  }
}

export default ResourceExceptionEditFormContainer;
