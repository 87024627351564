import axios from "axios";
import config from "../../../../Config/Config";
import { commercialCloud } from "../../../../Config/appConstant";

const getAccountLists = (token,cloud) => new Promise((resolve, reject) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.get(`${url}account/account-list`, {
        headers: {
            'Authorization': token
        }
    }).then((response) => {
        resolve(response)
    }).catch(error => {
        reject(error)
    })
})

const getMetadataLists = (token,query,cloud) => new Promise((resolve, reject) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.get(`${url}metadata/trusted-vendor?MetadataKey=${query}`,
        {
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
})

const createTrustedVendor = (token, payload, cloud) => new Promise((resolve, reject) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.post(`${url}metadata/trusted-vendor`, payload,
        {
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
})

const updateTrustedVendor = (token,payload,cloud) => new Promise((resolve,reject)=>{
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.put(`${url}metadata/trusted-vendor`,payload,{headers: {
        'Authorization': token
    }}).then((response) => {
        resolve(response)
    }).catch(error => {
        reject(error)
    })
})

const deleteTrustedVendor = (token,payload,cloud) => new Promise((resolve,reject)=>{
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.delete(`${url}metadata/trusted-vendor?sortKey=${payload.key}&updateBy=${payload.updateBy}&type=${payload.type}`,{
        headers: {
        'Authorization': token
    }}).then((response) => {
        resolve(response)
    }).catch(error => {
        reject(error)
    })
})
const getAccountDetail = (token,payload,cloud)=> new Promise((reject,resolve) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    const {table,key,value} = payload
    axios.get(`${url}query?Table=${table}&Key=${key}&Value=${value}`,{headers:{
        'Authorization': token
    }})
    .then(res=>{
        resolve(res)
    }).catch(err=>{
        reject(err)
    })
})

const getMetadataDetailsFromKey = (token,query,cloud) => new Promise((resolve, reject) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.get(`${url}metadata/search?MetadataKey=${query}`,
        {
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
})

const getAccountList = (token,cloud)=> new Promise((resolve,reject)=>{
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.get(`${url}account/account-list`,{
        headers: {
            'Authorization': token
        }
    }).then((response) => {
        resolve(response)
    }).catch(error => {
        reject(error)
    })
})

export { 
    getAccountLists,
    getMetadataLists, 
    createTrustedVendor,
    updateTrustedVendor, 
    deleteTrustedVendor, 
    getAccountDetail ,
    getMetadataDetailsFromKey,
    getAccountList
}