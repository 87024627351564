import React, { Component } from "react";
import ConfigAccountAddDecomForm from "./ConfigAccountAddDecomForm";
import { TabPane, Tab, Segment } from 'semantic-ui-react'
import RestoreRemediation from "./RestoreRemediation";

const field_available_options = [
  { key: "t", text: "Account Monitored", value: 'ExceptionsCheckEnabled' },
  { key: "f", text: "Action Enabled", value: 'ActionEnabled' }
];
const forActionEnabled = [
  { key: "t", text: "True", value: "True" },
  { key: "f", text: "False", value: "False" }
]
const forExceptionsCheckEnabled = [
  { key: "t", text: "True", value: true },
  { key: "f", text: "False", value: false }
]




class ConfigAccountAddDecomFormContainer extends Component {
  render() {
    const RenderAccountUpdate = ()=>(
      <ConfigAccountAddDecomForm
      authState={this.props.authState}
      field_available_options={field_available_options}
      forActionEnabled={forActionEnabled}
      forExceptionsCheckEnabled={forExceptionsCheckEnabled}
      {...this.props}
      />
    )
    return (
      <Segment style={{
        borderColor: 'blue',
        borderStyle: 'Solid',
        margin: '6px',
      }}>
       <Tab
       panes={[
        { menuItem: 'Bulk Updates', render: () => 
        <TabPane style={{boxShadow:"none"}}>
          <RenderAccountUpdate/>
        </TabPane> },
        { menuItem: 'AutoRemediation Snapshot', render: () => 
        <TabPane style={{boxShadow:"none", minHeight:"300px"}}>
          <RestoreRemediation {...this.props}/>
        </TabPane> }
       ]} />
   </Segment>
    );
  }
}

export default ConfigAccountAddDecomFormContainer;