import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { SecureRoute,Security, LoginCallback } from '@okta/okta-react';
import history from './history'; 
import { OktaAuth } from '@okta/okta-auth-js';
import Home from './Home';
import Authentication from './Authentication';
import 'semantic-ui-css/semantic.min.css';
import config from './Config/Config';

// local testing
// const oktaAuth = new OktaAuth({
//     issuer: "https://verisk.oktapreview.com/",
//     clientId: '0oaw2w8u8tLmsxOpC0h7',
//     redirectUri: window.location.origin+'/login/callback'
//   });

//   qa testing 
// const oktaAuth = new OktaAuth({
//     issuer: "https://verisk.oktapreview.com/",
//     clientId: '0oaxior38ywzlXtg60h7',
//     redirectUri: window.location.origin+'/login/callback'
//   });

// dev testing 
const oktaAuth = new OktaAuth({
    issuer: config.issuer,
    clientId: config.clientid,
    redirectUri: window.location.origin+'/login/callback',
    scopes:['groups', 'profile', 'openid','email']
});

class App extends React.Component {
   render() {
       return (
           <Router>
                <Security oktaAuth={oktaAuth} >
                    <Route path='/' component={Authentication} />
                    {/* <SecureRoute path='/home' component={Home} /> */}
                    <Route path='/login/callback' component={LoginCallback} />
                </Security>
           </Router>
       );
   }
}
export default App;