import React, { Component } from 'react'
import axios from 'axios'
import { Segment, Select, Button, Form, Dimmer, Loader, Header } from 'semantic-ui-react'
import SearchResult from './SearchResult'
import config from '../../../../Config/Config'
import { cloudCompatibilityOption, commercialCloud, localStorageCloudValue } from '../../../../Config/appConstant'


export default class AutoRemediationAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rules: { data: [], options: [], isLoading: false },
            accounts: { data: [], isLoading: false },
            form: {
                account: ''
            },
            searchedResult: {
                data: [],
                length: -1,
                isLoading: false,
                displayView: false
            },
            selectCloud:"aws"
        }
        this.getAccountLists = this.getAccountLists.bind(this)
        this.getConfigRuleLists =this.getConfigRuleLists.bind(this)
        this.advanceSearch = this.advanceSearch.bind(this)
        this.onChangeHandler = this.onChangeHandler.bind(this)
        this.handleSelectCloudSpace = this.handleSelectCloudSpace.bind(this)
    }
    getAccountLists = () => {
        this.setState({ accounts: { ...this.state.accounts, isLoading: true } })
        const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
        this.props.authState.accessToken.accessToken && axios.get(`${selectedUrl}account/account-list`, {
            headers: {
                Authorization: this.props.authState.accessToken.accessToken
            }
        }).then((res) => {
            const result = res.data.body.data;
            this.setState({ ...this.state, accounts: { ...this.state.accounts, data: result, isLoading: false } })
        }).catch(err => {
            console.error(err)
            this.setState({ ...this.state, accounts: { ...this.state.accounts, isLoading: false } })
        })
    }

    getConfigRuleLists = ()=>{
        this.setState({ rules: { ...this.state.rules, isLoading: true } })
        const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
        this.props.authState.accessToken && axios.get(`${selectedUrl}configrules`, {
            headers: {
                Authorization: this.props.authState.accessToken.accessToken
            }
        }).then((res) => {
            const result = res?.data?.body?.data;
            const optionList = result.filter((item) => item.ActionAvailable !== false).map((item) => {
                return {
                    key: item.RuleId,
                    text: `${item.RuleId} ${item.RuleName}`,
                    value: item.RuleId
                }
            })

            this.setState({ ...this.state, rules: { ...this.state.rules, data: result, options: optionList, isLoading: false } })
        }).catch(err => {
            console.error(err)
            this.setState({ ...this.state, rules: { ...this.state.rules, isLoading: false } })
        })
    }

    onChangeHandler = (e, data) => {
        this.setState({ ...this.state, form: { ...this.state.form, [data.name]: data.value }, searchedResult: { ...this.state.searchedResult, data: [], length: -1, displayView: false } })
    }

    advanceSearch = () => {
        const { form } = this.state
        this.setState({
            ...this.state,
            searchedResult: {
                ...this.state.searchedResult,
                length: -1,
                isLoading: true,
                displayView: false
            }
        })
        let query = [
            {
                key: "AccountId",
                value: form.account
            },
            {
                key: "Table",
                value: "ACCOUNT_ATTR_TABLE"
            }
        ]

        let search = ""

        query.map((item) => {
            if (search !== "") {
                if (item.value) {
                    search += `&${item.key}=${item.value}`
                }
            }
            else {
                if (item.value) {
                    search += `${item.key}=${item.value}`
                }
            }
        })
        const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
        this.props.authState &&
            axios.get(`${selectedUrl}advance-search?${search}`, {
                headers: {
                    Authorization: this.props.authState.accessToken.accessToken
                }
            }).then(res => {
                this.setState({
                    ...this.state,
                    searchedResult: {
                        ...this.state.searchedResult,
                        data: {
                            body: {
                              data: res.data?.body.data.map((item) => {
                                return {
                                  ...item,
                                  ActionEnabled: `${item?.ActionEnabled?.slice(0, 1).toUpperCase()}${item?.ActionEnabled?.slice(1).toLowerCase()}`
                                }
                              })
                            }
                        },
                        length: Object.keys(res.data?.body?.data[0]).length || 0,
                        isLoading: false,
                        displayView: true
                    }
                })
            }).catch(err => {
                console.error(err)
                this.setState({
                    ...this.state,
                    searchedResult: {
                        ...this.state.searchedResult,
                        isLoading: false,
                        displayView: false
                    }
                })
            })
    }
    handleSelectCloudSpace = (event, data) => {
        localStorage.setItem(localStorageCloudValue,data?.value)
        this.setState((prevState) => ({
            ...prevState, [data.name]: data.value,
            searchedResult: { ...prevState.searchedResult, data: [], displayView: false, length: -1 }
        }), () => {
            this.getAccountLists()
            this.getConfigRuleLists()
        });
    };
    componentDidMount() {
        let value = localStorage.getItem(localStorageCloudValue)
        if(value !== null && value !==""){
          this.handleSelectCloudSpace({},{name:"selectCloud",value:value})
        }else{
            this.getAccountLists()
            this.getConfigRuleLists()
        }
    }

    render() {
        const { form, searchedResult } = this.state
        return (
            <Segment
                size="mini"
                style={{
                    padding: "30px",
                    borderColor: "blue",
                    borderStyle: "Solid",
                    margin: "6px",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
                    <Header as="h3" color="blue"> Auto-remediation report for account.</Header>
                    <div>
                        <b style={{ fontSize: 13, marginBottom: 2 }}>Selected organization:</b>
                        <Select
                            fluid
                            label='Select Cloud'
                            options={cloudCompatibilityOption}
                            value={this.state.selectCloud}
                            name="selectCloud"
                            onChange={this.handleSelectCloudSpace}
                            placeholder='Select Cloud'
                            style={{ position: "centre", width: "150px" }}
                        />
                    </div>
                </div>
                <Form onSubmit={this.advanceSearch} id="account-search">
                    <Dimmer active={this.state.searchedResult.isLoading} inverted ><Loader /></Dimmer>
                    <Form.Group>
                        <Form.Field
                            control={Select}
                            options={this.state.accounts.data}
                            label={{ children: 'Accounts', htmlFor: 'form-select-account' }}
                            placeholder='Select or search account'
                            clearable
                            search
                            searchInput={{ id: 'form-select-account' }}
                            style={{ width: "21em" }}
                            name="account"
                            onChange={(e, data) => this.onChangeHandler(e, data)}
                            loading={this.state.accounts.isLoading}
                        />
                        <Button type='submit' style={{ height: "40px", marginTop: "23px" }} disabled={form.account === "" ? true : false}>Search</Button>
                    </Form.Group>

                </Form>
                {(form.account !== "" && searchedResult.displayView && searchedResult.length > 0)
                    ? (<SearchResult {...this.state} />)
                    : searchedResult.length === 0 ? 'No data found!' : ('')}
            </Segment>
        )
    }
}
