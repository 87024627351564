import axios from "axios";
import config from "../../../../Config/Config";
import { commercialCloud } from "../../../../Config/appConstant";

export const listOfRuleInS3BucketExists = (selectCloud,token)=> new Promise((resolve,reject)=>{
    let url = (commercialCloud === selectCloud?.toLowerCase()) ? config.apiendpoint : config.govCloudApi
    axios.get(`${url}snapshot/config-rules`, {
        headers: {
            Authorization: token
        }
    }).then(res => {
        resolve(res)
    }).catch(err => {
        reject(err)
    })
})

export const createArchiveRecord = (selectCloud,token,payload)=> new Promise((resolve,reject)=>{
    let url = (commercialCloud === selectCloud?.toLowerCase()) ? config.apiendpoint : config.govCloudApi
    axios.post(`${url}archive-file`,payload,{
        Authorization:token
    }).then((res)=>{
        resolve(res)
    }).catch(err=>{
        console.error(err)
        reject(err)
    })
})


export const readS3FileData = (selectCloud,token,fileName) => new Promise((resolve,reject)=>{
    let url = commercialCloud === selectCloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.get(`${url}snapshot/search?fileName=${fileName}`, {
        headers: {
            Authorization: token
        }
    }).then(res => {
        resolve(res)
    }).catch(err => {
        reject(err)
    })
})

export const skipRemediation = (selectCloud,token,payload) => new Promise((resolve,reject)=>{
    let url = commercialCloud === selectCloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.post(`${url}snapshot/skip`,payload, {
        headers: {
            Authorization: token
        }
    }).then(res => {
        resolve(res)
    }).catch(err => {
        reject(err)
    })
})

export const handleRestore = (selectCloud, token, payload) => new Promise((resolve,reject)=>{
    let url = commercialCloud === selectCloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.put(`${url}snapshot/restore`, payload, {
        headers: {
            Authorization: token
        }
    }).then(res => {
        resolve(res)
    }).catch(err => {
        reject(err)
    })
})