import React, { Component } from "react";
import ResourceExceptionFormComp from "./ResourceExceptionFormComp";
import axios from "axios";
import * as yup from "yup";
import config from "../../../../Config/Config";
import { commercialCloud, localStorageCloudValue } from "../../../../Config/appConstant";
// Added code for toast message


class ResourceExceptionFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionListData: "",
      ruleId: "",
      ruleName: "",
      accId: "",
      resourceId: "",
      ticketId: "",
      peraId: "",
      approvalDate: "",
      expiryDate: "",
      AccountId_RuleId: "",
      resourceChecksum: "",
      resourceExceptionType: "",
      status: "NEW",
      resource_tooltip: "",
      resourceArnValidation: false,
      additionalValidation:false,
      ruleSeverity: "",
      toastMessage: "",
      onToastMsg: false,
      toastColour: "green",
      acc_options: [],
      todayDate: "",
      resourceRegion: [],
      resourceRegionName: "",
      storeAwsGlobalResources: [],
      getApplicableResources: [],
      applicableResourcesExists: false,
      ruleStore:[],
      resourceExceptionPattern:{
        errorMessage:'',
        exceptionExample:[],
        isAccountRequired:false,
        isRegionRequired:false
      },
      additionalEntityValidation:{
        isAdditionalEntityRequired:false,
        additionalEntityExample:[],
        additionalEntityPattern:''
      },
      storeValidateExceptionPattern:[],
      openModal:true,
      selectCloud:'',
      ruleIdName:''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.clearClick = this.clearClick.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleCalendarChangeToDbSchema =
      this.handleCalendarChangeToDbSchema.bind(this);
    this.formatDate = this.formatDate.bind(this)
    this.getAwsRegionsFromMetadata = this.getAwsRegionsFromMetadata.bind(this)
    this.handleResourceRegionName = this.handleResourceRegionName.bind(this)
    this.getAwsGlobalResourceFromMetadataTable = this.getAwsGlobalResourceFromMetadataTable.bind(this)
    this.getValidateExceptionPattern = this.getValidateExceptionPattern.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleSelectCloudSpace = this.handleSelectCloudSpace.bind(this)
    this.getSeletedCloud = this.getSeletedCloud.bind(this)
  }
  // componentDidMount() {
  //   this.getAwsRegionsFromMetadata()
  //   this.getAwsGlobalResourceFromMetadataTable()
  //   this.getValidateExceptionPattern()
  // }
  isDate = date => {
    return date instanceof Date && !isNaN(date.valueOf());
  };
  parseDateString = (value, originalValue) => {
    const parsedDate = this.isDate(originalValue)
      ? originalValue
      : new Date(originalValue);
    return parsedDate;
  };

  oneYearAheadDate = (value, originalValue) => {
    if (this.isDate(originalValue)) {
      const date = originalValue.setFullYear(originalValue.getFullYear() + 1);
      return date;
    } else {
      const aYearFromNow = new Date(originalValue);
      const date = new Date(
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
      );
      return date;
    }
  };
  today = new Date();

  schema = yup.object().shape({
    accId: yup
      .string()
      .required("Account ID is a required field")
      .matches(/^[0-9]+$/, "Account ID must be only digits")
      .min(12, "Account ID must be exactly 12 digits")
      .max(12, "Account ID must be exactly 12 digits"),
    peraId: yup.string().test({
      name: "severitybasedenablement",
      exclusive: false,
      params: {},
      message: "PeraId is a required field for high/critical severity rule",
      test: function (value) {
        if (
          (this.parent.ruleSeverity === "High" ||
            this.parent.ruleSeverity === "Critical") &&
          !this.parent.peraId
        ) {
          // console.log('this.parent.ruleSeverity',this.parent.ruleSeverity)
          return false;
        }
        return true;
      },
    }),
    resourceChecksum: yup.string().test({
      name: "additionalentity",
      exclusive: false,
      params: {},
      message:
        "Additional entity is required while adding composite exceptions",
      test: function (value) {
        if (
          (this.parent.ruleName === "VA-iam-pb-compliance" ||
            this.parent.ruleName === "DEV-iam-pb-compliance" ||
            this.parent.ruleName === "QA-iam-pb-compliance") ||
            this.parent?.resourceExceptionType?.includes("Composite") &&
          !this.parent.resourceChecksum 
        ) {
          // console.log('this.parent.ruleSeverity',this.parent.ruleSeverity)
          return false;
        }
        return true;
      },
    }).test({
      message:()=>{
        return `Additional Entity must follow the pattern. For example: ${this.state?.additionalEntityValidation?.additionalEntityExample?.join(" OR ")}`
      },
      test:function(value){
        if(this.parent?.resourceExceptionType?.includes("Composite") && this.parent?.resourceChecksum!==""){
          if(this.parent?.additionalEntityValidation?.isAdditionalEntityRequired){
            return this.parent?.additionalValidation
          }
        }else{
          return true
        }
      }
    }),
    resourceId: yup
      .string()
      .required("Resource ID is a required field")
      .test(
        {
          name:"testingarnvalidate",
          message:()=>{
            let isRuleExistsInMetadataTable = this.state.storeValidateExceptionPattern?.filter((item)=>item.rules?.includes(parseInt(this.state.ruleId)))
            if(isRuleExistsInMetadataTable?.length>0){
              return `ResourceID must follow the pattern. For example: ${this.state?.resourceExceptionPattern?.exceptionExample?.join(" OR ")}`
            }
            else{
              return `${this.state?.resourceExceptionPattern?.exceptionExample?.join(" OR ")}`
            }
            
          },
          test:function (value,ctx) {
            if(this.parent?.resourceId !== ""){
              return this.parent.resourceArnValidation;
            }else{
              return true
            }
        }
        }
        )
      .test({
        message:"Account ID must be same in Resource ARN!",
        test: function(value){
          if(this.parent.resourceExceptionPattern?.isAccountRequired){
            if(this.parent?.resourceId!==""){
              return this.parent.resourceId?.includes(this.parent?.accId)
            }else{
              return true
            }
          }else{
            return true
          }
        }
      }).test({
        message:"Region must be same in Resource ARN!",
        test: function(value){
          if(this.parent.resourceExceptionPattern?.isRegionRequired){
            if(this.parent?.resourceId!==""){
              return this.parent.resourceId?.includes(this.parent?.resourceRegionName?.split("+")[1])
            }else{
              return true
            }
           
          }else{
            return true
          }
        }
      }),
    ticketId: yup
      .string()
      .required("Ticket ID is a required field")
      .min(11, "Ticket ID must be exactly 11 letters long")
      .max(11, "Ticket ID must be exactly 11 letters long")
      .matches(/^[a-zA-Z0-9,-]*$/, "Ticket ID should not contain spaces")
      .test(
        "RITM",
        "Ticket ID should start with RITM",
        val => val && val.startsWith("RITM")
      )
      .test(
        "RITMNUM",
        "Ticket ID should contain digits after RITM",
        val => val && !isNaN(val.substring(4))
      ),
    approvalDate: yup
      .date()
      .transform(this.parseDateString)
      .max(this.today, "The approval Date should not be in future"),
    expiryDate: yup
      .date()
      .transform(this.parseDateString)
      .min(
        this.today.setDate(this.today.getDate()),
        "The difference between approval and expiry date should be at least one day and expiry date should be greater than current date"
      )
      .test({
        name: "max",
        exclusive: false,
        params: {},
        message:
          "The Expiry date should not be greater than one year of the approval date",
        test: function (value) {
          const currentDate = this.parent.approvalDate
          const endDate = value  
          const oneDayMilliseconds = 24 * 60 * 60 * 1000;
          const diffInMilliseconds = Math.abs(currentDate - endDate);
          const diffInDays = Math.round(diffInMilliseconds / oneDayMilliseconds);
          return diffInDays <= 365 ? true : false;
        },
      }),
      resourceRegionName:yup
      .string()
      .required("Region input field required!")
  });

  handleChange = async event => {
    if (event.target.name === "resourceId" ) {
      this.validateResourceArn(event.target.value);
    }
    else if(event.target.name === "resourceChecksum"){
      this.validateAdditionalEntity(event.target.value)
    }

    this.setState({ [event.target.name]: event.target.value }, async() =>
      await this.schema
        .validate(this.state, { abortEarly: false })
        .then(valid => this.setState({ errorPaths: [], errors: [] })) //called if the entire form is valid
        .catch(err =>
          {
          this.setState({
            errors: err?.errors,
            errorPaths: err.inner.map(i => i.path),
          })
          }
        )
    ); //called if any field is invalid
  };

  handleDropDownChange = async (event, data) => {
    this.clearClick();
    var ruleIdruleName = data.value.split(" ", 2);
    const { getApplicableResources, storeAwsGlobalResources } = this.state
    let filterApplicableResource = getApplicableResources.filter(item => item.RuleId === parseInt(ruleIdruleName[0]))
    let isApplicableResource = filterApplicableResource[0].ApplicableResources.some(item => storeAwsGlobalResources.includes(item))
    this.setState({ 
     ["ruleId"]: ruleIdruleName[0],
     ["ruleName"]: ruleIdruleName[1], 
     applicableResourcesExists: isApplicableResource,
     errors: [],
     errorPaths: [],
     ruleIdName:data.value
     }, () => {
      this.readDataFromApiForRuleId();
      this.getAwsRegionsFromMetadata()
    });
  };

  handleDropDownChangeForAccId = (event, data) => {
    this.setState({ [data.name]: data.value },async()=>{
      await this.schema
        .validate(this.state, { abortEarly: false })
        .then(valid => this.setState({ errorPaths: [], errors: [] }))
        .catch(err =>{
          this.setState({
            errors: err.errors,
            errorPaths: err.inner.map(i => i.path),
          })
        })
    });
  };

  readDataAccountIdFromApi = () => {
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
      headers: {
        Authorization: this.props.authState.accessToken.accessToken,
      },
    };
    axios
      .get(selectedUrl + "account/account-list", options)
      .then(res => {
        var acc_list_created = res.data.body.data;
        this.setState({ ["acc_options"]: acc_list_created });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  readDataFromApiForRuleId = () => {
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
      params: { RuleId: parseInt(this.state.ruleId) },
      headers: {
        Authorization: this.props.authState.accessToken.accessToken,
      },
    };
    axios
      .get(selectedUrl + "configrules/search", options)
      .then(res => {
        var ruleidList = res.data.body.data;
        var statusCode = res.data.status_code;

        if (statusCode === 200) {
          this.setState({
            ["resource_tooltip"]: ruleidList[0]["ExceptionFormat"],
          });
          this.setState({
            ["resourceExceptionType"]: ruleidList[0]["ExceptionType"],
          });
          this.setState({ ["ruleSeverity"]: ruleidList[0]["Severity"] });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  validateAdditionalEntity = (event)=>{
    try{
      const {storeValidateExceptionPattern,ruleId} = this.state
      let getData = storeValidateExceptionPattern?.filter((item)=>item.rules?.includes(parseInt(ruleId)))
      if(getData?.length>0){
        
        for(let i=0;i<getData?.length;i++){
          let result = event?.match(getData[i]?.additionalEntityPattern?.javaScript) === null ? false : true
          this.setState({
            additionalValidation:result,
            additionalEntityValidation:{
              isAdditionalEntityRequired:getData[i]?.isAdditionalEntityRequired,
              additionalEntityExample:getData?.map((item)=>item?.additionalEntityExample),
              additionalEntityPattern:getData[i]?.additionalEntityPattern
            }
          });
          if(result){
            break;
        }
        }
        
      }else{
        this.setState({
          additionalValidation:false,
          additionalEntityValidation:{
            isAdditionalEntityRequired:false,
            additionalEntityExample:[],
            additionalEntityPattern:''
          }
        });
      }
    }
    catch(err){
      console.error(err)
    }
  }
  validateResourceArn =  resValue => {
    try{
      const {storeValidateExceptionPattern,ruleId} = this.state
      let getData = storeValidateExceptionPattern?.filter((item)=>item.rules?.includes(parseInt(ruleId)))
      if(getData?.length>0){
        for(let i=0;i<getData?.length;i++){
          let result = resValue?.match(getData[i]?.exceptionPattern?.javaScript) === null ? false : true
          this.setState({ 
            ["resourceArnValidation"]: result,
            resourceExceptionPattern:{
              errorMessage:getData[i]?.exceptionPattern?.javaScript,
              exceptionExample:getData?.map((item)=>item.exceptionExample),
              isAccountRequired:getData[i]?.isAccountRequired,
              isRegionRequired:getData[i]?.isRegionRequired
            } });
          if(result){
              break;
          }
        }
      }else{
        this.setState({ 
          ["resourceArnValidation"]: false,
          resourceExceptionPattern:{
            errorMessage:"RuleIdNotFound",
            exceptionExample:[`Resource pattern not found. Please add ${this.state.ruleId} Rule ID to the MetadataKey "ValidateExceptionPattern".`],
            isAccountRequired:false,
            isRegionRequired:false
          } });
      }
    }
    catch(err){
      console.error(err)
    }
  };

  show = () => {
    this.setState({ open: true });
  };

  handleCalendarChangeToDbSchema(dateValueReceived) {
    var dateMonthYear = dateValueReceived.split("-");
    var convertedDate =
      dateMonthYear[1] + "/" + dateMonthYear[2] + "/" + dateMonthYear[0];
    return convertedDate;
  }
  handleConfirm = async () => {
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    this.setState({ result: "confirmed", open: false });
    var convApprovalDate = this.handleCalendarChangeToDbSchema(
      this.state.approvalDate
    );
    var convExpiryDate = this.handleCalendarChangeToDbSchema(
      this.state.expiryDate
    );
    if (this.state.resourceExceptionType === "Composite") {
      let compositeResourceName =
        this.state.resourceId + "+" + this.state.resourceChecksum;
      await this.setState({ ["resourceId"]: compositeResourceName });
    }
    let getAccountName = this.state.acc_options?.find((item)=>item.value === this.state.accId)?.accountName
    const data = {
      AccountId: this.state.accId,
      Exception: this.state.resourceId,
      ServiceNowTicketId: this.state.ticketId,
      PeraId: this.state.peraId,
      RuleId: parseInt(this.state.ruleId),
      RuleName: this.state.ruleName,
      ApprovalDate: convApprovalDate,
      ExpiryDate: convExpiryDate,
      AccountId_RuleId: this.state.accId + "_" + this.state.ruleId,
      LastModifiedBy: this.props.authState.accessToken.claims.sub,
      ExceptionStatus: this.state.status,
      ExceptionRegion: this.state.resourceRegionName?.split('(')[0].split('+')[1],
      AccountName: getAccountName
    };

    const options = {
      headers: {
        Authorization: this.props.authState.accessToken.accessToken,
      }
    };
    axios
      .post(selectedUrl + "exception/RESOURCE", data, options)
      .then(res => {
        if (res.data.status_code === 201) {
          this.setState({
            onToastMsg: true,
            toastMessage: res.data.body.message,
            toastColour: "green",
          });
          this.clearClick();
        } else {
          this.setState({
            onToastMsg: true,
            toastMessage: res.data.body.message,
            toastColour: "red",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getSeletedCloud = (data)=> data[`${this.state.selectCloud?.split('_')[0].toLowerCase()}Cloud`]
  readDataFromApi = () => {
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
      headers: {
        Authorization: this.props.authState.accessToken.accessToken,
      },
    };
    axios
      .get(selectedUrl + "configrules", options)
      .then(res => {
        var filteredData = res.data.body.data;
        var ruleid_list = filteredData?.filter((item)=>item?.ExceptionType!=="Not_Applicable" && (item?.CloudCompatibility !== undefined && item?.CloudCompatibility[this.state.selectCloud]))
        let option_list = [];
        let applicableResources = []
        ruleid_list.map(myFunction);

        function myFunction(element) {
          var new_element = {};
          new_element["key"] = element["RuleId"];
          new_element["text"] = element["RuleId"] + " " + element["RuleName"];
          new_element["value"] = element["RuleId"] + " " + element["RuleName"];
          option_list.push(new_element);
        }
        if (ruleid_list.length > 0) {
          ruleid_list.map((item) => {
            applicableResources.push({
              RuleId: item.RuleId,
              ApplicableResources: item.ApplicableResources
            })
          })
        }
        this.setState({ ["optionListData"]: option_list, getApplicableResources: applicableResources,ruleStore:ruleid_list });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleCancel = () => {
    this.setState({ result: "cancelled", open: false });
  };

  clearClick = () => {
    this.setState({
      accId: "",
      resourceId: "",
      ticketId: "",
      peraId: "",
      resourceChecksum: "",
      resourceRegionName:"",
      ruleIdName:'',
      ruleId:'',
      ruleName:''
    });
    this.handleDate();
  };

  handleSubmit(event) {
    this.show();
  }

  dismissToastMessage = () => {
    this.clearClick();
    this.setState({ onToastMsg: false });
  };

  formatDate = (dd, mm, yyyy) => `${yyyy}-${mm.toString().padStart(2, "0")}-${dd.toString().padStart(2, "0")}`
  handleDate = () => {
    try { 
      const resourceExpiryDays = 365
      const today = new Date()
      const expiryDate = new Date()
      expiryDate.setDate(today.getDate() + resourceExpiryDays)
      
      this.setState({ 
        todayDate: today.toISOString()?.slice(0,10),
        approvalDate: today.toISOString()?.slice(0,10),
        expiryDate: expiryDate.toISOString()?.slice(0,10)
      });
    }
    catch (err) {
      console.error(err)
    }
  };
  getAwsRegionsFromMetadata = () => {
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    axios.get(`${selectedUrl}metadata/search?MetadataKey=ConfigEnabledRegions`, {
      headers: {
        'Authorization': this.props.authState.accessToken.accessToken
      }
    }).then((res) => {
      const result = res.data?.body?.data
      if (result.length > 0) {
        let configEnabledRegions = result[0].Metadata.ConfigEnabledRegions.AwsRegions[0].MetadataValue
        let keyValueConfigEnabledRegions = []
        const size = configEnabledRegions.length
        if (this.state.applicableResourcesExists) {
          let globalValue = configEnabledRegions.find(item => item === "Global+global")
          let splitGlobalValue = globalValue.split('+')
          keyValueConfigEnabledRegions.push({
            key: 0,
            text: `${splitGlobalValue[0].split('-').join(' ')} (${splitGlobalValue[1]})`,
            value: globalValue,
            content: (
              <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>{splitGlobalValue[0].split('-').join(' ')}</div>
                <div>{splitGlobalValue[1]}</div>
              </div>
            )
          })
          this.setState({ resourceRegion: keyValueConfigEnabledRegions })
        } else {
          for (var i = 0; i < size; i++) {
            let storeRegion = configEnabledRegions[i].split('+')
            keyValueConfigEnabledRegions.push({
              key: i,
              text: `${storeRegion[0].split('-').join(' ')} (${storeRegion[1]})`,
              value: configEnabledRegions[i],
              content: (
                <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>{storeRegion[0].split('-').join(' ')}</div>
                  <div>{storeRegion[1]}</div>
                </div>
              )
            })
          }
          this.setState({ resourceRegion: keyValueConfigEnabledRegions })
        }
      }
    }).catch(e => {
      console.log(e)
    })
  }
  handleResourceRegionName = (event, data) => {
    this.setState({ resourceRegionName: data.value },async()=>{
      await this.schema
        .validate(this.state, { abortEarly: false })
        .then(valid => this.setState({ errorPaths: [], errors: [] }))
        .catch(err =>{
          this.setState({
            errors: err.errors,
            errorPaths: err.inner.map(i => i.path),
          })
        })
    })
  }
  getAwsGlobalResourceFromMetadataTable = () => {
    
    axios.get(`${config.apiendpoint}metadata/search?MetadataKey=AWSGlobalResources`, {
      headers: {
        'Authorization': this.props.authState.accessToken.accessToken
      }
    }).then((res) => {
      const result = res.data?.body?.data
      if (result.length > 0) {
        let awsGlobalResources = result[0].Metadata.AWSGlobalResources.ResourceTypes[0].MetadataValue
        this.setState({ storeAwsGlobalResources: awsGlobalResources })
      }
    }).catch(e => {
      console.log(e)
    })
  }
  getValidateExceptionPattern = () => {
    
    axios.get(`${config.apiendpoint}metadata/search?MetadataKey=ValidateExceptionPattern`, {
      headers: {
        'Authorization': this.props.authState.accessToken.accessToken
      }
    }).then((res) => {
      const result = res.data?.body?.data
      if (result.length > 0) {
        let resourcePatternList = result[0].Metadata.ValidateExceptionPattern?.ExceptionPattern[0].MetadataValue
        this.setState({ storeValidateExceptionPattern: resourcePatternList })
      }
    }).catch(e => {
      console.log(e)
    })
  }
  handleSelectCloudSpace = (event, data) => {
    localStorage.setItem(localStorageCloudValue,data?.value)
    this.setState((prevState)=>({...prevState, [data.name]: data.value,
      openModal: false,
      ruleIdName:"",
      ruleId:"",
      ruleName:"",
      optionListData:"",
      accId: "",
      resourceId: "",
      ticketId: "",
      peraId: "",
      resourceChecksum: "",
      resourceRegionName:""
    }),()=>{
      this.readDataFromApi();
      this.handleDate();
      this.readDataAccountIdFromApi();
      this.getAwsRegionsFromMetadata()
      this.getAwsGlobalResourceFromMetadataTable()
      this.getValidateExceptionPattern()
    });
  };

  

  handleCloseModal = ()=>{
    this.setState((prevState)=>({...prevState,openModal: false}),()=>{
      window.location.href = "/"
    });
  }
  componentDidMount(){
    let value = localStorage.getItem(localStorageCloudValue)
    if(value !== null && value !==""){
      this.handleSelectCloudSpace({},{name:"selectCloud",value:value})
    }
  }
  render() {
    return (
      <ResourceExceptionFormComp
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        handleDropDownChange={this.handleDropDownChange}
        {...this.state}
        accessToken={this.props.authState.accessToken.accessToken}
        handleDate={this.handleDate}
        readDataFromApi={this.readDataFromApi}
        clearClick={this.clearClick}
        handleConfirm={this.handleConfirm}
        handleCancel={this.handleCancel}
        dismissToastMessage={this.dismissToastMessage}
        handleDropDownChangeForAccId={this.handleDropDownChangeForAccId}
        readDataAccountIdFromApi={this.readDataAccountIdFromApi}
        handleResourceRegionName={this.handleResourceRegionName}
        configExceprionResource={this.state.storeValidateExceptionPattern}
        handleCloseModal={this.handleCloseModal}
        handleSelectCloudSpace={this.handleSelectCloudSpace}
      />
    );
  }
}

export default ResourceExceptionFormContainer;
