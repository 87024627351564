import React, { Component } from 'react'
import Papa from 'papaparse'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import ReactEcharts from 'echarts-for-react'
import { Table, Popup, Pagination, Icon, Input, Dropdown, Image, Button, Grid, Checkbox, Card, Accordion } from 'semantic-ui-react'
import { tableHeader, dataPerPageOptions, pieChartDefaultValue, severityKey, actionEnabledOptions, halfDonutOption } from './reportConstant'
import FilterBox from './FilterBox'
import csvIcon from '../../../../Assets/Images/csv_icon.png'
export default class SearchResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      dataPerPage: 10,
      sortTable: false,
      toggleTableView: true,
      form: {
        actionEnabled: [],
        exceptionsCheckEnabled: '',
        search: '',
        severity: ''
      },
      sortTable: false,
      sortTableHeaderIndex: -1,
      toggleTableView: true,
      storeAccountsData: [],
      storeCheckAccountsData: [],
      downloadMarkedDataRecord: [],
      downloadMarkedAllData: false,
      pieChartData: pieChartDefaultValue,
      severityData: [],
      activeIndex: -1
    }
    this.handlePageChange = this.handlePageChange.bind(this)
    this.onDropdownChangeHandler = this.onDropdownChangeHandler.bind(this)
    this.onSearchHandler = this.onSearchHandler.bind(this)
    this.handleDownloadCsv = this.handleDownloadCsv.bind(this)
    this.handleDownloadPdf = this.handleDownloadPdf.bind(this)
    this.checkboxHandler = this.checkboxHandler.bind(this)
    this.onClickPieChart = this.onClickPieChart.bind(this)
    this.onClickDonutChart = this.onClickDonutChart.bind(this)
    this.handleSortTable = this.handleSortTable.bind(this)
    this.handleClick = this.handleClick.bind(this)

  }
  handlePageChange = (event, data) => {
    this.setState({ ...this.state, currentPage: data.activePage });
  }
  onSearchHandler = () => {
    const { form } = this.state
    const rulesWithActionAvailableTrue = this.props?.rules?.data.filter((item) => item.ActionAvailable === true)

    const _copyStoreAccountsData = this.props.searchedResult?.data?.body?.data?.filter((item) => {
      return this.props.rules.options?.some((res) => item.RuleId === res.value)
    })
    const copyStoreAccountsData = _copyStoreAccountsData?.map((item) => {
      return {
        ...item,
        Severity: rulesWithActionAvailableTrue?.find((data) => data.RuleId === item.RuleId)?.Severity
      }
    })
    let filterStoreAccountsData = []
    if (form.actionEnabled.length === 0 && form.exceptionsCheckEnabled === "" && form.severity === "") {
      filterStoreAccountsData = copyStoreAccountsData
    }
    else {
      filterStoreAccountsData = copyStoreAccountsData?.filter(obj => {
        if (form.actionEnabled.length > 0 && !form.actionEnabled.includes(obj.ActionEnabled)) {
          return false;
        }
        if (form.severity !== "" && obj.Severity.toLowerCase() !== form.severity.toLowerCase()) {
          return false;
        }
        if (form.exceptionsCheckEnabled !== "" && obj.ExceptionsCheckEnabled !== form.exceptionsCheckEnabled) {
          return false;
        }
        return true;
      });
    }
    this.setState({
      ...this.state, storeAccountsData: filterStoreAccountsData, currentPage: 1
    })
  }
  onDropdownChangeHandler = (e, data) => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, [data.name]: data.value, search: '' }
    }, () => {
      this.onSearchHandler()
    })
  }
  handleDownloadCsv = (data, fileName) => {
    if (!data && !fileName) return
    const downloadData = data.map((item) => {
      return {
        RuleId: item.RuleId, RuleName: item.RuleName,
        RuleMonitored: item.ExceptionsCheckEnabled,
        ActionEnabled: item.ActionEnabled,
        Severity: item.Severity
      }
    })
    const csv = Papa.unparse(downloadData);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${fileName}.csv`);
    tempLink.click();
  }
  handleDownloadPdf = (body, header, fileName) => {
    if (!body && !header && !fileName) return
    const pdf = new jsPDF("p", "px")
    const now = new Date();
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    const orderedHeader = header.map((item) => item.text)
    const orderedBody = body?.map((item) => {
      return [
        item.RuleId, item.RuleName,item.ExceptionsCheckEnabled,item.ActionEnabled, item.Severity
      ]
    })
    const findText = this.props.accounts.data?.find((item) => item.value === this.props.form.account)?.text
    const splitText = findText?.split(" - ")[0]
    pdf.setFontSize(10);
    pdf.text(`${splitText?.replace(/\s*\([^)]*\)/, '')} - ${this.props.form.account} Rule-level Remediation Report.`, 30, 20);
    autoTable(pdf, {
      head: [orderedHeader],
      body: orderedBody,
      theme: 'grid',
      styles: { fontSize: 8, cellWidth: "wrap" },
      columnStyles: {
        0: {
          cellWidth: 50
        },
        1: {
          cellWidth: 150
        },
        2: {
          cellWidth: 50
        },
        3: {
          cellWidth: 50
        }
      },
      didDrawPage: (data) => {
        var pageStr = `Page ${data.pageNumber}. `
        var dateTimeStr = `Report generated on ${now.toLocaleString('en-US', options)}`
        var pageSize = pdf.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        pdf.setFontSize(9)
        pdf.text(dateTimeStr, 30, pageHeight - 20);
        pdf.text(pageStr, pageSize.width - 50, pageHeight - 20);
      }
    })
    pdf.save(`${fileName}.pdf`);
  }
  checkboxHandler = (e, data, item) => {
    let checkedData = this.state.storeCheckAccountsData
    if (data.checked) {
      let isDataExists = checkedData.some(value => value.index === item.index)
      if (!isDataExists) {
        checkedData.push(item)
        this.setState({ ...this.state, storeCheckAccountsData: checkedData })
      }
    }
    else {
      this.setState({
        ...this.state,
        storeCheckAccountsData: checkedData?.filter((value) => value.index !== item.index)
      })
    }
  }
  onClickPieChart = (e) => {
    let status = actionEnabledOptions.find((item) => item === e.data.name)
    this.setState({
      ...this.state, toggleTableView: true, form: {
        ...this.state.form, severity: "",
        actionEnabled: status === "False" ? [status] : ["True", "Email", "Detach"], exceptionsCheckEnabled: ''
      }
    }, () => {
      this.onSearchHandler()
      const element = document.getElementById("result");
      element.scrollIntoView({ behavior: 'smooth' });
    })
  }
  onClickDonutChart = (severityName, e) => {
    let severity = severityKey.find((item) => item === severityName)
    this.setState({
      ...this.state, toggleTableView: true, form: {
        ...this.state.form, severity: severity,
        actionEnabled: e.data.name === "False" ? ["False"] : ["True", "Email", "Detach"], exceptionsCheckEnabled: ''
      }
    }, () => {
      this.onSearchHandler()
      const element = document.getElementById("result");
      element.scrollIntoView({ behavior: 'smooth' });
    })
  }
  handleSortTable = (value, index) => {
    this.setState({
      ...this.state,
      sortTable: !this.state.sortTable,
      sortTableHeaderIndex: index,
      currentPage: 1,
      storeAccountsData: this.state.storeAccountsData.sort((a, b) => {
        if (this.state.sortTable) {
          if (a[value] < b[value]) return -1;
          if (a[value] > b[value]) return 1;
        }
        else {
          if (a[value] > b[value]) return -1;
          if (a[value] < b[value]) return 1;
        }
        return 0;
      })
    })

  }
  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }

  componentDidMount() {
    const { searchedResult, rules } = this.props
    let pieData = []
    let severityData = []
    const resultData = searchedResult.data?.body?.data.filter((item) => {
      return rules.options.some((res) => item.RuleId === res.value)
    })
    const rulesWithActionAvailableTrue = rules.data.filter((item) => item.ActionAvailable === true)
    const accountContainsRulesWithSeverity = resultData.map((item) => {
      return {
        ...item,
        Severity: rulesWithActionAvailableTrue?.find((data) => data.RuleId === item.RuleId)?.Severity
      }
    })

    severityKey.map((severity) => {
      let result = accountContainsRulesWithSeverity?.filter((item) => item.Severity.toLowerCase() === severity.toLowerCase())
      let actionEnabledSet = new Set()
      if (result.length !== 0) {
        result.map((item) => {
          actionEnabledSet.add(item.ActionEnabled)
        })

        let typeActionEnabledData = []

        actionEnabledSet.forEach((value) => {
          typeActionEnabledData.push(value)
        })

        let keyValueStore = []
        let valueFalse = result.filter((item) => item.ActionEnabled === "False")
        let valueTrue = result.filter((item) => item.ActionEnabled !== "False")
        if (valueFalse.length !== 0) {
          keyValueStore.push({ value: valueFalse.length, name: "False" })
        }
        if (valueTrue.length !== 0) {
          keyValueStore.push({ value: valueTrue.length, name: "True" })
        }

        severityData.push({
          severity: severity,
          counts: result.length,
          percentage: ((result.length / accountContainsRulesWithSeverity.length) * 100).toFixed(2),
          data: result,
          keyValue: [...keyValueStore, {
            value: keyValueStore.reduce((acc, obj) => {
              return acc + obj.value;
            }, 0),
            itemStyle: {
              color: 'none',
              decal: {
                symbol: 'none'
              }
            },
            label: {
              show: false
            }
          }]
        })
      }
    })

    let resultFalse = accountContainsRulesWithSeverity && accountContainsRulesWithSeverity.filter((dataItem) => dataItem.ActionEnabled === "False")
    let resultTrue = accountContainsRulesWithSeverity && accountContainsRulesWithSeverity.filter((dataItem) => dataItem.ActionEnabled !== "False")
    if (resultFalse?.length !== 0) {
      pieData.push({
        name: "False",
        value: resultFalse.length
      })
    }

    if (resultTrue.length !== 0) {
      pieData.push({
        name: "True",
        value: resultTrue.length
      })
    }

    this.setState({
      ...this.state, storeAccountsData: accountContainsRulesWithSeverity, severityData: severityData, pieChartData: {
        ...this.state.pieChartData,
        series: [
          {
            ...this.state.pieChartData.series[0],
            data: pieData,
          }
        ]
      }
    },()=>{
      const element = document.getElementById("account-search");
      element.scrollIntoView({ behavior: 'smooth' });
    })
  }
  render() {
    const { searchedResult } = this.props
    const { currentPage, dataPerPage, toggleTableView, form, storeAccountsData, storeCheckAccountsData,
      downloadMarkedAllData, downloadMarkedDataRecord, activeIndex } = this.state
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const addIndexInStoreAccountsData = storeAccountsData?.filter(item => {
      return JSON.stringify(item?.RuleId)?.toLowerCase().includes(form.search.trim().toLowerCase()) ||
        JSON.stringify(item?.ActionEnabled)?.toLowerCase().includes(form.search.trim().toLowerCase()) ||
        JSON.stringify(item?.Severity)?.toLowerCase().includes(form.search.trim().toLowerCase()) ||
        JSON.stringify(item?.RuleName)?.toLowerCase().includes(form.search.trim().toLowerCase())
    })?.map((item, index) => {
      return {
        ...item,
        index: index
      }
    })
    const currentData = searchedResult.data && addIndexInStoreAccountsData?.slice(indexOfFirstData, indexOfLastData);
    var cardColor = ["#0000ff", "#00ff00", "#ffff00", "#ff0000", "#ffae00"]
    const RenderTableView = () => (
      <React.Fragment>
        <div style={{ width: "100%", overflow: 'scroll', maxHeight: "65vh", marginTop: "10px" }}>
          <Table celled striped sortable id="pdf-view">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Popup
                    content={true ? "Unmarked all" : "Marked all for download!"}
                    trigger={
                      <Checkbox checked={downloadMarkedDataRecord.includes(currentPage)} onChange={(e, data) => {
                        if (data.checked) {
                          let filterData = currentData.filter((item) => !storeCheckAccountsData.some((value) => value.index === item.index))
                          this.setState({
                            ...this.state, storeCheckAccountsData: storeCheckAccountsData.concat(filterData),
                            downloadMarkedAllData: !downloadMarkedAllData, downloadMarkedDataRecord: [...downloadMarkedDataRecord, currentPage]
                          })
                        }
                        else {
                          this.setState({
                            ...this.state, storeCheckAccountsData: storeCheckAccountsData.filter((item) => {
                              return !currentData.some((res) => res.index === item.index)
                            }), downloadMarkedAllData: !downloadMarkedAllData, downloadMarkedDataRecord: downloadMarkedDataRecord.filter((item) => item !== currentPage)
                          })
                        }
                      }} />
                    } />
                </Table.HeaderCell>
                {tableHeader && tableHeader.map((item, index) => (
                  <Table.HeaderCell
                    key={index}
                    sorted={this.state.sortTable && index === this.state.sortTableHeaderIndex ? 'ascending' : 'descending'}
                    onClick={() => this.handleSortTable(item.value, index)}
                  >
                    {item.text}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                currentData && currentData.length > 0 ? currentData.map((item, index) => (
                  <Table.Row key={index} >
                    <Table.Cell>
                      <Checkbox value={item.RuleId} checked={this.state.storeCheckAccountsData?.some((data) => data.index === item.index)} onChange={(e, data) => {
                        this.checkboxHandler(e, data, item)
                      }} />
                    </Table.Cell>
                    <Table.Cell style={{ minWidth: "70px" }}>
                      {item.RuleId}
                    </Table.Cell>
                    <Table.Cell style={{ minWidth: "70px" }}>
                      {item.RuleName}
                    </Table.Cell>
                    <Table.Cell style={{ minWidth: "70px" }}>
                      {item?.ExceptionsCheckEnabled !== undefined ? (item?.ExceptionsCheckEnabled ? "true" : "false") : ""}
                    </Table.Cell>
                    <Table.Cell style={{ minWidth: "200px", maxWidth: "250px", wordBreak: "break-all" }}>
                      {item.ActionEnabled}
                    </Table.Cell>
                    <Table.Cell>
                      {item?.Severity}
                    </Table.Cell>
                  </Table.Row>
                )) : (
                  <Table.Row>
                    <Table.Cell colSpan={`colSpan=${searchedResult.length}`} >
                      No records found!
                    </Table.Cell>
                  </Table.Row>
                )
              }
            </Table.Body>
          </Table>
        </div>
        <div style={{ marginTop: "10px", width: "100%", display: "flex", justifyContent: "space-between" }}>
          <Pagination
            activePage={currentPage}
            totalPages={Math.ceil(addIndexInStoreAccountsData.length / dataPerPage)}
            onPageChange={this.handlePageChange}
            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
          />
          <h3>Results:{`(${addIndexInStoreAccountsData.length})`}</h3>
        </div>
      </React.Fragment>
    )
    return (
      <>
        <Grid id="result">
          <Grid.Row>
            <Grid.Column width={3} style={{ display: toggleTableView ? "block" : "none" }}>
              <FilterBox
                form={form}
                onDropdownChangeHandler={this.onDropdownChangeHandler}
              />
            </Grid.Column>
            <Grid.Column width={toggleTableView ? 13 : 16}>
              <div style={{ display: 'flex', width: "100%", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <Button style={{ marginTop: "20px" }} basic icon={toggleTableView ? "arrow left" : "filter"} onClick={() => {
                    this.setState({ ...this.state, toggleTableView: !this.state.toggleTableView })
                  }} />
                  <Input
                    placeholder="Search..."
                    icon="search"
                    style={{ width: "220px", marginTop: "20px" }}
                    value={form.search}
                    onChange={(e, data) => {
                      if (storeCheckAccountsData.length > 0) {
                        this.setState({ ...this.state, currentPage: 1, form: { ...form, search: data.value }, storeCheckAccountsData: [], downloadMarkedAllData: false, downloadMarkedDataRecord: [] })
                      } else {
                        this.setState({ ...this.state, currentPage: 1, form: { ...form, search: data.value } })
                      }
                    }}
                  />
                </div>
                <div style={{ display: 'flex', width: "100%", justifyContent: "flex-end" }}>
                  <div >
                    <Dropdown
                      placeholder='Record per page'
                      selection
                      options={dataPerPageOptions}
                      value={this.state.dataPerPage}
                      onChange={(e, data) => {
                        this.setState({ ...this.state, dataPerPage: data.value, currentPage: 1 })
                      }}
                      style={{ height: "35px", marginTop: "20px", minWidth: 0 }}
                    />
                  </div>
                  <div style={{ marginLeft: "5px", marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Popup
                      content="Download results to CSV"
                      trigger={<Button disabled={currentData.length === 0} basic style={{ padding: "1px", height: "36px", width: "40px" }}
                        onClick={() => {
                          let downloadData = [];
                          if (storeCheckAccountsData.length > 0 && form.search === "") {
                            downloadData = storeCheckAccountsData?.map((item) => {
                              const {
                                index, LastModifiedBy, CreateTimeStamp, UpdateTimeStamp
                                , ...rest } = item
                              return rest
                            })
                          }
                          else {
                            downloadData = addIndexInStoreAccountsData?.map((item) => {
                              const {
                                index, LastModifiedBy, CreateTimeStamp, UpdateTimeStamp
                                , ...rest } = item
                              return rest
                            })
                          }
                          this.handleDownloadCsv(downloadData, `Account_${this.props.form.account}`)
                        }}
                      >
                        <Image src={csvIcon} style={{ marginLeft: "13px", height: "13.5px" }} />
                      </Button>}
                    />
                    <Popup
                      content="Download results to PDF"
                      trigger={<Button disabled={currentData.length === 0} basic icon="file pdf" onClick={() => {
                        let downloadData = [];
                        let headerKey = tableHeader
                        let fileName = `Account_${this.props.form.account}`
                        if (storeCheckAccountsData.length > 0 && form.search === "") {
                          downloadData = storeCheckAccountsData?.map((item) => {
                            const {
                              index, LastModifiedBy, CreateTimeStamp, UpdateTimeStamp
                              , ...rest } = item
                            return rest
                          })
                        }
                        else {
                          downloadData = addIndexInStoreAccountsData?.map((item) => {
                            const {
                              index, LastModifiedBy, CreateTimeStamp, UpdateTimeStamp
                              , ...rest } = item
                            return rest
                          })
                        }
                        this.handleDownloadPdf(downloadData, headerKey, fileName)
                      }} />}
                    />
                    <Popup
                      content="Visualization Insights"
                      trigger={<Button basic icon='pie chart' onClick={() => {
                        this.setState({activeIndex:0},()=>{
                          const element = document.getElementById("visualization");
                          element.scrollIntoView({ behavior: 'smooth' });
                        })
                      }} />}
                    />
                  </div>
                </div>
              </div>
              <RenderTableView />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Accordion id="visualization">
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
          >
            <Icon name='dropdown' />
            Visualization Insights
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <Grid columns={2} celled='internally' style={{ height: "100vh", border: "1px solid #d4d4d5", borderRadius: "4px" }}>
              <Grid.Row>
                <Grid.Column style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center", fontSize: "14px", marginTop: "20px" }}>
                    <h3>Auto Remediation Summary</h3>
                  </div>
                  <ReactEcharts
                    option={this.state.pieChartData}
                    style={{ width: "400px", height: "400px" }}
                    onEvents={{ 'click': this.onClickPieChart }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center", fontSize: "14px", marginTop: "20px", marginBottom: "30px" }}>
                    <h3>Severity</h3>
                  </div>
                  <Card.Group itemsPerRow={3} style={{ display: "flex", justifyContent: "center" }}>
                    {this.state.severityData && this.state.severityData?.sort(function (a, b) {
                      const sortOrder = ["Info", "Low", "Medium", "High", "Critical"];
                      return sortOrder.indexOf(a.severity) - sortOrder.indexOf(b.severity);
                    }).map((item, index) => (
                      <Card key={index} style={{ border: `1px solid ${cardColor[index]}` }}>
                        <Card.Content>
                          <Card.Header>{item.severity}</Card.Header>
                          <Card.Meta>{item.percentage}%</Card.Meta>
                          <Card.Description>
                            <p>Action Enabled:</p>
                            <ReactEcharts option={{
                              tooltip: halfDonutOption.tooltip,
                              legend: halfDonutOption.legend,
                              series: [
                                {
                                  ...halfDonutOption.series[0],
                                  data: item.keyValue
                                }
                              ]
                            }}
                              style={{ height: "120px", width: "100%" }}

                              onEvents={{
                                'click': (e) => {
                                  this.onClickDonutChart(item.severity, e)
                                }
                              }}
                            />
                            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                              <b style={{ fontSize: "30px" }}>{item.counts}</b>
                            </div>
                          </Card.Description>
                        </Card.Content>
                      </Card>
                    ))}
                  </Card.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>
        </Accordion>
      </>
    )
  }
}
