import React from 'react';
import { Header, Image, Button, Icon, Menu, Dropdown } from 'semantic-ui-react';
import MenuContainer from './MenuContainer';
import img_icon from '../../Assets/Images/verisk_w_bg.png';
import { withRouter } from 'react-router-dom';
import CircularMenu from './CircularMenu';

class HeaderComp extends React.Component {
    constructor(props) {
        super(props)
    }

    usernamefix = (name) => {
        let namecombined = '';
        var splitted_name = name.split(",")
        namecombined = splitted_name[1] + splitted_name[0]
    }
    render() {
        return (
            <React.Fragment>
                <Header dividing id='mainHeader' style={{backgroundColor:"#2A7DE1"}}>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{display:"flex"}}>
                            <Image
                                
                                floated='left'
                                src={img_icon}
                                style={{
                                    margin: "15px 0 0 10px",
                                    cursor:"pointer",
                                    height:"35px"
                                }}
                                onClick={()=>this.props.history.push("/")}
                            />
                            <Menu secondary>
                                <Menu.Item
                                    name='home'
                                    onClick={() => this.props.history.push("/")}
                                    style={{
                                        marginLeft: "10px",
                                        color: "#fff"
                                    }}
                                >
                                    Home
                                </Menu.Item>
                            </Menu>
                            <MenuContainer {...this.props} />
                        </div>
                        <div>
                            {this.props.username?.name !=="" ? (<CircularMenu username={this.props.username?.firstName}>
                                <div style={{minWidth:"200px",minHeight:"220px"}}>
                                    <div style={{display:"flex",width:"100%",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                                        <Icon name='user' circular size="large"/>
                                        <p>{this.props.username?.login?.split("@")[0]}</p>
                                        <p style={{lineHeight:"1px"}}>{`${this.props.username?.firstName} ${this.props.username?.lastName}`}</p> 
                                        <p>{`${this.props.username?.primaryemail}`}</p>
                                    </div>
                                    <div style={{display:"flex",width:"100%",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                                      <Button icon labelPosition='left' onClick={this.props.logout}>
                                        <Icon name='log out' />
                                        Logout
                                        </Button>
                                    </div>
                                </div>
                               </CircularMenu>):null}
                        </div>
                    </div>
                </Header>
            </React.Fragment>
        )
    }
}

export default withRouter(HeaderComp);