import React, { Component } from "react";
import ConfigRuleFormComp from "./ConfigRuleFormComp";
import axios from "axios";
import * as yup from "yup";
import config from '../../../../Config/Config';
import { findAllByTestId } from "@testing-library/react";
import {commercialCloud,usGovCloud} from '../../../../Config/appConstant'
class ConfigRuleFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleId: "",
      ruleName: "",
      severity: "",
      actionAvailable: "",
      exceptionType: "Not_Applicable",
      ccsReporting: "",
      applicableResources: [],
      category: "",
      exceptionFormat: "",
      triggerType: [],
      globalExceptionType:'Not_Applicable',
      globalExceptionFormat:[],
      periodicFrequency: "",
      ruleReleaseDate: "",
      open: "",
      errorPaths: [],
      errors: [],
      dimmerActive:false,
      toastMessage:'',
      onToastMsg:false,
      toastColour:'green',
      cloudCompatibility:[],
      DefaultActionEnabled:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.clearClick = this.clearClick.bind(this);
    this.readDataRuleIdFromApi = this.readDataRuleIdFromApi.bind(this);
    this.clearClick = this.clearClick.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleCalendarChangeToDbSchema = this.handleCalendarChangeToDbSchema.bind(this);
    this.onChangeActionEnable = this.onChangeActionEnable.bind(this)
  }

  schema = yup.object().shape({
    ruleName: yup
      .string()
      .required("Rule Name is a required field")
      .min(4, "Rule Name must be greater then 4 characters")
      .matches(/^[a-zA-Z0-9,-]*$/, "Rule Name should not contain spaces")
      .matches(
        /(-[^-]*){2}/,
        "Rule Name should contain at least two hyphen '-' "
      )
      .test(
        "VA-",
        config['runenvironment']==='DEV' ? "Rule Name should start with DEV- followed by lower case." : config['runenvironment']==='QA'
        ? "Rule Name should start with QA- followed by lower case." : "Rule Name should start with VA- followed by lower case.",
        (val) =>
          val &&
          ((val.startsWith("DEV-") && config['runenvironment']==='DEV')|| (val.startsWith("QA-") && config['runenvironment']==='QA') || (val.startsWith("VA-") && config['runenvironment']==='PROD'))&&
          val.substring(3) === val.substring(3).toLowerCase()
      ),
    ruleReleaseDate: yup.string().required().max(10),
  });

  handleChange = (event) => {

    this.setState({ [event.target.name]: event.target.value }, () =>
      this.schema
        .validate(this.state, { abortEarly: false })
        .then((valid) => this.setState({ errorPaths: [], errors: [] })) //called if the entire form is valid
        .catch((err) =>
          this.setState({
            errors: err.errors,
            errorPaths: err.inner.map((i) => i.path),
          })
        )
    ); //called if any field is invalid
  }

  handleCalendarChangeToDbSchema()
  {
    var dateMonthYear= (this.state.ruleReleaseDate).split('-')
    var convertedDate= dateMonthYear[1]+'/'+dateMonthYear[2]+'/'+dateMonthYear[0];
    return convertedDate;
  };


  handleDropDownChange = (event, data) => {
    this.setState({ [data.name]: data.value });
    if(data.name === 'exceptionType' && data.value==='Not_Applicable'){
      this.setState({'exceptionFormat':''})
    }
    else if(data.name === 'globalExceptionType' && data.value==='Not_Applicable'){
      this.setState({'globalExceptionFormat':[]})
    }
    else if(data.name?.toLowerCase() === "actionavailable"){
      if(data.value === true){
          this.setState((prev)=>({...prev,DefaultActionEnabled:true}))
      }
      else{
        this.setState((prev)=>({...prev,DefaultActionEnabled:false}))
      }
    }
  };

  show = () => {
    this.setState({ open: true });
  };

  handleConfirm = () => {
    this.setState({ open: false, dimmerActive:true});
    var confirmDate = this.handleCalendarChangeToDbSchema();
    const data = {
      ActionAvailable: this.state.actionAvailable,
      Category: this.state.category,
      CcsReporting: this.state.ccsReporting,
      ExceptionType: this.state.exceptionType,
      ExceptionFormat: this.state.exceptionFormat,
      RuleId: parseInt(this.state.ruleId),
      RuleReleaseDate: confirmDate,
      RuleName: this.state.ruleName,
      Severity: this.state.severity,
      TriggerType: this.state.triggerType,
      ApplicableResources: this.state.applicableResources,
      PeriodicFrequency: this.state.periodicFrequency,
      LastModifiedBy: this.props.authState.accessToken.claims.sub,
      GlobalExceptionFormat:this.state.globalExceptionFormat,
      GlobalExceptionType:this.state.globalExceptionType,
      CloudCompatibility:{
        "aws":this.state.cloudCompatibility.includes(commercialCloud),
        "aws-us-gov": this.state.cloudCompatibility.includes(usGovCloud)
      },
      DefaultActionEnabled:this.state.DefaultActionEnabled
    };
    const options = {
      headers: {
        Authorization: this.props.authState.accessToken.accessToken,
      },
    };
    try{
      let createConfigRule = Promise.all([
        axios.post(`${config.apiendpoint}configrules`,data,options),
        axios.post(`${config.govCloudApi}configrules`,data,options)
      ])
      createConfigRule.then(([commercialCloudResponse,govCloudResponse])=>{
        if(commercialCloudResponse?.data.status_code === 201 && govCloudResponse?.data.status_code === 201 ){
          this.setState((prevState)=>({
            ...prevState,
            onToastMsg:true,
            toastMessage:commercialCloudResponse?.data.body.message,
            toastColour:'green',
            dimmerActive:false
          }))
            this.readDataRuleIdFromApi();
        }
        else{
          this.setState((prevState)=>({
            ...prevState,
            onToastMsg:true,
            toastMessage:govCloudResponse.data.body.message,
            toastColour:'red',
            dimmerActive:false
          }))
        }
      }).catch(err=>{
        console.error(err)
        this.setState((prevState)=>({...prevState,
          onToastMsg:true,
          toastMessage:err?.message,
          toastColour:'red',
          dimmerActive:false }));
      })
    }
    catch(error){
      console.error(error)
      this.setState((prevState)=>({...prevState,
        onToastMsg:true,
        toastMessage:error?.message,
        toastColour:'red',
        dimmerActive:false 
      }));
    }
  };

  readDataRuleIdFromApi = () => {
    const options = {
      headers: {
        Authorization: this.props.authState.accessToken.accessToken,
      },
    };
    axios
      .get(
        config['apiendpoint']+'configrules/RuleId',
        options
      )
      .then((res) => {
        var ruleid_dict = res.data.body.data[0].RuleId;
        this.setState({ ["ruleId"]: ruleid_dict });
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  clearClick = () => {

    this.setState({
      ruleName: "",
      severity: "",
      ccsReporting: "",
      applicableResources: [],
      category: "",
      triggerType: [],
      periodicFrequency: "",
      actionAvailable: "",
      exceptionType: "",
      exceptionFormat: "",
      globalExceptionFormat: "",
      globalExceptionType: "",
      cloudCompatibility:[]
    });
    this.handleDate();
  };

  dismissToastMessage=()=>{
    this.clearClick();
    this.setState({onToastMsg:false})
  }

  handleSubmit(event) {
    this.show();
  }

  handleDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    this.setState({ ["ruleReleaseDate"]: today });
    return today;
  };

  onChangeActionEnable = (event, data)=>{
    this.setState((...prevState)=>({...prevState,DefaultActionEnabled:data.value}))
  }

  render() {
    return (
      <ConfigRuleFormComp
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        handleDropDownChange={this.handleDropDownChange}
        {...this.state}
        handleDate={this.handleDate}
        clearClick={this.clearClick}
        handleConfirm={this.handleConfirm}
        handleCancel={this.handleCancel}
        readDataRuleIdFromApi={this.readDataRuleIdFromApi}
        dismissToastMessage={this.dismissToastMessage}
        onChangeActionEnable={this.onChangeActionEnable}
      />
    );
  }
}

export default ConfigRuleFormContainer;
