import axios from 'axios'
import React, { Component } from 'react'
import { Header, Segment, Select } from 'semantic-ui-react'
import { Button, Form, Dimmer, Loader } from 'semantic-ui-react'
import config from '../../../../Config/Config'
import SearchResult from './SearchedResult'
import { cloudCompatibilityOption, commercialCloud, localStorageCloudValue } from '../../../../Config/appConstant'
export default class ExceptionReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rules: { data: [], options: [], isLoading: false },
            accounts: { data: [], isLoading: false },
            configRuleOptionLists: [],
            regionOptionLists: [],
            form: {
                account: ''
            },
            searchedResult: {
                data: [],
                length: -1,
                isLoading: false,
                displayView: false
            },
            exceptionStatusType: [
                {
                    key: 1,
                    text: 'Exception Deleted',
                    value: 'EXCEPTION_DELETED'
                },
                {
                    key: 2,
                    text: 'New',
                    value: 'NEW'
                },
                {
                    key: 3,
                    text: 'Recertified',
                    value: 'RECERTIFIED'
                },
                {
                    key: 4,
                    text: "Rule Decommissioned",
                    value: "RULE_DECOMMISSIONED"
                },
                {
                    key: 5,
                    text: 'Active',
                    value: 'ACTIVE'
                },
                {
                    key: 6,
                    text: 'Resource Deleted',
                    value: 'RESOURCE_DELETED'
                },
                {
                    key: 7,
                    text: 'Account Decommissioned',
                    value: 'ACCOUNT_DECOMMISSIONED'
                },
                {
                    key: 8,
                    text: 'Expired',
                    value: 'EXPIRED'
                }
            ],
            selectCloud: "aws"
        }
        this.getAccountLists = this.getAccountLists.bind(this)
        this.advanceSearch = this.advanceSearch.bind(this)
        this.onChangeHandler = this.onChangeHandler.bind(this)
        this.getConfigRuleLists = this.getConfigRuleLists.bind(this)
        this.handleSelectCloudSpace = this.handleSelectCloudSpace.bind(this)
    }
    getAccountLists = () => {
        this.setState({ accounts: { ...this.state.accounts, isLoading: true } })
        const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
        this.props.authState.accessToken.accessToken && axios.get(`${selectedUrl}account/account-list`, {
            headers: {
                Authorization: this.props.authState.accessToken.accessToken
            }
        }).then((res) => {
            const result = res.data.body.data;
            this.setState({ ...this.state, accounts: { ...this.state.accounts, data: result, isLoading: false } })
        }).catch(err => {
            console.error(err)
            this.setState({ ...this.state, accounts: { ...this.state.accounts, isLoading: false } })
        })
    }

    onChangeHandler = (e, data) => {
        this.setState({ ...this.state, form: { ...this.state.form, [data.name]: data.value }, searchedResult: { ...this.state.searchedResult, data: [], displayView: false, length: -1 } })
    }

    advanceSearch = () => {
        const { form, rules } = this.state
        let configRuleStore = new Set()
        let regionStore = new Set()
        let configRuleOptions = []
        let regionOptions = []
        this.setState({
            ...this.state,
            searchedResult: {
                ...this.state.searchedResult,
                length: -1,
                isLoading: true,
                displayView: false
            }
        })
        let query = [
            {
                key: "AccountId",
                value: form.account
            },
            {
                key: "Table",
                value: "RESOURCE_TABLE"
            }
        ]

        let search = ""

        query.map((item) => {
            if (search !== "") {
                if (item.value) {
                    search += `&${item.key}=${item.value}`
                }
            }
            else {
                if (item.value) {
                    search += `${item.key}=${item.value}`
                }
            }
        })
        const rulesWithActionAvailableTrue = rules?.data.filter((item) => item.ActionAvailable === true)
        const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
        this.props.authState &&
            axios.get(`${selectedUrl}advance-search?${search}`, {
                headers: {
                    Authorization: this.props.authState.accessToken.accessToken
                }
            }).then(res => {
                let configRuleData = res.data?.body?.data?.map((item) => JSON.stringify({ RuleId: item.RuleId, RuleName: item.RuleName }))
                let regionData = res.data?.body?.data?.map((item) => item.ExceptionRegion)

                configRuleData && configRuleData?.map((item) => {
                    configRuleStore.add(item)
                })
                configRuleStore && configRuleStore?.forEach((item) => {
                    let data = JSON.parse(item)
                    configRuleOptions.push({
                        key: data?.RuleId,
                        text: `${data?.RuleId}-${data?.RuleName}`,
                        value: data.RuleId
                    })
                })

                regionData && regionData.map((item) => {
                    if (item !== undefined) {
                        regionStore.add(item)
                    }
                })
                regionStore && regionStore?.forEach((item) => {
                    regionOptions.push({
                        key: item,
                        text: item,
                        value: item
                    })
                })
                let exceptionWithSeverity = res.data?.body?.data?.map((item) => {
                    return {
                        ...item,
                        Severity: rulesWithActionAvailableTrue?.find((data) => data.RuleId === item.RuleId)?.Severity
                    }
                })
                this.setState({
                    ...this.state,
                    searchedResult: {
                        ...this.state.searchedResult,
                        data: { body: { data: exceptionWithSeverity } },
                        length: Object.keys(res.data?.body?.data[0]).length || 0,
                        isLoading: false,
                        displayView: true
                    },
                    configRuleOptionLists: configRuleOptions?.sort((a, b) => a.key - b.key),
                    regionOptionLists: regionOptions
                })
            }).catch(err => {
                console.error(err)
                this.setState({
                    ...this.state,
                    searchedResult: {
                        ...this.state.searchedResult,
                        isLoading: false,
                        displayView: false
                    }
                })
            })
    }
    getConfigRuleLists = () => {
        const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
        this.props.authState.accessToken && axios.get(`${selectedUrl}configrules`, {
            headers: {
                Authorization: this.props.authState.accessToken.accessToken
            }
        }).then((res) => {
            const result = res?.data?.body?.data;
            const optionList = result.filter((item) => item.ActionAvailable !== false).map((item) => {
                return {
                    key: item.RuleId,
                    text: `${item.RuleId} ${item.RuleName}`,
                    value: item.RuleId
                }
            })

            this.setState({ ...this.state, rules: { ...this.state.rules, data: result, options: optionList, isLoading: false } })
        }).catch(err => {
            console.error(err)
            this.setState({ ...this.state, rules: { ...this.state.rules, isLoading: false } })
        })
    }
    handleSelectCloudSpace = (event, data) => {
        localStorage.setItem(localStorageCloudValue, data?.value)
        this.setState((prevState) => ({
            ...prevState, [data.name]: data.value,
            searchedResult: { ...prevState.searchedResult, data: [], displayView: false, length: -1 }
        }), () => {
            this.getAccountLists()
            this.getConfigRuleLists()
        });
    };
    componentDidMount() {
     
        let value = localStorage.getItem(localStorageCloudValue)
        if (value !== null && value !== "") {
            this.handleSelectCloudSpace({}, { name: "selectCloud", value: value })
        }
        else{
            this.getAccountLists()
            this.getConfigRuleLists()
        }
    }

    render() {
        const { form, searchedResult } = this.state
        return (
            <Segment
                size="mini"
                style={{
                    padding: "30px",
                    borderColor: "blue",
                    borderStyle: "Solid",
                    margin: "6px",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
                    <Header as="h3" color="blue">Resource exception report for account.</Header>
                    <div>
                        <b style={{ fontSize: 13, marginBottom: 2 }}>Selected organization:</b>
                        <Select
                            fluid
                            label='Select Cloud'
                            options={cloudCompatibilityOption}
                            value={this.state.selectCloud}
                            name="selectCloud"
                            onChange={this.handleSelectCloudSpace}
                            placeholder='Select Cloud'
                            style={{ position: "centre", width: "150px" }}
                        />
                    </div>
                </div>
                <Form onSubmit={this.advanceSearch} id="account-search" >
                    <Dimmer active={this.state.searchedResult.isLoading} inverted ><Loader /></Dimmer>
                    <Form.Group >
                        <Form.Field
                            control={Select}
                            options={this.state.accounts.data}
                            label={{ children: 'Accounts', htmlFor: 'form-select-account' }}
                            placeholder='Select or search account'
                            clearable
                            search
                            searchInput={{ id: 'form-select-account' }}
                            style={{ width: "21em" }}
                            name="account"
                            onChange={(e, data) => this.onChangeHandler(e, data)}
                            loading={this.state.accounts.isLoading}
                        />
                        <Button type='submit' style={{ height: "40px", marginTop: "23px" }} disabled={form.account === "" ? true : false}>Search</Button>
                    </Form.Group>

                </Form>
                {(form.account !== "" && searchedResult.displayView && searchedResult.length > 0)
                    ? (<SearchResult {...this.state} />)
                    : searchedResult.length === 0 ? 'No exception found!' : ('')}
            </Segment>
        )
    }
}
