import React, { useEffect, useState } from 'react'
import { Button, Select, Input, Grid, Message, Dimmer, Loader, Modal, Table, Header, Rating, Label, Icon, Radio, Form, Popup } from 'semantic-ui-react'
import { getMetadataLists, getAccountLists, updateTrustedPartner } from '../service'

export default function UpdateTrustedPartner(props) {
  const [metadata, setMetadata] = useState({
    isLoading: false, error: null, data: null, sortKey: 0, listOfTrustedPartner: []
  })
  const [buAccounts, setBuAccounts] = useState({ isLoading: false, error: null, data: null })

  const [searchedResult, setSearchedResult] = useState({
    data: [], isLoading: false, message: "",
    openMessage: false, error: ""
  })

  const [openModal, setOpenModal] = useState(false)
  const [selectedModalData, setSelectedModalData] = useState({ modalTitle: "", data: null, index: -1 })
  const [updateOperation, setUpdateOperation] = useState("ATTACH")
  const [seletedAccount, setSelectedAccount] = useState([])
  const [partnerNameFromOnChange, setPartnerNameFromOnChange] = useState("")

  const fetchAccountLists = (token) => {
    setBuAccounts({ isLoading: true, data: null, error: null })
    getAccountLists(token, props?.selectCloud).then((item) => {
      if (selectedModalData.data !== null && selectedModalData.modalTitle !== '') {
        setBuAccounts({ isLoading: false, data: item.data?.body?.data?.filter((item) => !selectedModalData.data?.Associations?.includes(item?.value)), error: null })
      }
      else {
        setBuAccounts({ isLoading: false, data: item.data?.body?.data, error: null })
      }
    }).catch(error => {
      setBuAccounts({ isLoading: false, data: null, error: error.message })
      console.error(error)
    })
  }

  const fetchMetadataLists = (token) => {
    setMetadata((preData) => ({ ...preData, isLoading: true }))
    getMetadataLists(token, props?.metaKey, props?.selectCloud).then((response) => {
      if (response?.data?.data.length > 0) {
        let listOfTrustedPartner = []
        let trustedPartnerUniqueName = new Set()
        let sortKeys = []
        response?.data?.data?.map((item, index) => {
          trustedPartnerUniqueName.add(item?.GroupKey)
          sortKeys.push(parseInt(item.SortKey))
        })

        trustedPartnerUniqueName.forEach((item) => {
          listOfTrustedPartner.push({
            key: item,
            text: item,
            value: item
          })
        })
        sortKeys.sort((a, b) => b - a)
        setMetadata((preData) => ({
          ...preData, isLoading: false,
          data: response?.data?.data,
          listOfTrustedPartner: listOfTrustedPartner,
          sortKey: sortKeys[0] + 1
        }))
      }

    }).catch(error => {
      setMetadata((preData) => ({
        ...preData,
        isLoading: false,
        data: null,
        error: error.message,
        listOfTrustedPartner: [],
        sortKey: 0
      }))
      console.log(error)
    })
  }
  useEffect(() => {
    let token = props.authState.accessToken.accessToken
    if (token) {
      fetchMetadataLists(token)
      fetchAccountLists(token)
    }
  }, [props?.selectCloud, props?.ruleId])

  const getDate = (value) => {
    const date = new Date(value)
    return `${date.toUTCString()}`
  }
  const handleOpenModal = (data, index) => {
    let token = props.authState.accessToken.accessToken
    setUpdateOperation("ATTACH")
    fetchAccountLists(token)
    setOpenModal(true)
    setSelectedModalData((prevData) => ({ ...prevData, modalTitle: `${data?.GroupKey}-${data?.MetadataValue}`, data: data, index: index }))
    setSelectedAccount([])
    setSearchedResult({ data: [], isLoading: false, error: "", message: "", openMessage: false })
  }
  const handleCloseModal = () => {
    setOpenModal(false)
    setSelectedAccount([])
    setSelectedModalData({ modalTitle: "", data: null })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setSearchedResult({ data: [], isLoading: true, error: "", message: "", openMessage: false })
    let payload = {
      LastModifiedBy: props.authState.accessToken.claims.sub,
      GroupKey: selectedModalData.data?.GroupKey,
      MetadataValue: selectedModalData.data?.MetadataValue,
      Associations: seletedAccount,
      OperationType: updateOperation === "ATTACH" ? "ATTACH" : "DETACH",
      SortKey: selectedModalData.data?.SortKey,
      RuleId: props?.ruleId,
      MetadataKey:props?.metaKey
    }

    let token = props.authState.accessToken.accessToken
    updateTrustedPartner(token, payload, props?.selectCloud).then((response) => {
      if (response.status === 204) {
        setSearchedResult({ data: [], isLoading: false, error: "", message: updateOperation === "ATTACH" ? `Account attach successfully!` : 'Account detach successfully!', openMessage: true })
        fetchMetadataLists(token)
        setSelectedAccount([])
        handleCloseModal()
        setTimeout(() => { handleRemoveMessageBox() }, 5000)
      }
      else {
        setSearchedResult({ data: [], isLoading: false, error: "Failed to update record`!", message: "", openMessage: true })
        handleCloseModal()
        setTimeout(() => { handleRemoveMessageBox() }, 5000)
      }
    }).catch(error => {
      console.error(error)
      setSearchedResult({ data: [], isLoading: false, error: error.message, message: "", openMessage: true })
      handleCloseModal()
    })
  }
  const handleRemoveMessageBox = () => {
    setSearchedResult((prevState) => ({ ...prevState, openMessage: false, error: "", message: "" }))
  }
  return (
    <React.Fragment>

      <div style={{ width: "100%", top: 0, position: "absolute", left: 0 }}>
        <div style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }} >
          {searchedResult.openMessage ? <Message size="large" style={{ width: "40%" }} onDismiss={handleRemoveMessageBox}
            content={searchedResult.message === "" ? searchedResult.error : searchedResult.message}
            color={searchedResult.message === "" ? "red" : "green"} /> : null}
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        size='small'
      >
        <Modal.Header>{selectedModalData?.modalTitle}</Modal.Header>
        <Modal.Content>
          <Dimmer active={searchedResult.isLoading}>
            <Loader />
          </Dimmer>
          <Form >
            <Form.Field>
              Choose an action:
              <Popup
                content={
                  <div>
                    <div style={{ fontSize: "10px" }}><b>Attach: </b><br /> Add BU accounts into trusted partner.</div>
                    <div style={{ fontSize: "10px" }}><b>Detach: </b><br /> Remove BU accounts from trusted partner.</div>
                  </div>
                }
                trigger={<Icon name='info circle' color='grey' style={{ marginLeft: "3px" }} />}
                position='right center'
              />
            </Form.Field>
            <Form.Group>
              <Form.Field>
                <Radio
                  label='Attach'
                  name='radioGroup'
                  value={updateOperation}
                  checked={updateOperation === "ATTACH"}
                  onChange={() => setUpdateOperation("ATTACH")}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label='Detach'
                  name='radioGroup'
                  value={updateOperation}
                  checked={updateOperation === 'DETACH'}
                  onChange={() => setUpdateOperation("DETACH")}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <div style={{ marginTop: "30px" }}>
            <Form onSubmit={handleSubmit}>
              <Form.Group widths='equal'>
                <Form.Field>
                  {updateOperation === "ATTACH" ? (<Form.Select
                    loading={buAccounts.isLoading}
                    label={(<div style={{ display: "flex", marginBottom: "5px" }}>
                      <label>Select Accounts</label>  <Popup
                        content={<p style={{ fontSize: "10px" }}>More than one account you can also select.</p>}
                        trigger={<Icon name='info circle' color='grey' style={{ marginLeft: "3px" }} />}
                        position='right center'
                      /></div>)}
                    options={buAccounts.data?.filter((item) => !selectedModalData?.data?.Associations?.includes(item?.value))}
                    search
                    placeholder='Select and search'
                    name='accountId'
                    floated
                    value={seletedAccount}
                    multiple
                    onChange={(e, data) => setSelectedAccount(data.value)}
                    style={{ padding: "3px 0 0 0" }}
                  />) : (
                    <Form.Select
                      label={(<div style={{ display: "flex", marginBottom: "5px" }}>
                        <label>Select Accounts</label>  <Popup
                          content={<p style={{ fontSize: "10px" }}>More than one account we can select.</p>}
                          trigger={<Icon name='info circle' color='grey' style={{ marginLeft: "3px" }} />}
                          position='right center'
                        /></div>)}
                      options={selectedModalData?.data?.Associations?.map((item, index) => {
                        let getAccountDetails = buAccounts?.data?.find((buAccountDetails) => buAccountDetails?.value === item)
                        return {
                          key: index,
                          text: `${getAccountDetails?.text}`,
                          value: item
                        }
                      })}
                      search
                      placeholder='Select and search'
                      name='accountId'
                      floated
                      value={seletedAccount}
                      multiple
                      onChange={(e, data) => setSelectedAccount(data.value)}
                      style={{ padding: "3px 0 0 0" }}
                    />
                  )}
                </Form.Field>
                <Form.Field>
                  <Form.Button content='Submit' type="submit" style={{ marginTop: "25px" }} disabled={seletedAccount?.length > 0 ? false : true} />
                </Form.Field>
              </Form.Group>
            </Form>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
      <fieldset style={{ marginTop: 40, border: "1px solid #cfcfcf" }}>
        <legend style={{ padding: "5px 10px", backgroundColor: "rgb(42, 125, 225)", color: "#fff" }}>Update Trusted Partner</legend>
        <div style={{ marginTop: "30px", padding: 10 }}>
          <Grid columns={3} stackable>
            <Grid.Row>
              <Grid.Column>
                <div><label style={{ fontWeight: 700, fontSize: "12px" }}>Select Trusted Partner<span style={{ color: '#db2828' }}>*</span></label></div>
                <Select
                  search
                  loading={metadata?.isLoading}
                  placeholder='Select'
                  options={metadata.listOfTrustedPartner}
                  style={{ minWidth: "90%" }}
                  onChange={(e, data) => {
                    setPartnerNameFromOnChange(data.value)
                  }}
                  value={partnerNameFromOnChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div style={{ marginTop: "30px" }}>
            {metadata.data?.filter((item) => item?.GroupKey?.toLowerCase() === partnerNameFromOnChange.toLowerCase())?.length > 0 ? (
              <Table celled padded>
                <Table.Header>
                  <Table.Row>
                    {["Partner Account ID", "Verisk Internal Accounts (Associations)", "Action"].map(
                      (item) => (<Table.HeaderCell textAlign="center" >{item}</Table.HeaderCell>))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {!metadata.isLoading  ? metadata.data?.filter((item) => item.GroupKey.toLowerCase() ===
                    partnerNameFromOnChange.toLowerCase())?.map((seletedItem, index) => (
                      <Table.Row key={index}>
                        <Table.Cell textAlign="center">
                          <b>{seletedItem?.MetadataValue}</b>
                        </Table.Cell>
                        <Table.Cell style={{ maxWidth: "300px" }} >
                          {seletedItem?.Associations?.length > 0 ? seletedItem?.Associations?.map((associationAccount) => {
                            let getAccountDetails = buAccounts?.data?.find((buAccountDetails) => buAccountDetails?.value === associationAccount)
                            return (
                              <Popup content={<p style={{ fontSize: "10px" }}>{associationAccount}</p>} trigger={<Label style={{ margin: "5px" }}>{getAccountDetails?.accountName}</Label>} />
                            )
                          }) : (<div style={{ textAlign: "center" }}>Not Found</div>)}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                          <Icon name="edit" onClick={() => handleOpenModal(seletedItem, index)} style={{ cursor: "pointer" }} />
                        </Table.Cell>
                      </Table.Row>
                    )) : <>Loading...</>}
                </Table.Body>
              </Table>
            ) : null}
          </div>
        </div>
      </fieldset>
    </React.Fragment>
  )
}
