import React from 'react';
import {Link} from 'react-router-dom';
import { Dropdown, DropdownDivider, Menu } from 'semantic-ui-react';
// import {selectedMenuItem} from '../../store/action';
import history from '../../history'
import { connect} from "react-redux";

class DropdownPointing extends React.Component{
  constructor(props) {
    super(props)
  }
  render(){
    const { groupName } = this.props
    if (groupName === 'SuperAdmin') {
      return (
        <Menu secondary style={{ position: 'center', margin: '14px 8px',}} compact >
          <Dropdown style={{color:"#fff"}} text='Config Rules' pointing className='link item'>
            <Dropdown.Menu>
              <Dropdown.Item value='rule-create' onClick={() => this.props.selectedMenuItem('configrulecreate')}>Add to Master</Dropdown.Item>
              <Dropdown.Item clicked='rule-update' onClick={() => this.props.selectedMenuItem('configrulesearch')}>Update to Master</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown style={{color:"#fff"}} text='Account' pointing className='link item'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.props.selectedMenuItem('configaccountadddecom')}>Bulk Updates</Dropdown.Item>
              <Dropdown.Item onClick={() => this.props.selectedMenuItem('configaccountSearch')}>Update Account Attribute</Dropdown.Item>
              <Dropdown.Item onClick={() => this.props.selectedMenuItem('/aws-okta-report')}>Access Report</Dropdown.Item>
              <Dropdown.Item onClick={() => this.props.selectedMenuItem('/trusted-vendor')}>Trusted Vendor</Dropdown.Item>
              <Dropdown.Item onClick={() => this.props.selectedMenuItem('/trusted-partner')}>Trusted Partner</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown style={{color:"#fff"}} text='Exceptions' pointing className='link item'>
            <Dropdown.Menu>
              <Dropdown text='Global' pointing={'left'} className="link item">
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.props.selectedMenuItem('globalexceptioncreate')}>Create/Update</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown text='Resource' pointing={'left'} className="link item">
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.props.selectedMenuItem('resourceexceptioncreate')}>Create Exception</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.props.selectedMenuItem('resourceexceptionsearch')}>Update Exception</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown style={{color:"#fff"}} text='Metadata' pointing className='link item'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.props.selectedMenuItem('metadatacreate')}>Create</Dropdown.Item>
              <Dropdown.Item onClick={() => this.props.selectedMenuItem('metadataupdate')}>Update</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown style={{color:"#fff"}} text='Reports' pointing className='link item'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.props.selectedMenuItem('account-report')}>Resource Exception</Dropdown.Item>
              <Dropdown text='Auto Remediation' style={{ width: "200px" }} pointing={'left'} className="link item">
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.props.selectedMenuItem('auto-remediation-account-report')}>Account</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.props.selectedMenuItem('auto-remediation-config-rule-report')}>Config Rule</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      )
    }
    else if (groupName === "CloudOps") {
      return (
        <Menu secondary style={{ position: 'center', margin: '14px 8px',}} compact >
          <Dropdown style={{color:"#fff"}} text='Account' pointing className='link item'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.props.selectedMenuItem('configaccountSearch')}>Update Account Attribute</Dropdown.Item>
              <Dropdown.Item onClick={() => this.props.selectedMenuItem('/aws-okta-report')}>Access Report</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown style={{color:"#fff"}} text='Exceptions' pointing className='link item'>
            <Dropdown.Menu>
              <Dropdown text='Resource' pointing={'left'} className="link item">
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.props.selectedMenuItem('resourceexceptioncreate')}>Create Exception</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.props.selectedMenuItem('resourceexceptionsearch')}>Update Exception</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown style={{color:"#fff"}} text='Reports' pointing className='link item'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.props.selectedMenuItem('account-report')}>Resource Exception</Dropdown.Item>
              <Dropdown text='Auto Remediation' style={{ width: "200px" }} pointing={'left'} className="link item">
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.props.selectedMenuItem('auto-remediation-account-report')}>Account</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.props.selectedMenuItem('auto-remediation-config-rule-report')}>Config Rule</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      )
    }
    else if (groupName === "ReadOnly") {
      return (
        <Menu secondary style={{ position: 'center', margin: '14px 8px',}} compact >
          <Dropdown style={{color:"#fff"}} text='Reports' pointing className='link item'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.props.selectedMenuItem('account-report')}>Resource Exception</Dropdown.Item>
              <Dropdown text='Auto Remediation' style={{ width: "200px" }} pointing={'left'} className="link item">
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.props.selectedMenuItem('auto-remediation-account-report')}>Account</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.props.selectedMenuItem('auto-remediation-config-rule-report')}>Config Rule</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      )
    }
    else {
      return (
        <Menu secondary style={{ position: 'center', margin: '14px 8px',}} compact />
      )
    }
  }
}

// const mapDispatchtoProps = (dispatch) => {
//   return {
//     selectedMenuItem: (data) => dispatch(selectedMenuItem(data))
//   }
// }

// export default connect(null, mapDispatchtoProps)(DropdownPointing);

export default DropdownPointing;