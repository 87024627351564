import React, { Component } from 'react';
import { Form, Segment, Message, Header, Icon, Popup, Select, Radio } from 'semantic-ui-react';
import ReactTooltip from "react-tooltip";
import { Dimmer, Loader } from 'semantic-ui-react';
import ToastMessage from '../../../ReUsableComponent/ToastMessageComp';
import Modal from '../../../Modal';
import history from '../../../../history';
import { cloudCompatibilityOption, commercialCloud, labelAws, labelAwsUsGov, localStorageCloudValue, usGovCloud } from '../../../../Config/appConstant';

class ConfigAccountSearchComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAccountCopied: false,
      isRuleCopied: false,
      openModal:true
    }
    this.handleCopyText = this.handleCopyText.bind(this)
    this.handleSelectText = this.handleSelectText.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleSelectCloudSpace = this.handleSelectCloudSpace.bind(this)
    this.getSelectedCloudType = this.getSelectedCloudType.bind(this)
  }
  // componentDidMount() {
  //   this.props.readDataFromApi();
  //   this.props.readDataAccountIdFromApi();
  // }
  handleCopyText = (text) => {
    if (typeof window.navigator !== 'undefined') {
      navigator.clipboard.writeText(text).catch(err => console.error(err))
    } else {
      console.error('window.navigator is not supported in this environment!');
    }
  }

  handleSelectText = () => {
    if (typeof window.getSelection !== 'undefined') {
      let text = window.getSelection().toString()
      if (text !== "") {
        this.setState({ isAccountCopied: false, isRuleCopied: false })
        this.handleCopyText(text)
      }
    } else {
      console.error('window.getSelection is not supported in this environment.');
    }
  };
  handleSelectCloudSpace = (event, data) => {
    localStorage.setItem(localStorageCloudValue,data?.value)
    this.setState((prevState)=>({...prevState, openModal: false}));
    this.props.setSelectedCloudType(data.value)
  };

  handleCloseModal = ()=>{
    this.setState((prevState)=>({...prevState,openModal: false}),()=>{
      window.location.href = "/"
    });
  }
  getSelectedCloudType= ()=>{
    return this.state.selectCloud
  }

  componentDidMount(){
    let value = localStorage.getItem(localStorageCloudValue)
    if(value !== null && value !==""){
      this.handleSelectCloudSpace({},{name:"selectCloud",value:value})
    }
  }

  render() {
    const copyIconStyle = {
      cursor: 'pointer', position: 'absolute', top: 10,
      right: 30, height: '100%', display: 'flex', alignItems: 'center', 
      justifyContent: 'center'
    }
    return (
      <Segment size='mini' style={{ padding: '20px', borderColor: 'blue', borderStyle: 'Solid', margin: '6px' }}>
        <Dimmer active={this.props.isSearching} inverted>
          <Loader size='large'>Please wait! Searching...</Loader>
        </Dimmer>
        {this.props.onToastMsg && <ToastMessage toastMessage={this.props.toastMessage} colour={this.props.toastColour} dismissToastMessage={this.props.dismissToastMessage} />}
        {/* <Segment.Group> */}
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
        <Header as='h3' color='blue'>Search Account Attributes</Header>
        <div>
          <b style={{fontSize:13,marginBottom:2}}>Selected organization:</b>
        <Select
          fluid
          label='Select Cloud'
          options={cloudCompatibilityOption}
          value={this.props.selectCloud}
          name="selectCloud"
          onChange={this.handleSelectCloudSpace}
          placeholder='Select Cloud'
          style={{position: "centre", width: "150px" }}
          />
          </div>
        </div>
        <Modal openModal={this.state.openModal} closeModal={this.handleCloseModal} title={'Choose organization:'}>
        <Form>
            <Form.Group widths='equal'>
              <Form.Field>
                <Radio
                  label={labelAws.label}
                  name="selectCloud"
                  value={labelAws.value}
                  checked={this.props.selectCloud === commercialCloud}
                  onChange={this.handleSelectCloudSpace}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={labelAwsUsGov.label}
                  name="selectCloud"
                  value={labelAwsUsGov.value}
                  checked={this.props.selectCloud === usGovCloud}
                  onChange={this.handleSelectCloudSpace}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal>
        <Form onSubmit={this.props.handleSubmit}>
          <Form.Group widths='equal'>
          <div style={{ position: 'relative' }}>
            <Form.Select
              fluid
              search
              label={'Rule Identifier'}
              required
              onChange={(e, data) => {
                this.props.handleDropDownChange(e, data)
                this.setState({ isRuleCopied: false })
              }}
              options={this.props.optionlistdata}
              name='ruleId'
              onMouseUp={this.handleSelectText}
              placeholder='Select a config rule'
              value={this.props.ruleId}
              style={{ height: '50px', width: '400px', position: 'centre' }}
            />
               {this.props.ruleId && (
                <Icon
                  name="copy"
                  onClick={() => {
                    let findRuleText = this.props.optionlistdata && this.props.optionlistdata.find((item) => item.value === this.props.ruleId)?.text
                    this.setState({ isRuleCopied: true, isAccountCopied: false })
                    this.handleCopyText(findRuleText)
                  }}
                  style={{...copyIconStyle,color: this.state.isRuleCopied ? '#f0f1f2' : "grey"}}
                />
              )}
            </div>
            <div style={{ position: 'relative',marginLeft:"10px" }}>
              <Form.Select
                required
                label={'Account Name'}
                options={this.props.acc_options}
                search
                placeholder='Select Accounts from list'
                name='accId'
                floated
                value={this.props.accId}
                onMouseUp={this.handleSelectText}
                onChange={(e, data) => {
                  this.props.handleDropDownChange(e, data)
                  this.setState({ isAccountCopied: false })
                }}
                style={{ height: 'floated', height: '50px', width: '400px' }} />
              {this.props.accId && (
                <Icon
                  name="copy"
                  onClick={() => {
                    let findAccountText = this.props.acc_options && this.props.acc_options.find((item) => item.value === this.props.accId)?.text
                    this.setState({ isAccountCopied: true, isRuleCopied: false })
                    this.handleCopyText(findAccountText)
                  }}
                  style={{...copyIconStyle,color: this.state.isAccountCopied ? '#f0f1f2' : "grey"}}
                />
              )}
            </div>
            {/* <Form.Input fluid label='Account ID' required name='accId' placeholder='Enter your 12 digit number' value={this.props.accId} onChange ={this.props.handleChange} style ={{height:'50px',width:'390px'}}/> */}
            <Form.Button type='submit' size='medium' value='Submit' disabled={this.props.ruleId < 1 || this.props.accId < 1 || this.props.errors && this.props.errors.length} style={{ backgroundColor: '#13a6d8', position: 'centre', marginTop: '30px' }}>Search</Form.Button>
          </Form.Group>
          <Message
            visible={!!this.props.errors && this.props.errors.length}
            warning
            header="Please correct the following issues: "
            list={this.props.errors}
          />
        </Form>
        {/* </Segment.Group> */}
      </Segment>
    )
  }
}

export default ConfigAccountSearchComp;
