export const tableHeader = [
  {
    key: 'RuleId',
    text: 'Rule Id',
    value: 'RuleId',
  },
  {
    key: 'RuleName',
    text: 'Rule Name',
    value: 'RuleName',
  },
  {
    key: 'ExceptionsCheckEnabled',
    text: 'Rule Monitored',
    value: 'ExceptionsCheckEnabled'
  },
  {
    key: 'ActionEnabled',
    text: 'Action Enabled',
    value: 'ActionEnabled',
  },
  {
    key: 'Severity',
    text: 'Severity',
    value: "Severity"
  }
]

export const dataPerPageOptions = [
  { key: '1', text: '5', value: 5 },
  { key: '2', text: '10', value: 10 },
  { key: '3', text: '20', value: 20 },
  { key: '4', text: '30', value: 30 },
  { key: '5', text: '50', value: 50 },
  { key: '6', text: '100', value: 100 },
  { key: '7', text: '200', value: 200 },
  { key: '8', text: '300', value: 300 },
  { key: '9', text: '400', value: 400 },
  { key: '10', text: '500', value: 500 }
]

export const pieChartDefaultValue = {
  title: {
    text: '',
    left: 'center'
  },
  tooltip: {
    trigger: 'item',
    formatter: '<b>Action Enable:</b>{b}<br/><b>Percentage:</b> {d}% <br/><b>Count(s):</b> {c}<br/><b>Note:</b> Click to view details.'
  },
  series: [
    {
      name: 'Auto Remediation',
      type: 'pie',
      radius: '60%',
      center: ['50%', '50%'],
      data: [],
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 1,
        color: function (params) {
          if (params.name === 'True') {
            return 'rgb(145, 204, 117)';
          }
          else if (params.name === "False") {
            return 'rgb(84, 112, 198)';
          }
          else if (params.name === "Email") {
            return 'rgb(238, 102, 102)';
          }
          else {
            return 'rgb(250, 200, 88)';
          }
        }
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0,0,0,0.5)'
        }
      },
      label: {
        show: true,
        formatter: '({d}%)\n{name|{b}}\n{time|{c}}',
        minMargin: 5,
        lineHeight: 15,
        rich: {
          time: {
            fontSize: 10,
            color: '#000',
          }
        },
      }
    }
  ]
}

const halfDonutOption = {
  tooltip: {
    trigger: 'item',
    formatter(param) {
      return `<b>Action Enable:</b> ${param.name}<br/><b>Percentage:</b> ${param.percent * 2}% <br/><b>Count(s):</b> ${param.value}<br/><b>Note:</b> Click to view details.`
    }
  },
  legend: {
    left: 0,
    textStyle: {
      fontSize: 9
    },
    icon: "circle",
    selectedMode: false
  },
  series: [
    {
      name: 'Action Enabled',
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['50%', '70%'],
      startAngle: 180,
      label: {
        show: false,
        formatter(param) {
          return param.name + ' (' + param.percent * 2 + '%)';
        }
      },
      data: [],
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 1,
        color: function (params) {
          if (params.name === 'True') {
            return 'rgb(145, 204, 117)';
          }
          else if (params.name === "False") {
            return 'rgb(84, 112, 198)';
          }
          else if (params.name === "Email") {
            return 'rgb(238, 102, 102)';
          }
          else {
            return 'rgb(250, 200, 88)';
          }
        }
      }
    }
  ]
}
export const actionEnabledOptions = ["True", "False", "Email", "Detach"]
export const severityKey = ["Medium", "Low", "Info", "Critical", "High"]
export { halfDonutOption }
