import React, { Component } from 'react';
import { Form, Segment,Confirm, Button, Message,Header,Dimmer,Loader, Select, Radio} from 'semantic-ui-react';
import ToastMessage from "../../../ReUsableComponent/ToastMessageComp";
import Modal from '../../../Modal';
import { cloudCompatibilityOption, commercialCloud, labelAws, labelAwsUsGov, usGovCloud } from '../../../../Config/appConstant';
//toast message

class ResourceExceptionSearchForm extends Component {

  render() {
    return (
      <Segment size='mini' style={{padding:'20px',borderColor:'blue',borderStyle:'Solid',margin:'6px',position:"relative"}}>
        {/* <Segment.Group> */}
        <Dimmer active={this.props.isSearchingException} inverted>
          <Loader>Searching...</Loader>
        </Dimmer>
        {this.props.onToastMsg &&<ToastMessage toastMessage={this.props.toastMessage} colour={this.props.toastColour} dismissToastMessage={this.props.dismissToastMessage}/>}
        
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
        <Header as='h3' color='blue'>Search Resource Exception</Header>
        <div>
          <b style={{fontSize:13,marginBottom:2}}>Selected organization:</b>
        <Select
          fluid
          label='Select Cloud'
          options={cloudCompatibilityOption}
          value={this.props.selectCloud}
          name="selectCloud"
          onChange={this.props.handleSelectCloudSpace}
          placeholder='Select Cloud'
          style={{position: "centre", width: "150px" }}
          />
          </div>
        </div>
          <Modal openModal={this.props.openModal} closeModal={()=>this.props.handleCloseModal()} title={'Choose organization:'}>
        <Form>
            <Form.Group widths='equal'>
              <Form.Field>
                <Radio
                  label={labelAws.label}
                  name="selectCloud"
                  value={labelAws.value}
                  checked={this.props.selectCloud === commercialCloud}
                  onChange={this.props.handleSelectCloudSpace}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={labelAwsUsGov.label}
                  name="selectCloud"
                  value={labelAwsUsGov.value}
                  checked={this.props.selectCloud === usGovCloud}
                  onChange={this.props.handleSelectCloudSpace}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal>
      <Form onSubmit ={this.props.handleSubmit}>
        <Form.Group widths='equal'>
          <Form.Select
              fluid
              search
              label='Rule Identifier'
              required
              onChange={this.props.handleDropDownChange}
              options={this.props.optionlistdata}
              name='ruleId'
              value={this.props.ruleId}
              placeholder='Select a config rule'
              style ={{height:'50px'}}
              loading={(this.props.optionlistdata !== undefined && this.props.optionlistdata.length>0) ? false : true }
            />
            <Form.Select
            required
            label='Account Name/ID'
            options={this.props.acc_options}
            search
            placeholder='Select Accounts from list'
            name='accId'
            floated
            value={this.props.accId}
            onChange={this.props.handleDropDownChange}
            loading={(this.props.acc_options!== undefined && this.props.acc_options.length>0) ? false : true }
            style ={{height:'50px'}} />
          {/* <Form.Input fluid label='Account ID' required value={this.props.accId} name='accId' onChange={this.props.handleChange} placeholder='12 digit account id' style ={{height:'50px'}}/> */}
          <Form.Input fluid label='Resource ID' placeholder='Resource - ARN, Name or ID' value={this.props.resourceId} name='resourceId' onChange={this.props.handleChange} style ={{height:'50px'}}/>
          <Form.Button type ='submit' size = 'medium' disabled={(this.props.ruleId==="0000" || this.props.accId==="")|| this.props.isSearchingException} value ='Submit' style={{backgroundColor:'#13a6d8',position:'centre',marginTop:'30px'}}>Search</Form.Button>
        </Form.Group>
        <Message visible={!!this.props.errors && this.props.errors.length} warning
            header='Please correct the following issues: '
            list={this.props.errors} />
      </Form>
      {/* </Segment.Group> */}
      </Segment>
    )
  }
}

export default ResourceExceptionSearchForm;