 export const tableHeader = [
    {
        key: 'AccountId',
        text: 'Account ID',
        value: 'AccountId',
    },
    {
        key: 'AccountName',
        text: 'Account Name',
        value: 'AccountName',
    },
    {
        key: 'ExceptionsCheckEnabled',
        text: 'Rule Monitored',
        value: 'ExceptionsCheckEnabled'
    },
    {
        key: 'ActionEnabled',
        text: 'Action Enabled',
        value: 'ActionEnabled',
    }
    
]

export const dataPerPageOptions = [
    { key: '1', text: '5', value: 5 },
    { key: '2', text: '10', value: 10 },
    { key: '3', text: '20', value: 20 },
    { key: '4', text: '30', value: 30 },
    { key: '5', text: '50', value: 50 },
    { key: '6', text: '100', value: 100 },
    { key: '7', text: '200', value: 200 },
    { key: '8', text: '300', value: 300 },
    { key: '9', text: '400', value: 400 },
    { key: '10', text: '500', value: 500 }
]

export const severityKey = ["Medium", "Low", "Info", "Critical", "High"]
