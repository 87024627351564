import React, { Component } from "react";
import {
  Form,
  Segment,
  Confirm,
  Button,
  Message,
  Header,
  Radio,
  Select,
} from "semantic-ui-react";
import ToastMessage from "../../../ReUsableComponent/ToastMessageComp";
import CheckSumGeneratorContainer from "../../Utility/CheckSumGeneratorContainer";
//added cpde for toast message

import ReactTooltip from "react-tooltip";
import Modal from "../../../Modal";
import { cloudCompatibilityOption, commercialCloud, labelAws, labelAwsUsGov, usGovCloud } from "../../../../Config/appConstant";

class ResourceExceptionFormComp extends Component {
  
  createConfirmMsg = () => {
    return (
      'Do you want to add this resource "' +
      this.props.resourceId +
      '" as an exception?'
    );
  };

  render() {
    const {ruleSeverity} =this.props
    return (
      <Segment
        size="mini"
        style={{
          padding: "30px",
          borderColor: "blue",
          borderStyle: "Solid",
          margin: "6px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
        <Header as="h3" color="blue">Create Resource Exception</Header>
        <div>
          <b style={{fontSize:13,marginBottom:2}}>Selected organization:</b>
        <Select
          fluid
          label='Select Cloud'
          options={cloudCompatibilityOption}
          value={this.props.selectCloud}
          name="selectCloud"
          onChange={this.props.handleSelectCloudSpace}
          placeholder='Select Cloud'
          style={{position: "centre", width: "150px" }}
          />
          </div>
        </div>
        <Modal openModal={this.props.openModal} closeModal={()=>this.props.handleCloseModal()} title={'Choose organization:'}>
        <Form>
            <Form.Group widths='equal'>
              <Form.Field>
                <Radio
                  label={labelAws.label}
                  name="selectCloud"
                  value={labelAws.value}
                  checked={this.props.selectCloud === commercialCloud}
                  onChange={this.props.handleSelectCloudSpace}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={labelAwsUsGov.label}
                  name="selectCloud"
                  value={labelAwsUsGov.value}
                  checked={this.props.selectCloud === usGovCloud}
                  onChange={this.props.handleSelectCloudSpace}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal>
        {this.props.onToastMsg && (
          <ToastMessage
            toastMessage={this.props.toastMessage}
            colour={this.props.toastColour}
            dismissToastMessage={this.props.dismissToastMessage}
          />
        )}
        {/* <Segment.Group> */}
        <Form style={{ width: "1300px" }}>
          <Form.Group widths="equal">
            <Form.Select
              required
              search
              fluid
              label="Rule Identifier"
              placeholder="Select a config rule"
              options={this.props.optionListData}
              onChange={this.props.handleDropDownChange}
              style={{ height: "50px", position: "centre" }}
              loading={this.props.optionListData !== "" ? false : true }
              value={this.props.ruleIdName}
            />
            <Form.Input
              fluid
              readOnly
              label={'Rule ID'}
              value={this.props.ruleId}
              style={{ height: "50px"}}
            />
            <Form.Input
              fluid
              readOnly
              label={"Rule Name"}
              value={this.props.ruleName}
              style={{ height: "50px"}}
            />
          </Form.Group>
          <Form.Group widths="equal" style={{ marginTop: "25px" }}>
            <Form.Select
              required
              label="Account Name/ID"
              options={this.props.acc_options}
              search
              placeholder="Select Accounts from list"
              name="accId"
              floated
              value={this.props.accId}
              onChange={this.props.handleDropDownChangeForAccId}
              style={{ height: "floated", height: "50px" }}
              loading={(this.props.acc_options!== undefined && this.props.acc_options.length>0) ? false : true }
            />
            <Form.Select
              required
              label="Region"
              options={this.props.resourceRegion}
              value={this.props.resourceRegionName}
              search
              placeholder="Select region"
              name="resourceRegion"
              floated
              loading={(this.props.resourceRegion !== undefined && this.props.resourceRegion.length>0) ? false : true}
              onChange={this.props.handleResourceRegionName}
              style={{ height: "floated", height: "50px" }}
            />
            {/* <Form.Input fluid label='Account ID' value={this.props.accId} name='accId' required onChange={this.props.handleChange} placeholder='Enter 12 digit account ID' style ={{height:'50px'}}/> */}
            <Form.Input
              fluid
              label="ServiceNow Ticket ID"
              required
              placeholder="ServiceNow Ticket ID"
              value={this.props.ticketId}
              name="ticketId"
              onChange={this.props.handleChange}
              style={{ height: "50px" }}
            />
          </Form.Group>
          <Form.Group style={{ marginTop: "25px" }} widths="equal">
            <Form.Input
              fluid
              label="Resource ID/ARN"
              placeholder="Resource ARN or ID"
              required
              value={this.props.resourceId}
              name="resourceId"
              onChange={this.props.handleChange}
              style={{ height: "50px" }}
            />
            {
              (this.props.resourceExceptionType && this.props.resourceExceptionType.includes("Composite")) ? (
                <Form.Input
                  fluid
                  label="Additional Entity"
                  placeholder="Additional Entity Name/ARN/Checksum"
                  className="additional_entity"
                  required
                  value={this.props.resourceChecksum}
                  name="resourceChecksum"
                  onChange={this.props.handleChange}
                  style={{ height: "50px" }}
                />
              ) : null
            }

            {(this.props.ruleSeverity === "High" || this.props.ruleSeverity === "Critical") ? (
               <Form.Input
               fluid
               label="PERA ID"
               required
               value={this.props.peraId}
               name="peraId"
               onChange={this.props.handleChange}
               placeholder="Pera Id"
               style={{ height: "50px" }}
             />
            ) : null}
            
          </Form.Group>
          <Form.Group widths="equal" style={{ marginTop: "25px" }}>
           
            <Form.Input
              fluid
              label="Approval Date"
              type="date"
              name="approvalDate"
              value={this.props.approvalDate}
              onChange={this.props.handleChange}
              placeholder="calendar date"
              style={{ height: "50px" }}
              max={this.props.todayDate}
            />
            <Form.Input
              fluid
              label="Expiry Date"
              type="date"
              name="expiryDate"
              value={this.props.expiryDate}
              onChange={this.props.handleChange}
              placeholder="calendar date"
              style={{ height: "50px" }}
              min={this.props.todayDate}
            />
          </Form.Group>
          <Form.Group style={{ marginTop: "25px" }}>
            <Form.Button
              size="medium"
              disabled={
                this.props.accId < 1 ||
                this.props.ruleId < 1 ||
                this.props.resourceRegionName < 1||
                this.props.ticketId<1 ||
                this.props.resourceId<1||
                (this.props.errors && this.props.errors.length)
              }
              style={{ backgroundColor: "#13a6d8" }}
              onClick={this.props.handleSubmit}
            >
              Submit
            </Form.Button>
            <Confirm
              open={this.props.open}
              onCancel={this.props.handleCancel}
              content={`Are you sure you want to create in ${this.props.selectCloud === commercialCloud ? labelAws.label : labelAwsUsGov.label}?`}
              onConfirm={this.props.handleConfirm}
            />
            <Button
              type="reset"
              size="medium"
              style={{ backgroundColor: "#13a6d8" }}
              onClick={()=>this.props.clearClick()}
            >
              Clear
            </Button>
          </Form.Group>
          <Message
            visible={!!this.props.errors && this.props.errors.length}
            warning
            header="Required Input Field!"
            list={this.props.errors}
          />
        </Form>
        {/* </Segment.Group> */}
      </Segment>
    );
  }
}

export default ResourceExceptionFormComp;
// {(this.props.ruleName === 'VA-iam-pb-compliance'|| this.props.ruleName === 'DEV-iam-pb-compliance' || this.props.ruleName === 'QA-iam-pb-compliance')&& <CheckSumGeneratorContainer accessToken={this.props.accessToken}/>}
