import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import { Icon, Table, Input, Grid, Pagination, Dropdown, Checkbox, Button, Popup, Image,Accordion } from 'semantic-ui-react'
import Papa from 'papaparse'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { tableHeader, dataPerPageOptions, pieChartDefaultValue, barChartDefaultValue } from './reportConstants'
import FilterBox from './FilterBox'
import csvIcon from '../../../../Assets/Images/csv_icon.png'
export default class SearchedResult extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pieChartData: pieChartDefaultValue,
            barChartRuleData: barChartDefaultValue,
            headers: tableHeader,
            form: {
                rule: '',
                exceptionStatus: '',
                region: '',
                search: '',
                severity:''
            },
            storeAccountsData: [],
            storeCheckAccountsData: [],
            currentPage: 1,
            dataPerPage: 10,
            sortTable: false,
            sortTableHeaderIndex: -1,
            toggleTableView: true,
            downloadMarkedAllData: false,
            headerKeys: [],
            downloadMarkedDataRecord: [],
            pieChartExceptionLength: [],
            activeIndex:-1

        }
        this.onChangeHandler = this.onChangeHandler.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.onSearchHandler = this.onSearchHandler.bind(this)
        this.handleDownloadCsv = this.handleDownloadCsv.bind(this)
        this.onClickPieChart = this.onClickPieChart.bind(this)
        this.onClickBarChart = this.onClickBarChart.bind(this)
        this.checkboxHandler = this.checkboxHandler.bind(this)
        this.handleSortTable = this.handleSortTable.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.advancedSearch = this.advancedSearch.bind(this)
        this.onDropdownChangeHandler = this.onDropdownChangeHandler.bind(this)
        this.handleDownloadPdf = this.handleDownloadPdf.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }
    onChangeHandler = (e, data) => {
        this.setState({ ...this.state, form: { ...this.state.form, [data.name]: data.value } })
    }
    onSearchHandler = () => {
        const { form } = this.state
        const copyStoreAccountsData = this.props.searchedResult?.data?.body?.data
        let filterStoreAccountsData = []
        if (form.rule === "" && form.exceptionStatus === "" && form.region === "" && form.severity === "") {
            filterStoreAccountsData = copyStoreAccountsData
        }
        else if (form.rule !== "" && form.exceptionStatus !== "" && form.region !== "" && form.severity!== "") {
            filterStoreAccountsData = copyStoreAccountsData?.filter((item) =>
                (item.RuleId === form.rule) &&
                (item.ExceptionStatus === form.exceptionStatus) &&
                (item.ExceptionRegion === form.region) &&
                (item.Severity === form.severity)
            )
        }
        else {
            filterStoreAccountsData = copyStoreAccountsData.filter(item => {
                return Object.entries({
                    RuleId: form.rule,
                    ExceptionRegion: form.region,
                    ExceptionStatus: form.exceptionStatus,
                    Severity:form.severity
                }).every(([key, values]) => {
                    if (!values) return true;
                    if (!Array.isArray(values)) {
                        values = [values];
                    }
                    return values.includes(item[key]);
                });
            });
        }
        this.setState({ ...this.state, storeAccountsData: filterStoreAccountsData, currentPage: 1 })
    }
    onDropdownChangeHandler = (e, data) => {
        this.setState({
            ...this.state,
            form: { ...this.state.form, [data.name]: data.value, search: '' }
        },
            () => {
                this.onSearchHandler()
            })
    }
    handlePageChange = (event, data) => {
        this.setState({ ...this.state, currentPage: data.activePage, downloadMarkedAllData: false });
    }
    handleDownloadCsv = (data, fileName) => {
        if (!data && !fileName) return
        const downloadData = data.map((item) => {
            return {
                RuleId: item.RuleId, RuleName: item.RuleName, AccountId: item.AccountId, Exception: item.Exception,
                ExceptionStatus: item.ExceptionStatus, ExceptionRegion: item.ExceptionRegion, ApprovalDate: item.ApprovalDate,
                RecertificationDate: item.RecertificationDate, ExpiryDate: item.ExpiryDate, ServiceNowTicketId: item.ServiceNowTicketId,
                PeraId: item.PeraId,Severity:item.Severity
            }
        })
        const csv = Papa.unparse(downloadData);
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `${fileName}.csv`);
        tempLink.click();
    }
    handleDownloadPdf = (body, header, fileName) => {
        if (!body && !header && !fileName) return
        const pdf = new jsPDF("l", "px")
        const now = new Date();
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        const accountName = this.props.accounts?.data?.filter((item) => item.value.toLowerCase() === this.props.form.account.toLowerCase())
        const orderedHeader = header.map((item) => item.text)
        const orderedBody = body?.map((item) => {
            return [
                item.RuleId, item.RuleName, item.Exception,
                item.ExceptionStatus, item.ExceptionRegion, item.ApprovalDate,
                item.LastRecertificationDate, item.ExpiryDate, item.ServiceNowTicketId,
                item.PeraId,item.Severity
            ]
        })
        pdf.setFontSize(12);
        accountName && pdf.text(`Report for the Resource Exception in the ${accountName[0].text.split('-')[0]} (${accountName[0].text.split('-')[1]}) account.`, 60, 20);
        autoTable(pdf, {
            head: [orderedHeader],
            body: orderedBody,
            theme: 'grid',
            styles: { fontSize: 8, cellWidth: "wrap" },
            columnStyles: {
                1: {
                    cellWidth: 50
                },
                2: {
                    cellWidth: 100
                },
                4: {
                    cellWidth: 50
                },
                5: {
                    cellWidth: 50
                },
                6: {
                    cellWidth: 50
                },
                7: {
                    cellWidth: 50
                },
                8: {
                    cellWidth: 50
                }
            },
            didDrawPage: (data) => {
                var pageStr = `Page ${data.pageNumber}. `
                var dateTimeStr = `Report generated on ${now.toLocaleString('en-US', options)}`
                var pageSize = pdf.internal.pageSize;
                var pageHeight = pageSize.height
                    ? pageSize.height
                    : pageSize.getHeight();
                pdf.setFontSize(9)
                pdf.text(dateTimeStr, 30, pageHeight - 20);
                pdf.text(pageStr, pageSize.width - 50, pageHeight - 20);
            }
        })
        pdf.save(`${fileName}.pdf`);
    }
    onClickPieChart = (e) => {
        const { exceptionStatusType } = this.props
        let status = exceptionStatusType.find((item) => item.text === e.data.name)
        this.setState({ ...this.state, form: { ...this.state.form, exceptionStatus: status.value, rule: '', region: '' } }, () => {
            this.onSearchHandler()
            const element = document.getElementById("result");
            element.scrollIntoView({ behavior: 'smooth' });

        })
    }
    onClickBarChart = (e) => {
        const { configRuleOptionLists } = this.props
        let rule = configRuleOptionLists.find((item) => item.text === e.name)
        this.setState({ ...this.state, form: { ...this.state.form, rule: rule.value, exceptionStatus: '', region: '' } }, () => {
            this.onSearchHandler()
            const element = document.getElementById("result");
            element.scrollIntoView({ behavior: 'smooth' });
        })
    }
    checkboxHandler = (e, data, item) => {
        let checkedData = this.state.storeCheckAccountsData
        if (data.checked) {
            let isDataExists = checkedData.some(value => value.index === item.index)
            if (!isDataExists) {
                checkedData.push(item)
                this.setState({ ...this.state, storeCheckAccountsData: checkedData })
            }
        }
        else {
            this.setState({
                ...this.state,
                storeCheckAccountsData: checkedData?.filter((value) => value.index !== item.index)
            })
        }
    }
    handleSortTable = (value, index) => {
        this.setState({
            ...this.state, sortTable: !this.state.sortTable, sortTableHeaderIndex: index, currentPage: 1, storeAccountsData: this.state.storeAccountsData.sort((a, b) => {
                if (this.state.sortTable) {
                    if (a[value] < b[value]) return -1;
                    if (a[value] > b[value]) return 1;
                }
                else {
                    if (a[value] > b[value]) return -1;
                    if (a[value] < b[value]) return 1;
                }
                return 0;
            })
        })

    }
    handleSearchChange = (e, data) => {
        let searchQuery = data.value
        this.setState({ ...this.state, currentPage: 1, form: { ...this.state.form, search: searchQuery } })
    }
    advancedSearch = (data, searchTerm, searchKeys) => {
        return data?.filter(item => {
            return searchKeys.some(key => {
                return typeof item[key] === 'string' && item[key].toLowerCase().includes(searchTerm.toLowerCase())
            })
        })
    }
    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
        this.setState({ activeIndex: newIndex })
      }
    componentDidMount() {
        const { exceptionStatusType } = this.props
        const { data } = this.props.searchedResult
        let pieExceptionData = []
        let barRuleData = []
        let otherData = data?.body.data;
        let excludeHeaderKeys = data?.body.data.map((item) => {
            const { index, LastModifiedBy, AccountId_RuleId, CreateTimeStamp, UpdateTimeStamp, ...rest } = item
            return rest
        })
        let headerKeys = excludeHeaderKeys?.length > 0 && Object.keys(excludeHeaderKeys[0])
        exceptionStatusType && exceptionStatusType.map((item) => {
            let result = data?.body.data.filter((dataItem) => dataItem.ExceptionStatus === item.value)
            if (item.value === "RULE_DECOMMISSIONED" || item.value === "ACCOUNT_DECOMMISSIONED") {
                return true
            }
            else if (result?.length == 0) {
                return true
            }
            else {
                pieExceptionData.push({
                    name: item.text,
                    value: result?.length
                })
            }

        })
        exceptionStatusType && exceptionStatusType.map((item) => {
            let result = otherData?.filter((dataItem) => dataItem.ExceptionStatus !== item.value)
            otherData = result
        })

        this.props.configRuleOptionLists?.map((item) => {
            let result = data?.body.data.filter((dataItem) => dataItem.RuleId === item.value)
            barRuleData.push({
                name: item?.text,
                value: result?.length
            })
        })

        /* 
        Only for development.
        To see, how many ExceptionStatus value is NULL.
        pieExceptionData.push({
            name: 'Other',
            value: otherData?.length
        }) */

        this.setState({
            ...this.state,
            pieChartData: {
                ...this.state.pieChartData,
                series: [
                    {
                        ...this.state.pieChartData.series[0],
                        data: pieExceptionData,
                    }
                ]
            },
            barChartRuleData: {
                ...this.state.barChartRuleData,
                xAxis: {
                    type: 'value',
                    interval: 2,
                    name: "No of Exceptions"
                },
                grid: {
                    align: 'center',
                    left: '40%',
                    right: '20%'
                },
                yAxis: {
                    type: 'category',
                    data: barRuleData?.map((item) => item.name),
                    name: "Rule Name"
                },
                series: [
                    {
                        data: barRuleData?.map((item) => item.value),
                        type: 'bar',
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        emphasis: {
                            focus: 'series'
                        }
                    }
                ]
            },
            storeAccountsData: data?.body.data,
            headerKeys: headerKeys,
            pieChartExceptionLength: pieExceptionData
        }, () => {
            const element = document.getElementById("account-search");
            element.scrollIntoView({ behavior: 'smooth' });
        })
    }

    render() {
        const { regionOptionLists, configRuleOptionLists } = this.props
        const { headers, currentPage, dataPerPage, form, storeAccountsData, toggleTableView,activeIndex,
            storeCheckAccountsData, downloadMarkedAllData, headerKeys, downloadMarkedDataRecord, pieChartExceptionLength } = this.state

        const addIndexInStoreAccountsData = storeAccountsData?.filter(item => {
            return headerKeys && headerKeys?.some(key => {
                return JSON.stringify(item[key])?.toLowerCase().includes(form.search?.trim().toLowerCase())
            })
        })?.map((item, index) => {
            return {
                ...item,
                index: index
            }
        })
        const indexOfLastData = currentPage * dataPerPage;
        const indexOfFirstData = indexOfLastData - dataPerPage;
        const currentData = addIndexInStoreAccountsData?.slice(indexOfFirstData, indexOfLastData);

        const RenderTableView = () => (
            <React.Fragment>
                <div style={{ width: "100%", overflow: 'scroll', maxHeight: "65vh", marginTop: "10px" }}>
                    <Table celled striped sortable id="pdf-view">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <Popup
                                        content={downloadMarkedAllData ? "Unmarked all" : "Marked all for download!"}
                                        trigger={
                                            <Checkbox checked={downloadMarkedDataRecord.includes(currentPage)} onChange={(e, data) => {
                                                if (data.checked) {
                                                    let filterData = currentData.filter((item) => !storeCheckAccountsData.some((value) => value.index === item.index))
                                                    this.setState({
                                                        ...this.state, storeCheckAccountsData: storeCheckAccountsData.concat(filterData),
                                                        downloadMarkedAllData: !downloadMarkedAllData, downloadMarkedDataRecord: [...downloadMarkedDataRecord, currentPage]
                                                    })
                                                }
                                                else {
                                                    this.setState({
                                                        ...this.state, storeCheckAccountsData: storeCheckAccountsData.filter((item) => {
                                                            return !currentData.some((res) => res.index === item.index)
                                                        }), downloadMarkedAllData: !downloadMarkedAllData, downloadMarkedDataRecord: downloadMarkedDataRecord.filter((item) => item !== currentPage)
                                                    })
                                                }
                                            }} />
                                        } />
                                </Table.HeaderCell>
                                {headers.map((item, index) => (
                                    <Table.HeaderCell key={index} sorted={this.state.sortTable && index === this.state.sortTableHeaderIndex ? 'ascending' : 'descending'}
                                        onClick={() => this.handleSortTable(item.value, index)}>
                                        {item.text}
                                    </Table.HeaderCell>
                                ))}

                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                currentData && currentData.length > 0 ? currentData.map((item, index) => (
                                    <Table.Row key={index} >
                                        <Table.Cell>
                                            <Checkbox value={item.RuleId} checked={this.state.storeCheckAccountsData?.some((data) => data.index === item.index)} onChange={(e, data) => {
                                                this.checkboxHandler(e, data, item)
                                            }} />
                                        </Table.Cell>
                                        <Table.Cell style={{ minWidth: "70px" }}>
                                            {item.RuleId}
                                        </Table.Cell>
                                        <Table.Cell style={{ minWidth: "70px" }}>{item.RuleName}</Table.Cell>
                                        <Table.Cell style={{ minWidth: "200px", maxWidth: "250px", wordBreak: "break-all" }}>
                                            {item.Exception}
                                        </Table.Cell>
                                        <Table.Cell style={{ minWidth: "70px" }}>
                                            {item?.ExceptionStatus}
                                        </Table.Cell>
                                        <Table.Cell style={{ minWidth: "70px" }}>
                                            {item?.ExceptionRegion}
                                        </Table.Cell>
                                        <Table.Cell >
                                            {item.ApprovalDate}
                                        </Table.Cell>
                                        <Table.Cell style={{ minWidth: "70px" }}>
                                            {item.LastRecertificationDate === undefined ? 'None' : item.LastRecertificationDate}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.ExpiryDate}
                                        </Table.Cell>
                                        <Table.Cell style={{ minWidth: "100px" }} >
                                            {item.ServiceNowTicketId}
                                        </Table.Cell>
                                        <Table.Cell >
                                            {item.PeraId}
                                        </Table.Cell>
                                        <Table.Cell >
                                            {item?.Severity}
                                        </Table.Cell>
                                    </Table.Row>
                                )) : (
                                    <Table.Row>
                                        <Table.Cell colSpan={`colSpan=${headers.length}`} >
                                            No records found!
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
                <div style={{ marginTop: "10px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <Pagination
                        activePage={currentPage}
                        totalPages={Math.ceil(addIndexInStoreAccountsData.length / dataPerPage)}
                        onPageChange={this.handlePageChange}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    />
                    <h3>Results:{`(${addIndexInStoreAccountsData.length})`}</h3>
                </div>
            </React.Fragment>
        )
        return (
            <React.Fragment>
                <Grid id="result" style={{ height: "100vh" }}>
                    <Grid.Row>
                        <Grid.Column width={3} style={{ display: toggleTableView ? "block" : "none" }}>
                            <FilterBox
                                form={form}
                                configRuleOptionLists={configRuleOptionLists}
                                exceptionStatusType={this.props.exceptionStatusType}
                                regionOptionLists={regionOptionLists}
                                onDropdownChangeHandler={this.onDropdownChangeHandler}
                            />

                        </Grid.Column>
                        <Grid.Column width={toggleTableView ? 13 : 16}>
                            <div style={{ display: 'flex', width: "100%", justifyContent: "space-between" }}>
                                <div style={{ display: "flex" }}>
                                    <Button style={{ marginTop: "20px" }} basic icon={toggleTableView ? "arrow left" : "filter"} onClick={() => {
                                        this.setState({ ...this.state, toggleTableView: !this.state.toggleTableView })
                                    }} />
                                    <Input
                                        placeholder="Search..."
                                        icon="search"
                                        value={form.search}
                                        onChange={(e, data) => {
                                            if (storeCheckAccountsData.length > 0) {
                                                this.setState({ ...this.state, currentPage: 1, form: { ...form, search: data.value }, storeCheckAccountsData: [], downloadMarkedAllData: false, downloadMarkedDataRecord: [] })
                                            } else {
                                                this.setState({ ...this.state, currentPage: 1, form: { ...form, search: data.value } })
                                            }
                                        }}
                                        style={{ width: "220px", marginTop: "20px" }}
                                    />
                                </div>
                                <div style={{ display: 'flex', width: "100%", justifyContent: "flex-end" }}>
                                    <div >
                                        <Dropdown
                                            placeholder='Record per page'
                                            selection
                                            options={dataPerPageOptions}
                                            value={this.state.dataPerPage}
                                            onChange={(e, data) => {
                                                this.setState({ ...this.state, dataPerPage: data.value, currentPage: 1 })
                                            }}
                                            style={{ height: "35px", marginTop: "20px", minWidth: 0 }}
                                        />
                                    </div>
                                    <div style={{ marginLeft: "5px", marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Popup
                                            content="Download results to CSV"
                                            trigger={<Button disabled={currentData.length === 0} basic onClick={() => {
                                                let downloadData = [];
                                                if (storeCheckAccountsData.length > 0 && form.search === "") {
                                                    downloadData = storeCheckAccountsData?.map((item) => {
                                                        const {
                                                            index, LastModifiedBy, AccountId_RuleId, CreateTimeStamp, UpdateTimeStamp
                                                            , ...rest } = item
                                                        return rest
                                                    })
                                                }
                                                else {
                                                    downloadData = addIndexInStoreAccountsData?.map((item) => {
                                                        const {
                                                            index, LastModifiedBy, AccountId_RuleId, CreateTimeStamp, UpdateTimeStamp
                                                            , ...rest } = item
                                                        return rest
                                                    })
                                                }
                                                this.handleDownloadCsv(downloadData, `account_${this.props.form.account}`)
                                            }} style={{ padding: "1px", height: "36px", width: "40px" }}>
                                                <Image src={csvIcon} style={{ marginLeft: "13px", height: "13.5px" }} />
                                            </Button>}

                                        />
                                        <Popup
                                            content="Download results to PDF"
                                            trigger={<Button disabled={currentData.length === 0} basic icon="file pdf" onClick={() => {
                                                let downloadData = [];
                                                let headerKey = tableHeader
                                                let fileName = `account_${this.props.form.account}`
                                                if (storeCheckAccountsData.length > 0 && form.search === "") {
                                                    downloadData = storeCheckAccountsData?.map((item) => {
                                                        const {
                                                            index, LastModifiedBy, AccountId_RuleId, CreateTimeStamp, UpdateTimeStamp
                                                            , ...rest } = item
                                                        return rest
                                                    })
                                                }
                                                else {
                                                    downloadData = addIndexInStoreAccountsData?.map((item) => {
                                                        const {
                                                            index, LastModifiedBy, AccountId_RuleId, CreateTimeStamp, UpdateTimeStamp
                                                            , ...rest } = item
                                                        return rest
                                                    })
                                                }
                                                this.handleDownloadPdf(downloadData, headerKey, fileName)
                                            }} />}
                                        />
                                        <Popup
                                            content="Visualization Insights"
                                            trigger={<Button basic icon='pie chart' onClick={() => {
                                                this.setState({ activeIndex: 0 }, () => {
                                                    const element = document.getElementById("visualization");
                                                    element.scrollIntoView({ behavior: 'smooth' });
                                                })
                                            }} />}
                                        />
                                    </div>
                                </div>
                            </div>
                            <RenderTableView />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Accordion id="visualization">
                    <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={this.handleClick}
                    >
                        <Icon name='dropdown' />
                        Visualization Insights
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        <Grid columns={2} celled='internally' style={{ height: "100vh", border: "1px solid #d4d4d5", borderRadius: "4px" }}>
                            <Grid.Row>
                                <Grid.Column>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center", fontSize: "14px", marginTop: "20px" }}>
                                        <h3>Valid Resource Exception - Total{`(${pieChartExceptionLength?.reduce((pre, current) => {
                                            return pre + parseInt(current.value)
                                        }, 0)})`}</h3>
                                    </div>
                                    <ReactEcharts option={this.state.pieChartData} onEvents={{ 'click': this.onClickPieChart }} />
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center", fontSize: "14px", marginTop: "20px" }}>
                                        <h3>Resource Exception/Config Rule</h3>
                                    </div>
                                    <ReactEcharts option={this.state.barChartRuleData} style={{
                                        minHeight: "450px"
                                    }} onEvents={{ 'click': this.onClickBarChart }} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Accordion.Content>
                </Accordion>
            </React.Fragment>
        )
    }
}
