import React, { Component } from 'react';
import { Form, Segment, Message,Header,Button,Icon} from 'semantic-ui-react';
import ReactTooltip from "react-tooltip";
import ToastMessage from '../../ReUsableComponent/ToastMessageComp';

class CheckSumGeneratorComp extends Component {
    constructor(props) {
        super(props);
    }

  render() {
    return (
      <Segment size='mini' style={{padding:'20px',borderColor:'blue',borderStyle:'Solid',margin:'6px'}}>
        {this.props.onToastMsg &&<ToastMessage toastMessage={this.props.toastMessage} colour={this.props.toastColour} dismissToastMessage={this.props.dismissToastMessage}/>}
        {/* <Segment.Group> */}
        <Header as='h3' color='blue'>Checksum generator for permission boundary</Header>
      <Form >
        <Form.Group>
          <Form.Input ref={(textarea) => this.textArea = textarea} fluid label='IAM Policy ARN' required name='PBName' placeholder='Enter a value ' value={this.props.PBName} onChange ={this.props.handleChange} style ={{height:'50px',width:'600px'}}/>
          {this.props.data && <Form.Input fluid label='Checksum' action={<Button type ='copy' size = 'medium' value ='Copy' style={{color: 'black',labelPosition: 'right',icon: 'copy',content: 'Copy',backgroundColor:'#13a6d8'}} onClick={() => {navigator.clipboard.writeText(this.props.data)}}>{<Icon name='copy'/>} </Button>} required name='data' placeholder='Enter a value ' value={this.props.data} onChange ={this.props.handleChange} style ={{height:'50px',width:'600px'}}/>}
        </Form.Group>
        <Form.Button onClick={this.props.handleSubmit}type ='submit' size = 'medium' value ='Submit' style={{backgroundColor:'#13a6d8',position:'centre',marginTop:'30px'}}>Generate Checksum</Form.Button>
        {/* {this.props.data && <Button type ='copy' size = 'medium' value ='Copy' onClick={() => {navigator.clipboard.writeText(this.props.data)}} style={{backgroundColor:'#13a6d8',position:'centre',marginTop:'30px'}}>Copy value</Button>} */}
        {this.props.PBName && <Message visible={!this.props.resourceArnValidation} warning 
            header='Please add resource ARN '
            fluid
            size='small'
            style ={{height:'50px',width:'390px'}} />}
        </Form>
      {/* </Segment.Group> */}
      </Segment>
    )
  }
}
// action={{color: 'teal',labelPosition: 'right',icon: 'copy',content: 'Copy'}}
export default CheckSumGeneratorComp;