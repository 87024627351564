import React, { Component } from "react";
import GlobalExceptionFormComp from "./GlobalExceptionFormComp";
import axios from "axios";
import update from "react-addons-update";
import config from '../../../../Config/Config';
import * as yup from 'yup';
import { array } from "yup/lib/locale";
import { commercialCloud, localStorageCloudValue } from "../../../../Config/appConstant";

class GlobalExceptionFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Rule:{},
      ruleId: "",
      ruleIdName:'',
      ruleName: "",
      errorPaths: [],
      errors: [],
      attributes: [{'resource_id':'','entity':''}],
      OperationMode:'Create',
      checkbox:false,
      toastMessage:'',
      onToastMsg:false,
      toastColour:'green',
      enableButton:true,
      optionlistdata:[],
      errorPaths: [],
      global_exception_type_dict:{},
      global_exception_format_dict:{},
      selectCloud:'',
      isModalOpen:true,
      open:false,
      isDelete:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.readDataRuleIdFromApi = this.readDataRuleIdFromApi.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.openOrCloseModalFunc = this.openOrCloseModalFunc.bind(this);
    this.readDataFromApi = this.readDataFromApi.bind(this);
    this.getSeletedCloud = this.getSeletedCloud.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleSelectCloudSpace = this.handleSelectCloudSpace.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.onConfirmHandler = this.onConfirmHandler.bind(this)
  }

  handleAddFields = () => {
    this.setState({
      attributes: [
        ...this.state.attributes,
        {'resource_id':'','entity':''},
      ],
    });
  };

  handleChangeInput = (index, e) => {
    this.setState(
      update(this.state, {
        attributes: {
          [index]: {
            [e.target.name]: {
              $set: e.target.value,
            },
          },
        },
      })
    );
  };

  handleRemoveFields = (index, event) => {

    this.setState({
      attributes: this.state.attributes.filter((_, i) => i !== index)
    },()=>{
      if(this.state.attributes.length === 0){
        this.setState({
          'OperationMode':'Delete'
        })
      }
    });

  };


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };


  handleCheckBoxChange=()=>{
    this.setState({'checkbox':!this.state.checkbox})
  }

  dismissToastMessage=()=>{
    this.setState({onToastMsg:false})
    this.clearState();
  }

  handleDismiss=()=>{
    this.setState({'enableButton':true})
  }

  // schema = yup.object().shape({
  //   attributes: yup.array().test({
  //       name: 'severitybasedenablement',
  //       exclusive: false,
  //       params: { },
  //       message: 'Resource id is a required field for high/critical severity rule',
  //       test: function (value) {
  //           console.log('hi')
  //           console.log(this.parent.attributes)
  //           console.log('validate function callled ')
  //           try{
  //             if(this.parent.ruleId){
  //               (this.parent.attributes).forEach(element => {
  //                 if(this.parent.checkbox === true){
  //                   if (element['resource_id'] != '' && element['entity'] != '')
  //                     {
  //                       return false
  //                     }
  //                   }
                
  //             });
  //             return true
  //             }
  //             else{
  //               return false
  //             }
  //           }
  //           catch{
  //             console.log('error')
  //           }
           
  //           // let get_value = this.parent.validateresid(this.parent.attributes)
  //           // return get_value
  //       },
  //     })
  // });

  validateresid = (exception_list)=>{
    let is_valid = true
    if(this.state.ruleId){
      exception_list.forEach(element => {
        if(this.state.checkbox === true){
          if (element['resource_id'] === '' || element['entity'] === '')
            {
              is_valid=false
            }
          }
      
    });
    // means true
    return is_valid
    }
    else{
      return false
    }

  }

  getSeletedCloud = (data)=> data[`${this.state.selectCloud?.split('_')[0].toLowerCase()}Cloud`]
  
  readDataFromApi = ()=> {
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
      headers:{
        'Authorization': this.props.authState.accessToken.accessToken
      }
    };

    axios.get(selectedUrl + 'configrules',options)
      .then(res => {
        var filteredData = res.data.body.data;
        var ruleid_list = filteredData?.filter((item)=>item.GlobalExceptionType!=="Not_Applicable" && (item?.CloudCompatibility !== undefined && item.CloudCompatibility[this.state.selectCloud]))
        let option_list=[];
        let global_entity_format = {}
        var global_exception_typedict = {}
        ruleid_list.map(myFunction)

        function myFunction(element) {
          var new_element={};
          new_element['key'] =element['RuleId']
          new_element['text'] =element['RuleId']+" "+element['RuleName']
          new_element['value'] =element['RuleId']+","+element['RuleName']
          option_list.push(new_element);
          global_exception_typedict[element['RuleId']]=element['GlobalExceptionType']
          global_entity_format[element['RuleId']]=element['GlobalExceptionFormat']
        }
        this.setState({['optionlistdata']:option_list,
                      ['global_exception_type_dict']:global_exception_typedict,
                    ['global_exception_format_dict']:global_entity_format});
      })
      .catch(function (error) {
        console.log(error);
    })
  };

  handleDropDownChange = async(event, data) => {
    
    let split_value = (data.value).split(',')
    this.setState({'ruleId': split_value[0],
      'ruleName':split_value[1],
                    'ruleIdName':data.value});
    if(this.state.global_exception_type_dict[split_value[0]] === 'Composite'){
      this.setState({'checkbox':true})
    }
    else{
      this.setState({'checkbox':false})
    }
    this.handleSearch(data.value)
    
  };

  handleSearch = async(value, callback) => {
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    let split_value = value.split(',')
    const options = {
      headers: {
        Authorization: this.props.authState.accessToken.accessToken,
      },
    };
    axios
      .get(
        selectedUrl +`exception/GLOBAL/search?RuleId=${split_value[0]}`,
        options
      )
      .then((res) => {
           if(Object.keys(res.data.body.data[0]).length === 0)
           {
             this.setState({
             'attributes': [{'resource_id':'','entity':''}]
            ,
            OperationMode:'Create'})
            // this.setState({
            //   'attributes': [{'resource_id':'','entity':''}]
            //  ,
            //  OperationMode:'Create'}, () => this.schema.validate(this.state, { abortEarly: false })
            //         .then(valid => this.setState({ errorPaths: [], errors: [] })) //called if the entire form is valid
            //         .catch(err => this.setState({ errors: err.errors, errorPaths: err.inner.map(i => i.path) }))) //called if any field is invalid
            }
            else{
              let resource_list = this.checkComposite(res.data.body.data[0]['Exception'])
              this.setState(
                {'Rule':res.data.body.data[0],
                'attributes': resource_list,
                OperationMode:'Update'
            })
        //     this.setState({
        //       'Rule':res.data.body.data[0],
        //     'attributes': resource_list,
        //     OperationMode:'Update'
        // }, () => this.schema.validate(this.state, { abortEarly: false })
        //             .then(valid => this.setState({ errorPaths: [], errors: [] })) //called if the entire form is valid
        //             .catch(err => this.setState({ errors: err.errors, errorPaths: err.inner.map(i => i.path) }))) //called if any field is invalid
            
            }
      });
  };

  checkComposite =(exception_list)=>{
    let formed_exception_list =[]
    let exception_dict_value ={}
    exception_list.forEach(element => {
      exception_dict_value = {'resource_id':'','entity':''}
      if(element.includes('+')){
        exception_dict_value['resource_id'] = (element.split('+'))[0]
        exception_dict_value['entity'] = (element.split('+'))[1]
      }
      else{
        exception_dict_value['resource_id'] = element
      }
      formed_exception_list.push(exception_dict_value)
    });
    return formed_exception_list
  }

  clearState=()=>{
    this.setState( {
      Rule:{},
      ruleName: "",
      errorPaths: [],
      errors: [],
      attributes: [{'resource_id':'','entity':''}],
      OperationMode:'Create',
      ruleId:'',
      ruleIdName:'',
      checkbox:false
    },()=>{
      this.readDataFromApi();
    })
  }

 formatDataForSubmission=(received_data)=>{
   let data_to_submit =[]
   received_data.forEach(element => {
      let concat_name='';
     if(this.state.checkbox){
      concat_name = element['resource_id'] + "+"+element['entity']
     }
     else{
      concat_name = element['resource_id']
     }
     data_to_submit.push(concat_name)
   });
   return data_to_submit
 }

  handleConfirm=()=>{
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const header = {
        Authorization: this.props.authState.accessToken.accessToken,
    }
    let data_to_submit = this.formatDataForSubmission(this.state.attributes);
  if(this.state.OperationMode === 'Create' || this.state.OperationMode === 'Update'){
      var rule = {
        RuleId: parseInt(this.state.ruleId),
        Exception:
        data_to_submit,
        RuleName:this.state.ruleName,
        CreateTimeStamp:this.state.Rule.CreateTimeStamp,
        UpdateTimeStamp:this.state.Rule.UpdateTimeStamp,
        LastModifiedBy: this.props.authState.accessToken.claims.sub
      };
    }
    let options = {
      url: selectedUrl + `exception/GLOBAL`,
      method:
        this.state.OperationMode !== "Delete"
          ? this.state.OperationMode === 'Create'
            ? "post"
            : "put"
          : "delete",
      headers: header,
    };
    if (this.state.OperationMode === "Delete") {
      options["params"] = {RuleId:this.state.ruleId,
        LastModifiedBy: this.props.authState.accessToken.claims.sub};
    } else {
      options["data"] = rule;
    }
    axios(options).then((res) => {
      if (res.data.status_code === 201)
        {
          this.setState({
            onToastMsg:true,
            toastMessage:res.data.body.message,
            toastColour:'green'
          })
        }
        else{
          this.setState({
            onToastMsg:true,
            toastMessage:res.data.body.message,
            toastColour:'red'
          })
        } 
    });
  }

  readDataRuleIdFromApi = () => {
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
      headers: {
        Authorization: this.props.authState.accessToken.accessToken,
      },
    };
    axios
      .get(
        selectedUrl+'configrules/RuleId',
        options
      )
      .then((res) => {
        var ruleid_dict = res.data.body.data[0].RuleId;
        this.setState({ ["ruleId"]: ruleid_dict });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleSubmit(event) {
    let final_validation = this.validateresid(this.state.attributes);
    if(final_validation){
      this.handleConfirm();
    }
    else{
      this.setState({'enableButton':false})
    }
    
  }

  openOrCloseModalFunc = (booldata) => {
    this.props.handleEditPopUpClose();
  };

  handleSelectCloudSpace = (event, data) => {
    localStorage.setItem(localStorageCloudValue,data?.value)
    this.setState((prevState)=>({...prevState, [data.name]: data.value,
      isModalOpen: false,
      attributes:[],
      optionlistdata:[],
      ruleIdName:"",
      onToastMsg:false
    }),()=>{
      this.readDataFromApi()
    });
  };

  handleCloseModal = ()=>{
    this.setState((prevState)=>({...prevState,openModal: false}),()=>{
      window.location.href = "/"
    });
  }
  handleCancel = () => {
    this.setState((prevState)=>({...prevState,open:false}));
  };

  onConfirmHandler = ()=>{
    this.setState((prevState)=>({...prevState,open:true}));
  }

  componentDidMount(){
    let value = localStorage.getItem(localStorageCloudValue)
    if(value !== null && value !==""){
      this.handleSelectCloudSpace({},{name:"selectCloud",value:value})
    }
  }
  render() {
    return (
      <GlobalExceptionFormComp
      {...this.state}
      readDataFromApi = {this.readDataFromApi}
      handleSubmit={this.handleSubmit}
      handleChange={this.handleChange}
      handleDropDownChange={this.handleDropDownChange}
      {...this.state}
      setEditData={this.setEditData}
      handleConfirm={this.handleConfirm}
      readDataRuleIdFromApi={this.readDataRuleIdFromApi}
      openModal={this.props.openModal}
      openOrCloseModalFunc={this.openOrCloseModalFunc}
      stringToDate={this.stringToDate}
      handleChangeInput={this.handleChangeInput}
      handleAddFields={this.handleAddFields}
      handleRemoveFields={this.handleRemoveFields}
      dismissToastMessage={this.dismissToastMessage}
      handleCheckBoxChange={this.handleCheckBoxChange}
      handleDismiss={this.handleDismiss}
      clearState = {this.clearState}
      handleSelectCloudSpace={this.handleSelectCloudSpace}
      handleCloseModal={this.handleCloseModal}
      handleCancel={this.handleCancel}
      onConfirmHandler = {this.onConfirmHandler}
      />
    );
  }
}

export default GlobalExceptionFormContainer;