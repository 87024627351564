import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import {withOktaAuth} from '@okta/okta-react';
import {Button} from 'semantic-ui-react';
import Home from './Home';
// import TableExampleColors from './Components/table';
export default withOktaAuth(class Authentication extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
        userName : '',
        groupName : ''
      }
      this.login = this.login.bind(this);
      this.logout = this.logout.bind(this);
    }
    componentDidUpdate() {
      this.checkAuthentication();
      //console.log('updating')
   }
   getRole(envType,roleName){
      try{
        if(roleName === "SuperAdmin"){
          return `users_configexceptionsmgmt${envType?.toLowerCase()}_superadmin`
        }
        else if(roleName === "CloudOps"){
          return `users_configexceptionsmgmt${envType?.toLowerCase()}_admin`
        }
        else if(roleName === "ReadOnly"){
          return `users_configexceptionsmgmt${envType?.toLowerCase()}_readonly`
        }
      }
      catch(err){
        console.error(err)
      }
   }
   checkAuthentication = async()=>{
      const authenticated = await this.props.authState.isAuthenticated;
      const getEnvType = process.env.REACT_APP_RUN_ENVIRONMENT || process.env.REACT_APP_API_ENDPOINT
      if (authenticated === true)
      {
          const user = this.props.authState.idToken.claims;
          const group = this.props.authState.accessToken.claims.groups;
          if(!this.state.userName && getEnvType !== undefined)
          {
            if(getEnvType?.toLowerCase()?.includes("prod")){
              if( group && (group.includes(this.getRole("prod", "SuperAdmin")))){
                this.setState({'groupName':'SuperAdmin'})
              }
              else if (group && (group.includes(this.getRole("prod", "CloudOps")))){
                this.setState({'groupName':'CloudOps'})
              }
              else if (group && (group.includes(this.getRole("prod", "ReadOnly")))) {
                this.setState({ 'groupName': 'ReadOnly' })
              }
            }
            else if(getEnvType?.toLowerCase()?.includes("qa")){
              if( group && (group.includes(this.getRole("qa", "SuperAdmin")))){
                this.setState({'groupName':'SuperAdmin'})
              }
              else if (group && (group.includes(this.getRole("qa", "CloudOps")))){
                this.setState({'groupName':'CloudOps'})
              }
              else if (group && (group.includes(this.getRole("qa", "ReadOnly")))) {
                this.setState({ 'groupName': 'ReadOnly' })
              }
            }
            else if(getEnvType?.toLowerCase()?.includes("dev"))
            {
              if( group && (group.includes(this.getRole("dev", "SuperAdmin")))){
                this.setState({'groupName':'SuperAdmin'})
              }
              else if (group && (group.includes(this.getRole("dev", "CloudOps")))){
                this.setState({'groupName':'CloudOps'})
              }
              else if (group && (group.includes(this.getRole("dev", "ReadOnly")))) {
                this.setState({ 'groupName': 'ReadOnly' })
              }
            }
          user && this.setState({
              // authenticated : true,
              userName : user
              // acc_token : await this.props.auth.getAccessToken(),
              // id_token : await this.props.auth.getIdToken() 
          });
        }
          //console.log(user)
      }
      // if(authenticated === false)
      //     this.login();
   }
    async login() {
      this.props.oktaAuth.signInWithRedirect();
    }
  
    async logout() {
      this.props.oktaAuth.signOut('/');
    }
  
    render() {
      if (this.props.authState.isPending) return <div>Loading...</div>;
      if (this.props.authState.isAuthenticated)
      {
        // return <button onClick={this.logout}>logout</button>
        // return <h1>{this.props.authState.user}</h1>
        return (<Home username ={this.state.userName} logout = {this.logout} {...this.props} groupName={this.state.groupName}/>)
      }
      else
      {
        this.login();
        // return <Button onClick={this.login} position='centre'>Login</Button>
        // return <div>Loading...</div>;
      }
    }
  });