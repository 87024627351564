import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { severityKey } from './reportConstant'
export default class FilterBox extends Component {
    render() {
        const { form, onDropdownChangeHandler } = this.props
        return (
            <React.Fragment>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginBottom: "10px", marginTop: "60px" }}><h3>Filters:</h3></div>
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <label style={{ fontWeight: "bold" }}>Action Enabled </label>
                        <Dropdown
                            name={'actionEnabled'}
                            placeholder='Select Action Enabled Type'
                            selection
                            search
                            multiple
                            clearable
                            options={[
                                {
                                    key: 1,
                                    text: "True",
                                    value: "True"
                                },
                                {
                                    key: 2,
                                    text: "False",
                                    value: "False"
                                },
                                {
                                    key: 3,
                                    text: "Email",
                                    value: "Email"
                                },
                                {
                                    key: 4,
                                    text: "Detach",
                                    value: "Detach"
                                }
                            ]}
                            value={form.actionEnabled}
                            onChange={onDropdownChangeHandler}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <label style={{ fontWeight: "bold" }}>Rule Monitored</label>
                        <Dropdown
                            name={'exceptionsCheckEnabled'}
                            placeholder='Select true or false'
                            selection
                            search
                            clearable
                            options={[
                                {
                                    key: 1,
                                    text: "True",
                                    value: true
                                },
                                {
                                    key: 2,
                                    text: "False",
                                    value: false
                                }
                            ]}
                            value={form.exceptionsCheckEnabled}
                            onChange={onDropdownChangeHandler}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <label style={{ fontWeight: "bold" }}>Severity</label>
                        <Dropdown
                            name={'severity'}
                            placeholder='Select Severity'
                            selection
                            search
                            clearable
                            options={[
                                {
                                    key: 1,
                                    text: severityKey[0],
                                    value: severityKey[0]
                                },
                                {
                                    key: 2,
                                    text: severityKey[1],
                                    value: severityKey[1]
                                },
                                {
                                    key: 3,
                                    text: severityKey[2],
                                    value: severityKey[2]
                                },
                                {
                                    key: 4,
                                    text: severityKey[3],
                                    value: severityKey[3]
                                },
                                {
                                    key: 5,
                                    text: severityKey[4],
                                    value: severityKey[4]
                                }
                            ]}
                            value={form.severity}
                            onChange={onDropdownChangeHandler}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
