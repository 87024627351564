import React, { Component } from 'react';
import CheckSumGeneratorComp from './CheckSumGeneratorComp';
import axios from 'axios';
import * as yup from "yup";
import config from '../../../Config/Config';
import { convertTokenParamsToOAuthParams } from '@okta/okta-auth-js';

class CheckSumGeneratorContainer extends Component {
  constructor(props) {
    super(props);
    // //console.log(this.props);
    this.state = {
      PBName: '',
      searchStatus:'',
      searchResultData:'',
      data:'',
      openModal:false,
      toastMessage:'',
      onToastMsg:false,
      toastColour:'green',
      resourceArnValidation:false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange=this.handleChange.bind(this);
  }
  schema = yup.object().shape({
    accId: yup.string()
    .required("Account ID is a required field")
    .matches(/^[0-9]+$/, "Account ID must be only digits")
    .min(12, "Account ID must be exactly 12 digits")
    .max(12, "Account ID must be exactly 12 digits")
  });

  dismissToastMessage=()=>{
    this.setState({onToastMsg:false})
  }

  validateResourceArn = async(resValue) =>{
    var re = new RegExp(/^(arn:aws:(\D+\d*):(us-east-1|us-west-2|us-east2|us-west-1|af-south-1|ap-east-1|ap-south-1|ap-northeast-3|ap-northeast-2|ap-southeast-1|ap-southeast-2|ap-northeast-1|ca-central-1|eu-central-1|eu-west-1|eu-west-2|eu-south-1|eu-west-3|eu-north-1|me-south-1|sa-east-1|:)*:\d{12}:policy)/, "g" );
    var result = re.test(resValue);
    await this.setState({['resourceArnValidation']:result})
  }

  handleChange = (datain) => {
    this.setState({ [datain.target.name]: datain.target.value,
    'data':''})

    if(datain.target.name === 'PBName')
    {
      this.validateResourceArn(datain.target.value);
    }
    console.log(this.state)
  };

  dataEditedLoading=()=>{
    this.getDataForSearch();
  }

  handleSubmit=(event)=>{
    if (this.state.PBName === ''){
      this.setState({
        onToastMsg:true,
        toastMessage:'Select a rule id and click on search',
        toastColour:'red'
      })
    }
    else {
      this.setState({['searchStatus']:''})
        this.getDataForSearch();
    }
  }

getDataForSearch=async()=>{
    const data = {
      params: {PBName:this.state.PBName},
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET',
      // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      headers:{
        'Authorization': this.props.accessToken
      }
    };
    await axios.get(config['apiendpoint']+'utils/checksum',data)
      .then(res => {
        console.log('Posting data for search',res)
        var accountSearchResultData = res.data.body.data;
        var statusCode = res.data.status_code
        console.log(res.data.body.data[0].checksum)
        if (statusCode === 200){
          // alert(res.data.body.message)
          this.setState({
            onToastMsg:true,
            toastMessage:res.data.body.message,
            toastColour:'green',
            data: (res.data.body.data[0].checksum),
            searchStatus:statusCode,
          })
          // this.handleClear();
        }
        else{
          this.setState({
            onToastMsg:true,
            toastMessage:res.data.body.message,
            toastColour:'red',
          })
        }
      })
      .catch(function (error) {
        console.log(error);
    })
  }

  handleClear=()=>{
    //console.log('handle clear')
    this.setState({['ruleId']:'0000',
    ['accId']:''
  })
  }

  dismissToastMessage=()=>{
    this.setState({onToastMsg:false})
  }

  render() {
    return (
        <div>
          <CheckSumGeneratorComp handleSubmit = {this.handleSubmit} handleChange={this.handleChange} {...this.state} copyCodeToClipboard={this.copyCodeToClipboard} dismissToastMessage={this.dismissToastMessage}/>
        </div>
    )
  }
}

export default CheckSumGeneratorContainer;