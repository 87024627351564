import React, { Component } from 'react';
import { Form, Segment,Confirm,Button,Header} from 'semantic-ui-react';
import ToastMessage from '../../../ReUsableComponent/ToastMessageComp';

class ConfigRuleSearchComp extends Component {

  componentDidMount() {
    this.props.readDataFromApi();
  }

  render() {
    return (
      <Segment size='mini' style={{padding:'20px',borderColor:'blue',borderStyle:'Solid',margin:'6px',minHeight: "200px"}}>
        {this.props.onToastMsg &&<ToastMessage toastMessage={this.props.toastMessage} colour={this.props.toastColour} dismissToastMessage={this.props.dismissToastMessage}/>}
        <Header as='h3' color='blue'>Search Config Rule</Header>
      <Form onSubmit ={this.props.handleSubmit}>
        <Form.Group>
          <Form.Select
              fluid
              search
              label='Rule Identifier'
              onChange={this.props.handleDropDownChange}
              options={this.props.optionlistdata}
              name='ruleId'
              placeholder='Select a config rule'
              style ={{height:'50px',width:'390px',position:'centre'}}
            />
            
          <Form.Button type ='submit' size = 'medium' value ='Submit' style={{backgroundColor:'#13a6d8',position:'centre',marginTop:'30px'}}>Search</Form.Button>
        </Form.Group>
      </Form>
      {/* </Segment.Group> */}
      </Segment>
    )
  }
}

export default ConfigRuleSearchComp;