import React, { Component } from 'react';
import axios from "axios";
import { Form, Segment,Label,Confirm, Button, Message,Header,Dropdown,Input, Select, Radio} from 'semantic-ui-react';
import config from '../../../Config/Config';
import update from "react-addons-update";
import {Dimmer,Loader} from "semantic-ui-react";
import ToastMessage from "../../ReUsableComponent/ToastMessageComp";
import { cloudCompatibilityOption, commercialCloud, labelAws, labelAwsUsGov, localStorageCloudValue, usGovCloud } from '../../../Config/appConstant';
import Modal from '../../Modal';

// const options = [
//   { key: 'Db_cluster_name', text: 'Db cluster name', value: 'Db_cluster_name' }
// ]

const optionListData = [
  { key: 'RDS_Cluster', text: 'Rds cluster', value: 'RDS_Cluster' },
  { key: 'us-east-1', text: 'us-east-1', value: 'us-east-1' },
  { key: 'us-west-1', text: 'us-west-1', value: 'us-west-1' },
  { key: 'us-west-2', text: 'us-west-2', value: 'us-west-2' }
]

const rule_portal_options = [
  { key: "t", text: "Rule", value: 'Rule' },
  { key: "f", text: "Portal", value: 'Portal' },
  { key: "automation", text: "Automation", value: 'Automation' },
  { key: "jenkins", text: "Jenkins", value: 'Jenkins' }
];

const removeFromDropdown = [
    "GlobalTrustedVendors",
    "BlacklistedTrustedVendors",
    "WhitelistedTrustedVendors",
    "S3TrustedPartner",
    "IamTrustedPartner",
    "S3GlobalTrustedPartners",
    "IamGlobalTrustedPartners",
    "S3BlackListedTrustedPartner",
    "S3WhiteListedTrustedPartner",
    "IamWhiteListedTrustedPartner",
    "IamBlackListedTrustedPartner",
    "ValidateExceptionPattern"
]



class MetaDataFormComp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      metaDataKeyOptions:[],
      currentValueMKey:'',
      currentValueGKey:'',
      groupKeyOptionsDict:{},
      GKeyOptionsForMkey:[],
      valueList:[{MetadataValue:'',method:'POST',LastModifiedBy:''}],
      deletedValueList:[],
      highestNumber:'',
      open:false,
      toastMessage:'',
      onToastMsg:false,
      toastColour:'green',
      ruleNameList:[''],
      rule_options:[],
      rulesSelected:[],
      ruleorPortalData:[],
      newRuleAddedFlag:false,
      parsedData:null,
      isModalOpen:true,
      selectCloud:"",
      metadataReceived:'',
      sortKeyReceived:''
    }
  }

  handleDropDownChange = async(e, value) =>{
    if(value.id === 'GKey'){
      await this.setState({ currentValueGKey: value.value})
      this.formValueListForMkeyGKey();
    }
    else{
      await this.setState({ currentValueMKey: value.value})
      this.dropDownForGKey();
    }
  }

  handleDropDownChangeforRule = (e, data) =>{
      this.setState({[data.name]: data.value })
      if(data.value === 'Rule'){
        // this.readDataFromApiForRuleName()
      }
      if(data.name === 'rulesSelected')
      {
        this.setState({['newRuleAddedFlag']:true})
      }
  }

  

  readDataFromApiForRuleName=async()=>{
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    //console.log(this.props.authState.accessToken.accessToken)
    const options = {
      headers:{
        'Authorization': this.props.accessData.authState.accessToken.accessToken
      }
    }
    await axios.get(selectedUrl+'configrules',options)
      .then(res => {
        var ruleid_list = res.data.body.data;
        let option_list=[];

        ruleid_list.map(myFunction)
        function myFunction(element) {
          var new_element={};
          new_element['key'] =element['RuleId']
          new_element['text'] =element['RuleId']+" "+element['RuleName']
          new_element['value'] =element['RuleName']
          option_list.push(new_element);
        }
        this.setState({['rule_options']:option_list});
      })
      .catch(function (error) {
        console.error(error);
    })
  }

  dropDownForGKey = ()=>{
      let selectedOptionListForGKey= this.state.groupKeyOptionsDict[this.state.currentValueMKey]
      this.setState({ ['GKeyOptionsForMkey']: selectedOptionListForGKey,
                      valueList:[{MetadataValue:'',method:'POST',LastModifiedBy:this.props.user}],
                      deletedValueList:[]
    })
    // }
    
  }

  handleAddition = (e, value ) => {
    if(value.id === 'MKey'){
      this.setState((prevState) => ({
        metaDataKeyOptions: [{ text: value.value, value:value.value }, ...prevState.metaDataKeyOptions],
      }))
    }
    else 
    {
      // console.log('I am trying to add a new gkey ')
      // setting an updated dict for newly added option 
      // First check the mkey is new one and we are trying to add a new gkey for that 
      if (this.state.currentValueMKey in (Object.keys(this.state.metadataReceived))){
        // console.log('existing mkey and new gkey')
        this.setState((prevState) => ({
          GKeyOptionsForMkey: [{ text: value.value, value:value.value }, ...prevState.GKeyOptionsForMkey],
        }))
      }
      else{
        // console.log('new mkey and gkey')
        this.setState({ GKeyOptionsForMkey: [{ text: value.value, value:value.value }]})
      }
    }
  }
  
  clearState=async()=>{
    this.setState({
      metaDataKeyOptions:[],
      currentValueMKey:'',
      currentValueGKey:'',
      groupKeyOptionsDict:{},
      GKeyOptionsForMkey:[],
      valueList:[{MetadataValue:'',method:'POST',LastModifiedBy:this.props.user}],
      deletedValueList:[],
      highestNumber:'',
      open:false,
      toastMessage:'',
      onToastMsg:false,
      toastColour:'green'
    }
    ,()=>{
      this.formatDataToOptionsForMetadataKey();
      this.setState({'highestNumber':this.state.sortKeyReceived+1})
    }
    );
  }

  formatDataToOptionsForMetadataKey = async() => {
    let keyListOfMKey = Object.keys(this.state.metadataReceived)
    let OptionListFormedForMKey = []
    let GroupKeyOptionsDictFormed ={}

    for(let eachKey of keyListOfMKey)
    {
      OptionListFormedForMKey.push({'text':eachKey,'value':eachKey})
      GroupKeyOptionsDictFormed[eachKey] = this.formGroupKeyDictForMKey(this.state.metadataReceived[eachKey]);
    }
    await this.setState({['metaDataKeyOptions']:OptionListFormedForMKey})
    await this.setState({['groupKeyOptionsDict']:GroupKeyOptionsDictFormed})
  }
  

  handleAddFields = () => {
    let getRandomKey = this.returnRandomKey();
    this.setState({
      valueList: [
        ...this.state.valueList,
        {
          MetadataValue: "",
          SortKey: getRandomKey,
          GroupKey:this.state.currentValueGKey,
          MetadataKey:this.state.currentValueMKey,
          method:'POST',
          LastModifiedBy:this.props.user
        },
      ],
    });
  };

  handleRemoveFields = async(index, e) => {
    let retrieveRecordBeforeDeletion = this.state.valueList[index]
    await this.setState({
      valueList: this.state.valueList.filter((_, i) => i !== index),
    });
    if(retrieveRecordBeforeDeletion['method'] !='POST'){
      retrieveRecordBeforeDeletion['method'] = 'DELETE'
      await this.setState({
        deletedValueList: [
          ...this.state.deletedValueList,
          retrieveRecordBeforeDeletion,
        ],
      })
    }
  };

  handleChangeInput = (index, e) => {
    if(index === 0)
    { 
      if(e.target.name === "MetadataValue" && JSON.stringify(e.target.value)?.includes("[",0)){
        this.setState((prevState)=>({...prevState,parsedData:this.handleParsedData(e.target.value)}))
      }
      this.handingInputforDAttributes(index,e.target.name,e.target.value);
    }
    else{
      this.handingInputforDAttributesForNonZeroIndex(index,e.target.name,e.target.value);
    }

  }

  handleInputChangeRuleField =(index,e) =>{
    var updatedRuleList = this.state.ruleNameList
    updatedRuleList[index]=e.target.value
    this.setState({
      RuleName: updatedRuleList
    }
      )
  }

  handleRuleNameAddField =() =>{
    // var updatedRuleList = this.state.ruleNameList
    // updatedRuleList[index]=e.target.value
    this.setState({
      ruleNameList: this.state.ruleNameList.concat('')
    }
      )
  }

  handleRuleNameRemoveField =(index,event) =>{
    var updatedRuleList = this.state.ruleNameList
    updatedRuleList.splice(index,1)
    this.setState({
      RuleName: updatedRuleList
    }
      )
  }

  handingInputforDAttributes = (index,key,value) =>{
    // console.log('Handling value for D attributes ',index)
    // console.log(this.state.valueList[index])
    
    if(this.state.valueList[index]['method'] === 'EXISTS' || this.state.valueList[index]['method'] === 'PUT'){
      // console.log('this is an existing value for zero index')
      this.setState(
       update(this.state, {
         valueList: {
           [index]: {
             [key]: {
               $set: value,
             },
             ['method']:{
               $set: 'PUT',
             },
             ['LastModifiedBy']:{
               $set:this.props.user
             }
           }, 
         },
       })
     );
     
     }
     else{
      let getRandomKey = this.returnRandomKey();
      // console.log('this is an new value for zero index')
      this.setState(
        update(this.state, {
        valueList: {
          [index]: {
            ['method']:{
              $set: 'POST',
            },
            [key]: {
              $set: value,
            },
            ['GroupKey']:{
              $set: this.state.currentValueGKey,
            },
            ['MetadataKey']: {
              $set: this.state.currentValueMKey,
            },
            ['SortKey']: {
              $set: getRandomKey,
            },
            ['LastModifiedBy']:{
              $set:this.props.user
            }
          },
        },
      }))
     }
  }

  handingInputforDAttributesForNonZeroIndex=(index,key,value)=>{
    if(this.state.valueList[index]['method'] === 'EXISTS' || this.state.valueList[index]['method'] === 'PUT'){
     this.setState(
      update(this.state, {
        valueList: {
          [index]: {
            [key]: {
              $set: value,
            },
            ['method']:{
              $set: 'PUT',
            }
          }, 
        },
      })
    );
    }
    else{
    let randomkey = this.returnRandomKey()
    this.setState(
        update(this.state, {
          valueList: {
            [index]: {
              [key]: {
                $set: value,
              },
            ['SortKey']: {
            $set: randomkey,
          }
            },
          },
        })
      );
    }
  }

  formGroupKeyDictForMKey=(data)=>{
    let keyListOfGKeyForMKey = Object.keys(data)
    let OptionListFormedForGKey = []

    for(let eachKey of keyListOfGKeyForMKey)
    {
      OptionListFormedForGKey.push({'text':eachKey,'value':eachKey})
    }
    return OptionListFormedForGKey
  }

  formValueListForMkeyGKey =()=>{
    try{
      let getValueForKeys = (this.state.metadataReceived)[this.state.currentValueMKey][this.state.currentValueGKey]
      let sortkeys = Object.keys(getValueForKeys)
      let MetadataValueList = []
      let i=0
      for(let eachKey of sortkeys)
      {
        MetadataValueList.push({SortKey:parseInt((this.state.metadataReceived)[this.state.currentValueMKey][this.state.currentValueGKey][eachKey]['SortKey']),
        MetadataKey:this.state.currentValueMKey,GroupKey:this.state.currentValueGKey,
        MetadataValue: typeof getValueForKeys[eachKey]['MetadataValue'] === "string" ? getValueForKeys[eachKey]['MetadataValue'] : JSON.stringify(getValueForKeys[eachKey]['MetadataValue'],null,2),method:'EXISTS',CreateTimeStamp:getValueForKeys[eachKey]['CreateTimeStamp'],UpdateTimeStamp:getValueForKeys[eachKey]['UpdateTimeStamp'],LastModifiedBy:this.props.user})
        if(i===0){
          this.setState({['rulesSelected']:getValueForKeys[eachKey]['RuleList'],
          ['ruleorPortalData']:getValueForKeys[eachKey]['MetadataType']})
        }
        
      }
      
      this.setState({['valueList']:MetadataValueList})
    }
    catch(err){
      console.error('error')
      this.setState({valueList:[{MetadataValue:'',method:'POST',LastModifiedBy:this.props.user}],
                    'highestNumber':this.state.sortKeyReceived})
    }
  }

  returnRandomKey=()=>{
    try{
      if(this.state.highestNumber === 0) {
        let getValueForKeys = (this.state.metadataReceived)[this.state.currentValueMKey][this.state.currentValueGKey]
        let mapKeysToArray = Object.keys(getValueForKeys)
        const NumberList = mapKeysToArray.map((i) => Number(i))
        const highestNumbers = Math.max(...NumberList)
        this.setState({'highestNumber': highestNumbers + 1 })
      }
      else{
        let numbergen = this.state.highestNumber;
        this.setState({'highestNumber': numbergen + 1 })
      }
      return this.state.highestNumber
    }
    catch(err){
      let numbergen = this.state.highestNumber;
      this.setState({'highestNumber': numbergen + 1 })
      return this.state.highestNumber
    }
  }

  handleParsedData = (data)=>{
    try{
      const newValue = JSON.parse(data)
      this.setState((prevState)=>({...prevState,parsedData:newValue}))
      return newValue
    }catch(error){
      console.error("Invalid JSON input", error)
      this.setState((prevState)=>({...prevState,parsedData:[]}))
    }

  }
 
  handleSubmit =()=>{
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    let  updatedvalueList = this.state.valueList
    const ruleList = this.state.rulesSelected
    const metadataType = this.state.ruleorPortalData
    const method = "PUT"
      
     /*  updatedvalueList.forEach(data=>{
        if(this.state.newRuleAddedFlag){
          data['RuleList']=this.state.rulesSelected
        }
        data['MetadataType']= this.state.ruleorPortalData
        data['method'] = 'PUT'
      }) */
      let response = updatedvalueList?.map((item)=>{
        let result = {...item}
        result["RuleList"] = ruleList
        result["MetadataType"] = metadataType 
        result.method = method
        if(this.state.newRuleAddedFlag){
          result['RuleList'] = this.state.rulesSelected
        }
        if(item?.MetadataValue?.includes("[",0)){
          result.MetadataValue = this.state.parsedData
        }
        return result
      })

    let finaldata = [].concat(response,this.state.deletedValueList)

    var finalDataDict={}
    finalDataDict['finaldata'] = finaldata
    finalDataDict['otherAttributeChanges'] = this.state.newRuleAddedFlag

    const options = {
      headers:{
        'Authorization': this.props.accessData.authState.accessToken.accessToken
      }
    }
    axios
      .put(
        selectedUrl+'metadata',finalDataDict,options).then((res) =>
        {
        this.setState({
          open: false,
        });
         if(res.status === 200){
            this.setState({
              onToastMsg:true,
              toastMessage:res.data.body.message,
              toastColour:'green',
              parsedData:null
            })
          }
          else{
            this.setState({
              onToastMsg:true,
              toastMessage:res.data.body.message,
              toastColour:'red',
              parsedData:null
            })
          }
      });
  }

  handleCancel=()=>{
    this.setState({'open':false})
  }

  createConfirmMsg=()=>{
    return "Are you sure?"
  }

  messagepopup=()=>{
    this.setState({
      'open':true
    })
  }

  dismissToastMessage=()=>{
    // this.clearClick();
    this.setState({onToastMsg:false},()=>{this.clearState()})
    this.handlerMetadata()
    
  }
  handleSelectCloudSpace = (event, data) => {
    this.clearState()
    localStorage.setItem(localStorageCloudValue,data?.value)
    this.setState((prevState)=>({...prevState, [data.name]: data.value,
      isModalOpen: false,ruleorPortalData:[]
    }),()=>{
      this.handlerMetadata()
    });
  };

  handleCloseModal = ()=>{
    this.setState((prevState)=>({...prevState,openModal: false}),()=>{
      window.location.href = "/"
    });
  }

  handlerMetadata=()=>{
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
      headers:{
        'Authorization': this.props.accessData.authState.accessToken.accessToken
      }
    }
   axios
      .get(
        selectedUrl+'metadata',options).then((res) =>
        {
          try{
            this.setState({
              metadataReceived: res.data.body.data[0].Metadata,
              sortKeyReceived:res.data.body.data[0].SortKey,
              dimmerActive:false
            },()=>{
              this.formatDataToOptionsForMetadataKey();
              this.setState({'highestNumber':this.state.sortKeyReceived+1})
              this.readDataFromApiForRuleName()
            });
          }
          catch(err){
            console.log(err)
          }
        
      });
  }
  componentDidMount() {
    this.handlerMetadata()
    let value = localStorage.getItem(localStorageCloudValue)
    if(value !== null && value !==""){
      this.handleSelectCloudSpace({},{name:"selectCloud",value:value})
    }
  }
  render() {
    const {parsedData,metaDataKeyOptions} = this.state
    const filterOutOptions = metaDataKeyOptions?.filter((item)=> !removeFromDropdown?.includes(item?.text))
    return (
      <Segment size='mini' style={{padding:'20px',borderColor:'blue',borderStyle:'Solid',margin:'6px'}}>
         <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
        <Header as='h3' color='blue'>Metadata</Header>
        <div>
          <b style={{fontSize:13,marginBottom:2}}>Selected organization:</b>
        <Select
          fluid
          label='Select Cloud'
          options={cloudCompatibilityOption}
          value={this.state.selectCloud}
          name="selectCloud"
          onChange={this.handleSelectCloudSpace}
          placeholder='Select Cloud'
          style={{position: "centre", width: "150px" }}
          />
          </div>
        </div>
          <Modal openModal={this.state.isModalOpen} closeModal={()=>this.handleCloseModal()} title={'Choose organization:'}>
        <Form>
            <Form.Group widths='equal'>
              <Form.Field>
                <Radio
                  label={labelAws.label}
                  name="selectCloud"
                  value={labelAws.value}
                  checked={this.state.selectCloud === commercialCloud}
                  onChange={this.handleSelectCloudSpace}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={labelAwsUsGov.label}
                  name="selectCloud"
                  value={labelAwsUsGov.value}
                  checked={this.state.selectCloud === usGovCloud}
                  onChange={this.handleSelectCloudSpace}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal>
        {this.state.onToastMsg &&<ToastMessage toastMessage={this.state.toastMessage} colour={this.state.toastColour} dismissToastMessage={this.dismissToastMessage}/>}
        <Form>
        <Form.Group widths='equal'>
        <div>
          <label style={{fontWeight:700}}>
            Metadata Key
          </label>
          <Dropdown
              fluid
              required
              search
              selection
              allowAdditions
              value={this.state.currentValueMKey}
              onChange={this.handleDropDownChange}
              options={filterOutOptions}
              onAddItem={this.handleAddition}
              id = 'MKey'
              style ={{height:'50px',width:'250px',marginTop:'5px'}}
            />
        </div>
        <div style={{marginLeft:'10px'}}>
            <label style={{fontWeight:700}}>
             Group Key
            </label>
            <Dropdown
            required
            fluid
            search
              selection
              allowAdditions
              value={this.state.currentValueGKey}
              onChange={this.handleDropDownChange}
              options={this.state.GKeyOptionsForMkey}
              id = 'GKey'
              onAddItem={this.handleAddition}
              style ={{height:'50px',width:'250px',position:'centre',marginTop:'5px'}}
          />
          </div>
          <div style={{marginLeft:'10px'}}>
          {this.state.valueList.map((inputField,index) => (
            <div >
              {index === 0 &&<label style={{fontWeight:700}}>Metadata Value </label>}
              { (!inputField.MetadataValue?.includes("[",0)) ? (
                <>
                <Input
                name="MetadataValue"
                fluid
                data-tip
                required
                data-for="rulenameTip"
                value={inputField.MetadataValue}
                onChange={event => this.handleChangeInput(index, event)}
                style ={{height:'50px',marginTop:'5px',width:'290px'}}
              />
              <Button onClick={event=>this.handleRemoveFields(index,event)} style ={{marginTop:'8px',marginLeft:'14px'}}>
                -
              </Button>
              <Button
                onClick={this.handleAddFields}
                style ={{marginTop:'8px',marginLeft:'2px'}}
              >
                +
              </Button>
                </>
              ) : (
                <Form.Field>
                  <div>
                  <textarea
                name="MetadataValue"
                fluid
                data-tip
                required
                data-for="rulenameTip"
                value={inputField.MetadataValue}
                onChange={event => {
                  this.handleChangeInput(index, event)
                }}
                style ={{width:'290px'}}
              />
                  </div>
              {(parsedData !== null && parsedData?.length === 0) ?
               (<span style={{fontSize:"10px",color:"red"}}>Please enter data in valid JSON format!</span>) : null}
                  </Form.Field>
              )}
              
            </div>
          )) }
          </div>
          <div style={{marginLeft:'10px'}}>
            <label style={{fontWeight:700}}>Used for</label>
          <Dropdown
              fluid
              required
              search
              selection
              allowAdditions
              name='ruleorPortalData'
              value={this.state.ruleorPortalData}
              onChange={this.handleDropDownChangeforRule}
              options={rule_portal_options}
              style ={{height:'50px',minWidth:'100px',marginTop:'5px'}}
            />
          </div>
          <div style={{marginLeft:'20px'}}>

          {this.state.ruleorPortalData ==='Rule' && 
          <Form.Select
            label='RuleName'
            options={this.state.rule_options}
            search
            placeholder='Select Accounts from list'
            required
            name='rulesSelected'
            multiple
            floated
            value={this.state.rulesSelected}
            onChange={this.handleDropDownChangeforRule}
            style ={{height:'floated',marginTop:'5px',width:'290px'}} />
          // this.state.ruleNameList.map((inputField,index) => (
          //   <div >
          //     {index === 0 &&<label>Rule Name </label>}
          //     <Input
          //       name="RuleNames"
          //       fluid
          //       data-tip
          //       required
          //       data-for="rulenameTip"
          //       value={inputField}
          //       onChange={event => this.handleInputChangeRuleField(index, event)}
          //       style ={{height:'50px',marginTop:'5px',width:'290px',marginLeft:'10px'}}
          //     />
          //     <Button onClick={event=>this.handleRuleNameRemoveField(index,event)} style ={{marginTop:'8px',marginLeft:'14px'}}>
          //       -
          //     </Button>
          //     <Button
          //       onClick={this.handleRuleNameAddField}
          //       style ={{marginTop:'8px',marginLeft:'2px'}}
          //     >
          //       +
          //     </Button>
          //   </div>
          // )) 
        }
          </div>
          
          <Confirm
              open={this.state.open}
              onCancel={this.handleCancel}
              content={`Are you sure you want to ${this.props.operationMode?.toLowerCase()} in ${this.state.selectCloud === commercialCloud ? labelAws.label : labelAwsUsGov.label}?`}
              onConfirm={this.handleSubmit}
            />
        </Form.Group>
        <Form.Group>
        <Form.Button type ='submit' size = 'medium' disabled={!(this.state.currentValueMKey && this.state.currentValueGKey)} value ='Submit' style={{backgroundColor:'#13a6d8',position:'centre',marginTop:'30px'}} onClick={this.messagepopup}>{this.props.operationMode}</Form.Button>
        <Form.Button type ='submit' size = 'medium' disabled={!!this.props.errors && this.props.errors.length || this.props.ruleId < 1} value ='Submit' style={{backgroundColor:'#13a6d8',position:'centre',marginTop:'30px'}} onClick={this.props.reload}>Clear</Form.Button>
        </Form.Group>
        <Message visible={!!this.props.errors && this.props.errors.length} warning
            header='Please correct the following issues: '
            list={this.props.errors} />
      </Form>
      {/* </Segment.Group> */}
      </Segment>
    )
  }
}

export default MetaDataFormComp;