import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Segment, Card,Icon,Accordion,Header } from 'semantic-ui-react';
import config from '../../Config/Config';

class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 }
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const description1 = [
      'hreedffff',
      'She enjoys the outdoors and currently resides in upstate New York.',
    ].join(' ')
    const { activeIndex } = this.state
    return (
      <Segment size='mini' style={{ padding: '30px', borderColor: 'blue', borderStyle: 'Solid', margin: '6px' }}>
        <Header as='h1' color='blue' block textAlign='center' style={{padding:'50px',margin:'6px'}}>{config['runenvironment'] === 'DEV' ? 'Welcome to DEV Config Exceptions Management Portal' : config['runenvironment'] ==='QA' ? 'Welcome to QA Config Exceptions Management Portal' :'Welcome to Config Exceptions Management Portal'}</Header>
        <Card.Group itemsPerRow='1' raised>
        <Card fluid color='blue'>
            <Card.Content header='FAQs and General Information' />
            <Card.Content extra>
            <Accordion styled fluid>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          Find more about Cloud Compliance Monitoring here
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <a href='https://epm.verisk.com/confluence/display/VSKCLDOPS/Compliance+Monitoring'>https://epm.verisk.com/confluence/display/VSKCLDOPS/Compliance+Monitoring</a>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          Find more about Verisk's AWS Config Exceptions here
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <a href='https://epm.verisk.com/confluence/display/VSKCLDOPS/AWS+Config+Exceptions'>https://epm.verisk.com/confluence/display/VSKCLDOPS/AWS+Config+Exceptions</a>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          Find more about Config System QA process here 
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
          <a href='https://epm.verisk.com/confluence/display/XWECLD/Config+System+QA+Testing'>https://epm.verisk.com/confluence/display/XWECLD/Config+System+QA+Testing</a>
        </Accordion.Content>
      </Accordion>
            </Card.Content>
        </Card>
        </Card.Group>
      </Segment>
    )
  }
}

export default WelcomePage;