import React, { useState, useEffect } from 'react'
import { Button, Select, Grid, Dimmer, Loader, Modal, Table, Label, Icon, Form, Popup, Header, Input, Message } from 'semantic-ui-react'
import { getMetadataLists, getAccountLists, deleteTrustedPartner } from '../service'

export default function GlobalDeleteTrustedPartner(props) {
  const [buAccounts, setBuAccounts] = useState({ isLoading: false, error: null, data: null })
  const [partnerNameFromOnChange, setPartnerNameFromOnChange] = useState("")
  const [metadata, setMetadata] = useState({
    isLoading: false, error: null, data: null, sortKey: 0, listOfTrustedPartner: []
  })
  const [selectedModalData, setSelectedModalData] = useState({ modalTitle: "", data: null, index: -1 })
  const [openModal, setOpenModal] = useState(false)
  const [verifyDelete, setVerifyDelete] = useState("")
  const [searchedResult, setSearchedResult] = useState({
    data: [], isLoading: false, message: "",
    openMessage: false, error: ""
  })

  const fetchAccountLists = (token) => {
    setBuAccounts({ isLoading: true, data: null, error: null })
    getAccountLists(token, props?.selectCloud).then((item) => {
      if (selectedModalData.data !== null && selectedModalData.modalTitle !== '') {
        setBuAccounts({ isLoading: false, data: item.data?.body?.data?.filter((item) => !selectedModalData.data?.Associations?.includes(item?.value)), error: null })
      }
      else {
        setBuAccounts({ isLoading: false, data: item.data?.body?.data, error: null })
      }
    }).catch(error => {
      setBuAccounts({ isLoading: false, data: null, error: error.message })
      console.error(error)
    })
  }
  const fetchMetadataLists = (token) => {
    setMetadata((preData) => ({ ...preData, isLoading: true }))
    getMetadataLists(token, props?.metaKey, props?.selectCloud).then((response) => {
      if (response?.data?.data.length > 0) {
        let listOfTrustedPartner = []
        let trustedPartnerUniqueName = new Set()
        let sortKeys = []
        response?.data?.data?.map((item, index) => {
          trustedPartnerUniqueName.add(item?.GroupKey)
          sortKeys.push(parseInt(item.SortKey))
        })

        trustedPartnerUniqueName.forEach((item) => {
          listOfTrustedPartner.push({
            key: item,
            text: item,
            value: item
          })
        })
        sortKeys.sort((a, b) => b - a)
        setMetadata((preData) => ({
          ...preData, isLoading: false,
          data: response?.data?.data,
          listOfTrustedPartner: listOfTrustedPartner,
          sortKey: sortKeys[0] + 1
        }))
      }

    }).catch(error => {
      setMetadata((preData) => ({
        ...preData,
        isLoading: false,
        data: null,
        error: error.message,
        listOfTrustedPartner: [],
        sortKey: 0
      }))
      console.log(error)
    })
  }

  useEffect(() => {
    let token = props.authState.accessToken.accessToken
    if (token) {
      fetchMetadataLists(token)
      fetchAccountLists(token)
    }
  }, [props?.selectCloud, props?.ruleId])

  const handleCloseModal = () => {
    setOpenModal(false)
    setVerifyDelete("")
  }
  const handleOpenModal = (data, index) => {
    setOpenModal(true)
    setSelectedModalData((prevData) => ({
      ...prevData,
      modalTitle: `${data?.GroupKey}-${data?.MetadataValue}`,
      data: data,
      index: index
    }))
  }
  const handleDeleteTrustedPartner = () => {
    let token = props.authState.accessToken.accessToken
    let payload = {
      key: selectedModalData?.data?.SortKey,
      updateBy: props?.authState?.accessToken?.claims?.sub,
      type: props?.partnerType,
      RuleId: props?.ruleId,
      MetadataKey: props?.metaKey
    }
    setSearchedResult({
      data: [], isLoading: true, message: "",
      openMessage: false, error: ""
    })
    deleteTrustedPartner(token, payload, props?.selectCloud).then((res) => {
      if (res.status === 204) {
        setSearchedResult({
          data: [], isLoading: false, message: "Record deleted successfully!",
          openMessage: true, error: ""
        })
        handleCloseModal()
        fetchMetadataLists(token)
        setInterval(() => { handleRemoveMessageBox() }, 5000)
      } else {
        setSearchedResult({
          data: [], isLoading: false, message: "",
          openMessage: true, error: "Failed to delete record!"
        })
        handleCloseModal()
        setInterval(() => { handleRemoveMessageBox() }, 5000)
      }
    }).catch(error => {
      console.error(error)
      handleCloseModal()
      setSearchedResult({
        data: [], isLoading: false, message: "",
        openMessage: true, error: error.message
      })
      setInterval(() => { handleRemoveMessageBox() }, 5000)
    })
  }

  const handleRemoveMessageBox = () => {
    setSearchedResult((prevState) => ({ ...prevState, openMessage: false, error: "", message: "" }))
  }

  return (
    <React.Fragment>
      <div style={{ width: "100%", top: 0, position: "absolute", left: 0 }}>
        <div style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }} >
          {searchedResult.openMessage ? <Message size="large" style={{ width: "40%" }} onDismiss={handleRemoveMessageBox}
            content={searchedResult.message === "" ? searchedResult.error : searchedResult.message}
            color={searchedResult.message === "" ? "red" : "green"} /> : null}
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        size='small'
      >
        <Modal.Header>{selectedModalData?.data?.GroupKey}</Modal.Header>
        <Modal.Content style={{ minHeight: "100px" }}>
          <Dimmer active={searchedResult.isLoading}>
            <Loader />
          </Dimmer>
          <div style={{ textAlign: "center" }}>
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>Are you sure you want to delete</span>
            <span style={{ fontWeight: "bold", color: "red", margin: "0 2px 0 2px", fontSize: "16px" }}> {selectedModalData.data?.MetadataValue} </span>
            <span style={{ fontWeight: "bold", fontSize: "16px" }}> Account? </span>
          </div>
          <div style={{ display: "flex", marginTop: "10px", width: "100%", justifyContent: "center" }}>
            <Input
              name="verify-delete"
              onChange={(e, data) => { setVerifyDelete(data.value) }}
              style={{ padding: 0, width: "350px" }}
              placeholder="Please enter account"
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button info onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button negative disabled={selectedModalData?.data?.MetadataValue !== verifyDelete}
            onClick={handleDeleteTrustedPartner}>
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <fieldset style={{ marginTop: 40, border: "1px solid #cfcfcf" }}>
        <legend style={{ padding: "5px 10px", backgroundColor: "rgb(42, 125, 225)", color: "#fff" }}>Delete Trusted Partner</legend>
        <div style={{ marginTop: "30px", padding: 10 }}>
          <Grid columns={3} stackable>
            <Grid.Row>
              <Grid.Column>
                <div><label style={{ fontWeight: 700, fontSize: "12px" }}>Select Trusted Partner<span style={{ color: '#db2828' }}>*</span></label></div>
                <Select
                  search
                  loading={metadata?.isLoading}
                  placeholder='Select'
                  options={metadata?.listOfTrustedPartner}
                  style={{ minWidth: "90%" }}
                  onChange={(e, data) => {
                    setPartnerNameFromOnChange(data.value)
                  }}
                  value={partnerNameFromOnChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div style={{ marginTop: "30px" }}>
            {metadata.data?.filter((item) => item.GroupKey.toLowerCase() === partnerNameFromOnChange.toLowerCase())?.length > 0 ? (
              <Table celled padded>
                <Table.Header>
                  <Table.Row>
                    {["Partner Account ID", "Action"].map(
                      (item) => (<Table.HeaderCell textAlign="center" >{item}</Table.HeaderCell>))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {!metadata?.isLoading ? <>
                    {metadata.data?.filter((item) => item.GroupKey.toLowerCase() ===
                      partnerNameFromOnChange.toLowerCase())?.map((seletedItem, index) => (
                        <Table.Row key={index}>
                          <Table.Cell textAlign="center">
                            <b>{seletedItem?.MetadataValue}</b>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Icon name="trash alternate outline" size="large" onClick={() => handleOpenModal(seletedItem)} style={{ cursor: "pointer" }} />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </> : <>loading...</>}
                </Table.Body>
              </Table>
            ) : null}
          </div>
        </div>
      </fieldset>
    </React.Fragment>
  )
}
