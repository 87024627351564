import _ from "lodash";
import React, { useState } from "react";
import { Button, Icon, Segment, Table } from "semantic-ui-react";

const SortableTable = (props) => {
  const headers = props.headers;
  const isButtonActive = props?.isButtonActive || false
  const [body, setBody] = useState(props.data);
  const [direction, setDirection] = useState("");
  const [sortHeader, setSortHeader] = useState("");
  const sort = (header) => {
    if (direction && direction === "ascending") {
      setDirection("descending");
      setSortHeader(header);
      setBody(_.orderBy(body, header, "desc"));
    } else {
      setDirection("ascending");
      setSortHeader(header);
      setBody(_.sortBy(body, header));
    }
  };

  return (
    <Segment size='mini' style={{padding:'0px',borderColor:'blue',borderStyle:'Solid',margin:'6px',overflowY: 'scroll'}}>
        <Table size= 'large' sortable celled singleLine>
        <Table.Header>
            <Table.Row>
            {headers.map((header) => (
                <Table.HeaderCell
                sorted={header === sortHeader ? direction : 'descending'}
                style={{ backgroundColor: "#D3D3D3" }}
                inverted
                onClick={() => sort(header)}
                >
                {header}
                </Table.HeaderCell>
            ))}
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {body.map((row, index) => (
            <Table.Row key={index} style={{ lineHeight: '14px'}}>
                {headers.map((cellKey) => (
                <Table.Cell key={cellKey + row[cellKey]}>
                    {(row[cellKey]===false || row[cellKey])&& String(row[cellKey])}
                </Table.Cell>
                ))}
                <Table.Cell >
                    {/* <Button primary >
                        <Icon name='pencil'/>
                    </Button>
                    <Button secondary >
                        <Icon name='delete'/>
                    </Button> */}

                    <Button primary onClick={() => props.handleEdit(row)} disabled={isButtonActive}>
                        <Icon name='pencil'/>
                    </Button>
                    {!props.enableDelete && <Button secondary onClick={() => props.handleDelete(row)} >
                        <Icon name='trash'/>
                    </Button>}
                </Table.Cell>
            </Table.Row>
            ))}
        </Table.Body>
        </Table>
    </Segment>
  );
}

export default SortableTable;