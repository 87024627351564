import React from 'react'
import {
    ModalHeader,
    ModalDescription,
    ModalContent,
    ModalActions,
    Button,
    Header,
    Image,
    Modal,
} from 'semantic-ui-react'

function RemediationModal({ openModal, closeModal, title, children }) {
    const [open, setOpen] = React.useState(false)

    return (
        <Modal
            open={openModal}
            size={"small"}
        >
            <ModalHeader style={{ backgroundColor: "rgb(42, 125, 225)", color: "#fff" }}>{title}</ModalHeader>
            <ModalContent>
                <div style={{ width: "100%", textAlign: "center", margin: "30px 0 30px 0" }}>
                    {children}
                </div>
                <div style={{ display: "flex",  justifyContent:"space-between", flexDirection:"row-reverse"}}>
                    <div>
                    <Button color="red"  onClick={() => closeModal()}>
                        Cancel
                    </Button>
                    </div>
                </div>
            </ModalContent>
        </Modal>
    )
}

export default RemediationModal;