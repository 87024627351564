import React, { useState, useEffect } from 'react'
import { Button, Select, Grid, Dimmer, Loader, Modal, Table, Input, Message, Icon, ButtonContent } from 'semantic-ui-react'
import { getMetadataLists, getAccountLists, createTrustedPartner, getMetadataDetailsFromKey } from '../service'
import { commercialCloud, metadataTableKeys } from '../../../../../Config/appConstant'
import config from '../../../../../Config/Config'
import axios from 'axios'

export default function GlobalUpdateTrustedPartner(props) {
  const [buAccounts, setBuAccounts] = useState({ isLoading: false, error: null, data: null })
  const [partnerNameFromOnChange, setPartnerNameFromOnChange] = useState("")
  const [metadata, setMetadata] = useState({
    isLoading: false, error: null, data: null, sortKey: 0, listOfTrustedPartner: []
  })
  const [selectedModalData, setSelectedModalData] = useState({ modalTitle: "", data: null, index: -1 })
  const [openModal, setOpenModal] = useState(false)
  const [partnerIds, setPartnerIds] = useState([{ id: 0, value: '', error: false, message: "" }])
  const [searchedResult, setSearchedResult] = useState({
    data: [], isLoading: false, message: "",
    openMessage: false, error: ""
  })
  const [whiteListTrustedPartner, setWhiteListTrustedPartner] = useState({
    data: [],
    isLoading: false,
    message: "",
    error: ""
  })
  const [blackListTrustedPartner, setBlackListTrustedPartner] = useState({
    data: [],
    isLoading: false,
    message: "",
    error: ""
  })
  const [accountDetail, setAccountDetail] = useState({
    data: [],
    isLoading: false,
    message: "",
    error: ""
  })
  const fetchAccountLists = (token) => {
    setBuAccounts({ isLoading: true, data: null, error: null })
    getAccountLists(token, props?.selectCloud).then((item) => {
      if (selectedModalData.data !== null && selectedModalData.modalTitle !== '') {
        setBuAccounts({ isLoading: false, data: item.data?.body?.data?.filter((item) => !selectedModalData.data?.Associations?.includes(item?.value)), error: null })
      }
      else {
        setBuAccounts({ isLoading: false, data: item.data?.body?.data, error: null })
      }
    }).catch(error => {
      setBuAccounts({ isLoading: false, data: null, error: error.message })
      console.error(error)
    })
  }
  const fetchMetadataLists = (token) => {
    setMetadata((preData) => ({ ...preData, isLoading: true }))
    getMetadataLists(token, props?.metaKey, props?.selectCloud).then((response) => {
      if (response?.data?.data.length > 0) {
        let listOfTrustedPartner = []
        let trustedPartnerUniqueName = new Set()
        let sortKeys = []
        response?.data?.data?.map((item, index) => {
          trustedPartnerUniqueName.add(item?.GroupKey)
          sortKeys.push(parseInt(item.SortKey))
        })

        trustedPartnerUniqueName.forEach((item) => {
          listOfTrustedPartner.push({
            key: item,
            text: item,
            value: item
          })
        })
        sortKeys.sort((a, b) => b - a)
        setMetadata((preData) => ({
          ...preData, isLoading: false,
          data: response?.data?.data,
          listOfTrustedPartner: listOfTrustedPartner,
          sortKey: sortKeys[0] + 1
        }))
      }

    }).catch(error => {
      setMetadata((preData) => ({
        ...preData,
        isLoading: false,
        data: null,
        error: error.message,
        listOfTrustedPartner: [],
        sortKey: 0
      }))
      console.log(error)
    })
  }
  const fetchMetadataDetailsFromKey = (token) => {
    let blackListMetaKey = props?.ruleId === 1007 ? metadataTableKeys?.s3BlackList : metadataTableKeys?.iamBlackList
    let whiteListMetaKey = props?.ruleId === 1007 ? metadataTableKeys?.s3WhiteList : metadataTableKeys?.iamWhiteList

    getMetadataDetailsFromKey(token, blackListMetaKey, props?.selectCloud).then((response) => {
      const result = response?.data?.body?.data
      if (result?.length > 0) {

        setBlackListTrustedPartner({ data: result[0]?.Metadata[blackListMetaKey]?.TrustedPartners[0]?.MetadataValue })
      }

      getMetadataDetailsFromKey(token, whiteListMetaKey, props?.selectCloud).then((response) => {
        const reponseData = response?.data?.body?.data
        setWhiteListTrustedPartner({ data: reponseData[0]?.Metadata[whiteListMetaKey]?.TrustedPartners[0]?.MetadataValue })
      }).catch(err => {
        console.error(err)
      })
    }).catch(err => {
      console.error(err)
    })
  }
  useEffect(() => {
    let token = props.authState.accessToken.accessToken
    if (token) {
      fetchMetadataLists(token)
      fetchAccountLists(token)
      fetchMetadataDetailsFromKey(token)
    }
  }, [props?.selectCloud, props?.partnerType, props?.ruleId])

  const handleCloseModal = () => {
    setOpenModal(false)
    setPartnerIds([{ id: 0, value: '', error: false, message: "" }])
  }
  const handleOpenModal = (data) => {
    setOpenModal(true)
    setSelectedModalData((prevData) => ({
      ...prevData,
      modalTitle: partnerNameFromOnChange,
      data: { GroupKey: partnerNameFromOnChange }
    }))
  }
  const handleUpdateTrustedPartner = () => {

    let token = props.authState.accessToken.accessToken

    let payload = partnerIds.map((item, index) => {
      return ({
        MetadataKey: props?.metaKey,
        SortKey: metadata?.sortKey + index,
        GroupKey: partnerNameFromOnChange,
        LastModifiedBy: props?.authState?.accessToken?.claims?.sub,
        MetadataType: "Portal",
        MetadataValue: item.value,
        Associations: [],
        RuleList: []
      })
    })

    setSearchedResult({
      data: [], isLoading: true, message: "",
      openMessage: false, error: ""
    })

    createTrustedPartner(token, payload, props?.selectCloud).then((res) => {
      if (res.status === 201) {
        setSearchedResult({
          data: [], isLoading: false, message: "Record Updated successfully!",
          openMessage: true, error: ""
        })
        handleCloseModal()
        fetchMetadataLists(token)
        setInterval(() => { handleRemoveMessageBox() }, 5000)
      } else {
        setSearchedResult({
          data: [], isLoading: false, message: "",
          openMessage: true, error: "Failed to update record!"
        })
        handleCloseModal()
        setInterval(() => { handleRemoveMessageBox() }, 5000)
      }
    }).catch(error => {
      console.error(error)
      handleCloseModal()
      setSearchedResult({
        data: [], isLoading: false, message: "",
        openMessage: true, error: error.message
      })
      setInterval(() => { handleRemoveMessageBox() }, 5000)
    })
  }

  const handleRemoveMessageBox = () => {
    setSearchedResult((prevState) => ({ ...prevState, openMessage: false, error: "", message: "" }))
  }
  const handleAddMoreInputFields = () => {
    const newInputFields = [...partnerIds]
    newInputFields.push({ id: Date.now(), value: "" })
    setPartnerIds(newInputFields)
  }

  const handleRemoveInputFields = (index) => {
    const newInputFields = partnerIds.filter((_, i) => i !== index);
    setPartnerIds(newInputFields)
  }

  return (
    <React.Fragment>
      <div style={{ width: "100%", top: 0, position: "absolute", left: 0 }}>
        <div style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }} >
          {searchedResult.openMessage ? <Message size="large" style={{ width: "40%" }} onDismiss={handleRemoveMessageBox}
            content={searchedResult.message === "" ? searchedResult.error : searchedResult.message}
            color={searchedResult.message === "" ? "red" : "green"} /> : null}
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        size='small'
      >
        <Modal.Header>{selectedModalData?.data?.GroupKey}</Modal.Header>
        <Modal.Content style={{ minHeight: "100px" }}>
          <Dimmer active={searchedResult.isLoading || accountDetail?.isLoading}>
            <Loader />
          </Dimmer>
          <div >
            <label style={{ fontWeight: "bold", fontSize: "16px" }}>Enter New Partner Account:</label>
          </div>
          <div style={{ marginTop: "10px", width: "100%" }}>
            {partnerIds?.map((item, index) => (
              <div key={index}>
                <div style={{ marginTop: "10px", width: "100%" }}>
                  <div style={{ display: "flex" }}>
                    <Input
                      name={`partner-id-${index}`}
                      value={item?.value}
                      onChange={(e, data) => {
                        if (!isNaN(data?.value)) {
                          if (data?.value.length <= 12) {
                            const newInputFields = [...partnerIds]
                            newInputFields[index].value = data?.value
                            newInputFields[index].error = false
                            newInputFields[index].message = ""

                            let token = props.authState.accessToken.accessToken
                            if (data.value === "") {
                              newInputFields[index].error = false
                              newInputFields[index].message = ""
                            }
                            else if (data?.value?.length < 12) {
                              newInputFields[index].error = true
                              newInputFields[index].message = "Length must be equal to 12."
                            }
                            else if(partnerIds?.filter((item)=>(item?.value?.toLowerCase() === data.value?.toLowerCase()))?.length>1){
                              newInputFields[index].error = true
                              newInputFields[index].message = "Partner Account Already Entered!"
                            }
                            else if (metadata.data?.some((item) => (item?.MetadataValue === data.value)
                              && (item?.GroupKey?.toLowerCase() === partnerNameFromOnChange?.toLowerCase()))) {
                              newInputFields[index].error = true
                              newInputFields[index].message = "Partner Account Already Exists!"
                            }
                            else if (data?.value?.length === 12) {
                              if (whiteListTrustedPartner?.data?.some((item) => parseInt(item) === parseInt(data?.value))) {
                                newInputFields[index].error = false
                              }
                              else if (blackListTrustedPartner?.data?.some((item) => parseInt(item) === parseInt(data?.value))) {
                                newInputFields[index].error = true
                                newInputFields[index].message = "Partner account is not approved!"
                              }
                              else {
                                setAccountDetail({ data: [], isLoading: true })
                                //To check account is blacklisted or not.
                                let url = commercialCloud === props?.selectCloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
                                axios.get(`${url}query?Table=${'ACCOUNTS_TABLE'}&Key=${'AccountId'}&Value=${data?.value}`,
                                  {
                                    headers: {
                                      'Authorization': token
                                    }
                                  }).then((res => {

                                    if (res.status === 200) {
                                      newInputFields[index].error = true
                                      newInputFields[index].message = "Please enter a valid trusted partner account!"
                                      setAccountDetail({ data: res.data, isLoading: false })
                                    }
                                    else {
                                      setAccountDetail({ data: [], isLoading: false })
                                    }

                                  })).catch(error => {
                                    setAccountDetail({ data: [], isLoading: false })
                                  })
                              }
                            }
                            setPartnerIds(newInputFields)
                          }
                        }
                      }}
                      style={{ padding: 0, width: "80%" }}
                      placeholder="e.g., 601306020600"
                      error={item?.error}
                    />

                    {partnerIds.length > 1 ?
                      (<Icon style={{ padding: "5px 10px 0px 10px", cursor: "pointer" }} onClick={() => handleRemoveInputFields(index)} name="delete" />
                      ) : null}
                  </div>
                </div>
                {item?.error ? (<div className="ui basic"
                  style={{ color: "#db2828", marginBottom: "7px", marginTop: "7px", fontSize: "10px" }}>
                  {item?.message}</div>) :
                  null}
              </div>
            ))}
           
          </div>
          <div style={{ width: "90%", display: "flex", flexDirection: "row-reverse" }}>
                  <Button
                    disabled={((partnerIds[partnerIds?.length - 1].value?.length >= 12) && (!partnerIds[partnerIds?.length - 1].error)) ? false : true}
                    onClick={handleAddMoreInputFields}
                    style={{
                      fontSize: "x-small",
                      color: "#2A7DE1",
                      backgroundColor: "#fff",
                      padding: "0px",
                      marginTop: "15px"
                    }}>Add more</Button>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button info onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button style={{ backgroundColor: "rgb(42, 125, 225)", color: "#fff" }}
            onClick={handleUpdateTrustedPartner}
            disabled={(partnerIds.some(item => item.error) || partnerIds.some((item) => item.value === ""))}>
            Update
          </Button>
        </Modal.Actions>
      </Modal>
      <fieldset style={{ marginTop: 40, border: "1px solid #cfcfcf" }}>
        <legend style={{ padding: "5px 10px", backgroundColor: "rgb(42, 125, 225)", color: "#fff" }}>Update Trusted Partner</legend>
        <div style={{ marginTop: "30px", padding: 10 }}>
          <Grid columns={3} stackable>
            <Grid.Row>
              <Grid.Column>
                <div><label style={{ fontWeight: 700, fontSize: "12px" }}>Select Trusted Partner<span style={{ color: '#db2828' }}>*</span></label></div>
               <div style={{display:"flex"}}>
               <Select
                  search
                  loading={metadata?.isLoading}
                  placeholder='Select'
                  options={metadata.listOfTrustedPartner}
                  style={{ minWidth: "90%" }}
                  onChange={(e, data) => {
                    setPartnerNameFromOnChange(data.value)
                  }}
                  value={partnerNameFromOnChange}
                />
                 {partnerNameFromOnChange !== "" ? (<div style={{marginLeft:50}}>
                  <Button onClick={() => handleOpenModal()} style={{ backgroundColor: "rgb(42, 125, 225)", color: "#fff", fontSize: 13, width:"180px" }}
                  >
                    Update Partner
                  </Button>
                </div>) : null}
               </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div style={{ marginTop: "30px", width:400 }}>
            {metadata.data?.filter((item) => item.GroupKey.toLowerCase() ===
             partnerNameFromOnChange.toLowerCase())?.length > 0 ? (
              <Table celled padded>
                <Table.Header>
                  <Table.Row  center>
                    {["Partner Account ID"].map(
                      (item, index) => (<Table.HeaderCell textAlign="center" key={index} >{item}</Table.HeaderCell>))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {!metadata?.isLoading ? (metadata.data?.filter((item) => item.GroupKey.toLowerCase() ===
                    partnerNameFromOnChange.toLowerCase())?.map((seletedItem, index) => (
                      <Table.Row key={index}>
                        <Table.Cell textAlign="center">
                          <b>{seletedItem?.MetadataValue}</b>
                        </Table.Cell>
                      </Table.Row>
                    ))) : <>Loading...</>}
                </Table.Body>
              </Table>
            ) : null}
          </div>
        </div>
      </fieldset>
    </React.Fragment>
  )
}