import React, { Component } from "react";
import MetaDataFormComp from "./MetaDataFormComp";

class MetaDataFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimmerActive:true,
      user:'',
      operationMode:'create',
    };
  }

  changeReceivedValue=()=>{
    this.setState({['user']:this.props.authState.accessToken.claims.sub})
    
  }

  componentDidMount(){
    if((window.location.pathname).includes('create')){
      this.setState({['operationMode']:'Create'})
    }
    else{
      this.setState({['operationMode']:'Update'})
    }
  }

  render() {
    return (
      <MetaDataFormComp
      {...this.state}
      accessData={this.props}
    />
    )
  }
}

export default MetaDataFormContainer;