import React, { Component } from "react";
import {
  Form,
  Segment,
  Confirm,
  Button,
  Message,
  Modal,
  Header,
  Dimmer,
  Loader,
  Popup
} from "semantic-ui-react";
import ReactTooltip from "react-tooltip";
import { DateInput } from "semantic-ui-calendar-react";
import ToastMessage from "../../../../ReUsableComponent/ToastMessageComp";
import { cloudCompatibilityOption } from "../../../../../Config/appConstant";


const resource_type_options = [
  { key: "d", text: "AWS::DynamoDB::Table", value: "AWS::DynamoDB::Table" },
  { key: "r1", text: "AWS::RDS::DBInstance", value: "AWS::RDS::DBInstance" },
  { key: "r2", text: "AWS::RDS::DBSecurityGroup", value: "AWS::RDS::DBSecurityGroup" },
  { key: "r3", text: "AWS::RDS::DBSnapshot", value: "AWS::RDS::DBInstance" },
  { key: "r4", text: "AWS::RDS::DBSubnetGroup", value: "AWS::RDS::DBInstance" },
  { key: "r5", text: "AWS::RDS::EventSubscription	", value: "AWS::RDS::EventSubscription	" },
  { key: "r6", text: "AWS::RDS::DBCluster", value: "AWS::RDS::DBCluster" },
  { key: "r7", text: "AWS::RDS::DBClusterSnapshot", value: "AWS::RDS::DBClusterSnapshot" },
  { key: "r8", text: "AWS::EC2::Instance", value: "AWS::EC2::Instance" },
  { key: "r9", text: "AWS::AutoScaling::AutoScalingGroup", value: "AWS::AutoScaling::AutoScalingGroup" },
  { key: "r10", text: "AWS::S3::Bucket", value: "AWS::S3::Bucket" },
  { key: "r11", text: "AWS::IAM::User", value: "AWS::IAM::User" },
  { key: "r12", text: "AWS::IAM::Group", value: "AWS::IAM::Group" },
  { key: "r13", text: "AWS::IAM::Role", value: "AWS::IAM::Role" },
  { key: "r14", text: "AWS::IAM::OIDCProvider", value: "AWS::IAM::OIDCProvider" },
  { key: "r15", text: "AWS::IAM::SAMLProvider", value: "AWS::IAM::SAMLProvider" },
  { key: "r16", text: "AWS::Lambda::Function", value: "AWS::Lambda::Function" },
  { key: "r17", text: "AWS::Redshift::Cluster", value: "AWS::Redshift::Cluster" },
  { key: "r18", text: "AWS::WorkSpaces::Workspace", value: "AWS::WorkSpaces::Workspace" },
  { key: "r19", text: "AWS::EC2::SecurityGroup", value: "AWS::EC2::SecurityGroup" },
  { key: "r20", text: "AWS::CloudTrail::Trail", value: "AWS::CloudTrail::Trail" },
  { key: "r21", text: "AWS::GuardDuty::Detector", value: "AWS::GuardDuty::Detector" },
  { key: "r22", text: "AWS::ElasticLoadBalancing::LoadBalancer", value: "AWS::ElasticLoadBalancing::LoadBalancer" },
  { key: "r23", text: "AWS::ElasticLoadBalancingV2::LoadBalancer", value: "AWS::ElasticLoadBalancingV2::LoadBalancer" },
  { key: "r24", text: "AWS::DirectoryService::SimpleAD", value: "AWS::DirectoryService::SimpleAD" },
  { key: "r25", text: "AWS::EC2::VPCPeeringConnection", value: "AWS::EC2::VPCPeeringConnection" },
  { key: "r26", text: "AWS::EC2::VPC", value: "AWS::EC2::VPC" },
  { key: "r27", text:"AWS::::Account", value: "AWS::::Account" },
  { key: "r28", text:"AWS::IAM::Policy", value: "AWS::IAM::Policy" },
  { key: "r29", text:"AWS::DirectoryService::MicrosoftAD", value: "AWS::DirectoryService::MicrosoftAD" },
];

const global_exception_format_options = [
  { key: "rid", text: "Resource ID", value: "Resource ID" },
  { key: "rarn", text: "Resource ARN ", value: "Resource ARN" }
];

const global_exception_type_options = [
  { key: "s", text: "Simple", value: "Simple" },
  { key: "c", text: "Composite", value: "Composite" },
  { key: "n", text: "Not Applicable", value: "Not_Applicable" },
];

const severity_options = [
  { key: "i", text: "Info", value: "Info" },
  { key: "l", text: "Low", value: "Low" },
  { key: "m", text: "Medium", value: "Medium" },
  { key: "h", text: "High", value: "High" },
  { key: "s", text: "Critical", value: "Critical" },
];

const ccs_reporting_options = [
  { key: "t", text: "True", value: true },
  { key: "f", text: "False", value: false },
];

const action_available_options = [
  { key: "t", text: "Yes", value: true },
  { key: "f", text: "No", value: false },
];

const exception_type_options = [
  { key: "s", text: "Simple", value: "Simple" },
  { key: "c", text: "Composite", value: "Composite" },
  { key: "n", text: "Not Applicable", value: "Not_Applicable" }
];

const trigger_type_options = [
  { key: "cc", text: "Configuration Change", value: "Configuration Change" },
  { key: "p", text: "Periodic", value: "Periodic" },
];

const frequency_options = [
  { key: "one", text: "One Hour", value: "One" },
  { key: "three", text: "Three Hours", value: "Three" },
  { key: "six", text: "Six Hours", value: "Six" },
  { key: "twelve", text: "Twelve Hours", value: "Twelve" },
  { key: "twenty_four", text: "Twenty-Four Hours", value: "Twenty-four" },
];
// Network Security ,Data Protection ,IAM , Vulnerabilities
const Category_options = [
  { key: "d", text: "Data Protection", value: "Data Protection" },
  { key: "twelve", text: "IAM", value: "IAM" },
  { key: "m", text: "Network Security", value: "Network Security" },
  { key: "C", text: "Vulnerabilities", value: "Vulnerabilities" },
];

const exception_format_options = [
  { key: "rid", text: "Resource ID", value: "Resource ID" },
  { key: "rarn", text: "Resource ARN", value: "Resource ARN" }
];

class ConfigRuleEditForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.stringToDate();
    this.props.setEditData();
  }

  createConfirmMsg=()=>{
    return 'Do you want to update the attributes of rule '+this.props.ruleName +'?'
  }

  render() {
    return (
      <Modal
        closeIcon
        open={this.props.openModal && this.props.openOrCloseModal}
        onClose={() => this.props.openOrCloseModalFunc(false)}
      >
        <Segment
          size="mini"
          style={{
            padding: "30px",
            borderColor: "blue",
            borderStyle: "Solid",
            margin: "6px",
          }}
        >
          {this.props.onToastMsg &&<ToastMessage toastMessage={this.props.toastMessage} colour={this.props.toastColour} dismissToastMessage={this.props.dismissToastMessage}/>}
          <Header as='h3' color='blue'>Edit Config Rule</Header>
          {/* <Segment.Group> */}
          <Dimmer active={this.props.dimmerActive} inverted>
            <Loader size='large'>Please wait! Updating Rule...</Loader>
         </Dimmer>
          <Form style={{ width: "1300px" }} onSubmit={this.props.handleSubmit}>
            <Form.Group widths="equal">
              <Popup
                content={'Read Only'}
                trigger={<Form.Input
                  error={this.props.errorPaths.includes("ruleId")}
                  readOnly
                  fluid
                  data-tip
                  data-for="registerTip"
                  label="Rule ID"
                  name="ruleId"
                  value={this.props.ruleId}
                  onChange={this.props.handleChange}
                  style={{ height: "50px" }}
                />}
              />
              <Popup
               content={'Read Only'}
               trigger={
                <Form.Input
                error={this.props.errorPaths.includes("ruleName")}
                fluid
                data-tip
                data-for="registerTop"
                label="Rule Name"
                readOnly
                placeholder="VA-ec2-public-ip"
                name="ruleName"
                value={this.props.ruleName}
                onChange={this.props.handleChange}
                style={{ height: "50px" }}
              />
               }   
              />
              
              <Form.Select
              multiple
              search
              label="Applicable Resources"
              name="applicableResources"
              value={this.props.applicableResources}
              options={resource_type_options}
              onChange={this.props.handleDropDownChange}
              placeholder="Applicable resources"
              style={{ height: "50px" }}
            />
            </Form.Group>

            <Form.Group widths="equal" style={{ marginTop: "25px" }}>
              <Form.Select
                label="Category"
                options={Category_options}
                placeholder=""
                onChange={this.props.handleDropDownChange}
                name="category"
                value={this.props.category}
                style={{ height: "50px" }}
              />
              <Form.Select
                fluid
                label="Severity"
                value={this.props.severity}
                onChange={this.props.handleDropDownChange}
                options={severity_options}
                name="severity"
                placeholder="Severity for the config rule"
                style={{ height: "50px", position: "centre" }}
              />
              <Form.Select
                label="Cloud Compliance Scorecard Reporting"
                options={ccs_reporting_options}
                onChange={this.props.handleDropDownChange}
                placeholder="Cloud Compliance Scorecard Reporting"
                name="ccsReporting"
                value={this.props.ccsReporting}
                style={{ height: "50px" }}
              />
              {/* width: '390px' */}
            </Form.Group>
            <Form.Group widths="equal" style={{ marginTop: "25px" }}>
              <Form.Select
                label="Action Available"
                options={action_available_options}
                value={this.props.actionAvailable}
                required
                name="actionAvailable"
                onChange={this.props.handleDropDownChange}
                placeholder="Resource Remediation Available"
                style={{ height: "50px" }}
              />
              {this.props?.actionAvailable ? (
              <>
              <Form.Select
              label="Default Action Enabled"
              options={[
                { key: 1, text: "True", value: true },
                { key: 2, text: "False", value: false }
              ]}
              value={this.props.defaultActionEnabled}
              required
              data-tip
              data-for="defaultActionEnabled"
              name="defaultActionEnabled"
              onChange={this.props.onChangeActionEnable}
              style={{ height: "50px" }}
            />
            <ReactTooltip id="defaultActionEnabled" type='info' place="top" effect="solid">
              Informational
            </ReactTooltip>
              </>
            ) :null}
              <Form.Select
                label="Exception Type"
                options={exception_type_options}
                name="exceptionType"
                required
                onChange={this.props.handleDropDownChange}
                placeholder="Simple or Complex"
                value={this.props.exceptionType}
                style={{ height: "50px" }}
              />
              {this.props.exceptionType !== "Not_Applicable" && this.props.exceptionType !== "" ? (
              <Form.Select
                label="Exception Format"
                options={exception_format_options}
                required={(this.props.exceptionType === "Not_Applicable" || this.props.exceptionType === "") ? false :true}
                name="exceptionFormat"
                onChange={this.props.handleDropDownChange}
                placeholder="Resource ID or Resource ARN or Resource Name"
                value={this.props.exceptionFormat}
                style={{ height: "50px" }}
              />) : null}
            </Form.Group>
            <Form.Group widths="equal" style={{ marginTop: "25px" }}>
              <Form.Select
                multiple
                label="Trigger Type"
                name="triggerType"
                value={this.props.triggerType}
                options={trigger_type_options}
                onChange={this.props.handleDropDownChange}
                placeholder="Configurational Change or Periodic"
                style={{ height: "floated" }}
                floated
              />
              {(this.props.triggerType?.length!==0 && this.props.triggerType?.includes("Periodic") ) ? (
                <Form.Select
                label="Periodic Frequency"
                value={this.props.periodicFrequency}
                options={frequency_options}
                name="periodicFrequency"
                placeholder="One_Hour"
                onChange={this.props.handleDropDownChange}
                style={{ height: "50px" }}
              />
              ) : null}
              
              {/* value={this.props.dataToEdit.RuleReleaseDate} */}
              <Popup
              content={'Read Only'}
              trigger={<Form.Input
                fluid
                label="Rule Release Date"
                readOnly
                type="date"
                name="ruleReleaseDate"
                onChange={this.props.handleChange}
                value={this.props.ruleReleaseDate}
                placeholder="calendar date"
                style={{ height: "50px" }}
              />}
              />
            </Form.Group>
            <Form.Group widths="equal" style={{ marginTop: "25px" }}>
          <Form.Select
              label="Global Exception Type"
              data-tip
              data-for="periodicFreqTip"
              value={this.props.globalExceptionType}
              options={global_exception_type_options}
              name="globalExceptionType"
              placeholder="Not Applicable"
              onChange={this.props.handleDropDownChange}
              style={{ height: "50px" }}
            />
            {(this.props.globalExceptionType !== "Not_Applicable" && this.props.globalExceptionType !== "") ? (
            <Form.Select
              multiple
              label="Global Exception Format"
              name="globalExceptionFormat"
              data-tip
              data-for="globalExceptionFormatTip"
              value={this.props.globalExceptionFormat}
              required={(this.props.globalExceptionType === "Not_Applicable" || this.props.globalExceptionType === "") ? false :true}
              options={global_exception_format_options}
              onChange={this.props.handleDropDownChange}
              placeholder="Resource ID"
              style={{ height: "50px" }}
            />) : null}
            <ReactTooltip id="triggerTypeTip" type='info' place="top" effect="solid">
              Configurational
            </ReactTooltip>
            <ReactTooltip id="periodicFreqTip" type='info' place="top" effect="solid">
              Configurational
            </ReactTooltip>
            <ReactTooltip id="globalExceptionFormatTip" type='info' place="top" effect="solid">
              Configurational
            </ReactTooltip>
            <Form.Select
              multiple
              label="Cloud Compatibility"
              name="cloudCompatibility"
              value={this.props.cloudCompatibility}
              required
              options={cloudCompatibilityOption}
              onChange={this.props.handleDropDownChange}
              placeholder="Select Cloud Compatibility"
              style={{ minHeight: "50px" }}
              data-tip
              data-for="cloudCompatibilityTip"
            />
         
          </Form.Group>

            <Form.Group style={{ marginTop: "25px" }}>
              <Confirm
                open={this.props.open}
                content = {
                  this.createConfirmMsg()
                }
                onCancel={this.props.handleCancel}
                onConfirm={this.props.handleConfirm}
              />
              <Form.Button
                type="submit"
                size="medium"
                value="Submit"
                style={{ backgroundColor: "#13a6d8" }}
                disabled={
                  this.props.triggerType?.length < 1 ||
                  this.props.category?.length < 1 ||
                  this.props.severity?.length < 1 ||
                  this.props.ccsReporting?.length < 1 ||
                  this.props.exceptionType?.length < 1 ||
                  this.props.globalExceptionType?.length < 1 ||
                  this.props.cloudCompatibility?.length < 1 ||
                  this.props.actionAvailable?.length < 1 ||
                  (this.props.exceptionType != 'Not_Applicable' && this.props.exceptionFormat?.length < 1) ||
                  (this.props.globalExceptionType != 'Not_Applicable' && this.props.globalExceptionFormat?.length < 1) ||
                  (this.props.triggerType?.includes("Periodic") && this.props.periodicFrequency?.length < 1)
                }
              >
                Update
              </Form.Button>
            </Form.Group>
            <Message
              visible={!!this.props.errors.length}
              warning
              header="Please correct the following issues: "
              list={this.props.errors}
            />
          </Form>
          {/* </Segment.Group> */}
        </Segment>
      </Modal>
    );
  }
}

export default ConfigRuleEditForm;
