import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { commercialCloud, localStorageCloudValue } from '../../../../Config/appConstant'
import { Button, Dimmer, Grid, Icon, Input, Loader, Message, Segment, Select, Table } from 'semantic-ui-react'
import CloudModal from './CloudModal'
import config from "../../../../Config/Config";
import { listOfRuleInS3BucketExists } from './service'
import RestoreModal from './ConfirmRestoreModal'

export default function RestoreRemediation(props) {
    const [selectCloud, setSelectCloud] = useState("")
    const [openModal, setOpenModal] = useState(true)
    const [ruleOptions, setRuleOptions] = useState([])
    const [ruleFileName, setRuleFileName] = useState("")
    const [ruleFileData, setRuleFileData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isRestoreProgress, setIsRestoreProgress] = useState(false)
    const [isMessageVisible, setMessageVisible] = useState(false)
    const [toastMessage, setToastMessage] = useState("")
    const [color, setColor] = useState("")
    const [search, setSearch] = useState("")
    const [restoreModal, setRestoreModal] = useState(false)
    const handleRestore = (data) => {
        let url = commercialCloud === selectCloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
        let payload = {
            LastModifiedBy: props?.authState?.accessToken?.claims?.sub,
            Accounts: data,
            RuleName: ruleFileName
        }
        setIsRestoreProgress(true)
        axios.put(`${url}snapshot/restore`, payload, {
            headers: {
                Authorization: props?.authState?.accessToken?.accessToken
            }
        }).then(res => {
            setIsRestoreProgress(false)
            setMessageVisible(true)
            setColor("green")
            setToastMessage("Successfully restored!")
            setRuleFileData(null)
            setRuleFileName("")
            setRuleOptions([])
            listOfRuleInS3BucketExists(selectCloud, props?.authState?.accessToken?.accessToken).then((res) => {
                setRuleOptions(res?.data?.data)
            }).catch(err => {
                console.error(err)
            })
        }).catch(err => {
            setIsRestoreProgress(false)
            console.error(err)
            setIsLoading(false)
            setMessageVisible(true)
            setColor("red")
            setToastMessage("Failed to restore!")
        })
    }

    const handleFileData = (fileName) => {
        setIsLoading(true)
        let url = commercialCloud === selectCloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
        axios.get(`${url}snapshot/search?fileName=${fileName}`, {
            headers: {
                Authorization: props?.authState?.accessToken?.accessToken
            }
        }).then(res => {
            setIsLoading(false)
            setRuleFileName(fileName)
            setRuleFileData(res.data?.data)
        }).catch(err => {
            console.error(err)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        let value = localStorage.getItem(localStorageCloudValue)
        if (value !== null && value !== "") {
            setSelectCloud(value)
            setOpenModal(false)
            let token = props?.authState?.accessToken?.accessToken
            if (token) {
                setRuleOptions([])
                setRuleFileData(null)
                setRuleFileName("")
                listOfRuleInS3BucketExists(value, token).then((res) => {
                    setRuleOptions(res?.data?.data)
                }).catch(err => {
                    console.error(err)
                })
            }
        }
    }, [selectCloud])
    const filteredData = ruleFileData?.Accounts?.filter(item => {
        return JSON.stringify(item?.AccountId)?.toLowerCase().includes(search?.trim().toLowerCase())
            || JSON.stringify(item?.AccountName)?.toLowerCase().includes(search?.trim().toLowerCase())
            || JSON.stringify(item?.ActionEnabled)?.toLowerCase().includes(search?.trim().toLowerCase())
    })
    return (
        <React.Fragment>
            <Segment
                size="mini"
                style={{
                    padding: "30px",
                    margin: "6px",
                    boxShadow: "none",
                    border: "none"
                }}>
                <Dimmer active={isRestoreProgress} inverted>
                    <Loader size='large'>Please wait!</Loader>
                </Dimmer>
                <RestoreModal openModal={restoreModal} closeModal={()=>{
                    setRestoreModal(false)
                }} title={"Restore Action"}

                    handleSubmit={()=>{
                        setRestoreModal(false)
                        handleRestore(ruleFileData?.Accounts)
                    }}
                >
                    Are you sure you want to restore <b>{ruleOptions?.filter((item)=>item.value === ruleFileName)[0]?.text}</b> in {selectCloud === "aws" ? "Commercial Cloud" : "GovCloud"} ?
                </RestoreModal>
                {isMessageVisible && (<Message
                    size="large"
                    onDismiss={() => {
                        setMessageVisible(false)
                    }}
                    color={color}
                >
                    <Icon name="announcement" />
                    {toastMessage}
                </Message>)}
                <CloudModal title={"AutoRemediation Snapshot"} openModal={openModal} selectCloud={selectCloud} setOpenModal={setOpenModal} setSelectCloud={setSelectCloud} />
                <div style={{ padding: 10, display: "flex", width: "100%" }}>

                    <div style={{ minWidth: "420px" }}>
                        <div>
                            <label style={{ fontWeight: 700, fontSize: "12px" }}>Config Rule<span style={{ color: '#db2828' }}>*</span></label></div>
                        <Select
                            search
                            placeholder='Select'
                            options={ruleOptions}
                            style={{ minWidth: "90%" }}
                            onChange={(e, data) => {
                                setRuleFileName(data?.value)
                                handleFileData(data?.value)
                            }}
                        />
                    </div>
                    <div style={{ marginTop: "15px" }}>
                        <Button disabled={ruleFileName==="" ? true : false} onClick={() => {
                            setRestoreModal(true)
                        }}>Restore</Button>
                    </div>

                </div>
                <div style={{ padding: 10 }}>
                    {!isLoading ? (
                        <>
                            {(ruleFileData !== null) ? (
                                <div style={{ width: "100%", position: "relative", maxHeight: "500px", overflowY: "scroll" }}>
                                    <Table celled padded>
                                        <Table.Header style={{ position: "sticky", top: 0 }} >
                                            <Table.HeaderCell colSpan='3' >
                                                <Table.Row>
                                                    <Table.Cell style={{ border: "none" }}>
                                                        <b style={{ fontSize: ".9rem" }}>Snapshot Taken By:</b>
                                                    </Table.Cell>
                                                    <Table.Cell style={{ border: "none" }}>
                                                        {ruleFileData?.LastModifiedBy}
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row >
                                                    <Table.Cell style={{ border: "none" }}>
                                                        <b style={{ fontSize: ".9rem" }}>Timestamp:</b>
                                                    </Table.Cell>
                                                    <Table.Cell style={{ border: "none" }}>
                                                        {(new Date(ruleFileData?.Timestamp))?.toUTCString()}
                                                    </Table.Cell>
                                                    <Table.Cell style={{ border: "none" }}>
                                                        <div style={{ display: "flex", flexDirection: "row-reverse", width: "100", position:"absolute",right:200,marginTop:-40 }}>

                                                            <Input icon placeholder='Search...' onChange={(e, data) => {
                                                                setSearch(data.value)
                                                            }}>
                                                                <input />
                                                                <Icon name='search' />
                                                            </Input>
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.HeaderCell>
                                            <Table.Row>
                                                {["Account Name", "Account Id", "Action Enabled"].map(
                                                    (item, key) => (<Table.HeaderCell key={key} style={{ fontSize: ".9rem" }} textAlign="center" >{item}</Table.HeaderCell>))}
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {filteredData?.map((item, index) => {
                                                return {
                                                    ...item,
                                                    index: index
                                                }
                                            })?.map((seletedItem, index) => (
                                                <Table.Row key={index}>
                                                    <Table.Cell textAlign="center">
                                                        <b>{seletedItem?.AccountName}</b>
                                                    </Table.Cell>
                                                    <Table.Cell textAlign='center' >
                                                        <b>{seletedItem?.AccountId}</b>
                                                    </Table.Cell>
                                                    <Table.Cell textAlign='center'>
                                                        <b>{seletedItem?.ActionEnabled}</b>
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                    {filteredData?.length === 0 ?(<>Not Found!</>) : null}
                                </div>

                            ) : null}
                        </>
                    ) : (<>Loading...</>)}
                </div>
            </Segment>
        </React.Fragment>

    )
}
