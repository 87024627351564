import React, { Component } from "react";
import {Message,Icon } from "semantic-ui-react";

// Added code fpr toast message

class ToastMessage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <Message
        size="large"
        onDismiss={() => {
          this.props.dismissToastMessage();
        }}
        color={this.props.colour}
      >
        <Icon name="announcement" />
        {this.props.toastMessage}
      </Message>
    );
  }
}

export default ToastMessage;
// color={this.state.responseStatus ? "green" : "red"}