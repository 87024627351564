import React, { Component } from 'react'
import axios from 'axios'
import { Header, Segment, Select } from 'semantic-ui-react'
import { Button, Form, Dimmer, Loader } from 'semantic-ui-react'
import config from '../../../../Config/Config'
import SearchResult from './SearchResult'
import { severityKey } from './reportConstant'
import { cloudCompatibilityOption, commercialCloud, localStorageCloudValue } from '../../../../Config/appConstant'
export default class AutoRemediationConfigRule extends Component {
  constructor(props) {
    super(props)

    this.state = {
      rules: { data: [], options: [], isLoading: false },
      data: [],
      form: {
        rule: '',
        severity: ""
      },
      searchedResult: {
        data: [],
        length: 0,
        isLoading: false,
        displayView: false
      },
      selectCloud:"aws"
    }
    this.advanceSearch = this.advanceSearch.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.getConfigRuleLists = this.getConfigRuleLists.bind(this)
    this.handleSelectCloudSpace = this.handleSelectCloudSpace.bind(this)
  }
  onChangeHandler = (e, data) => {
    const { form, rules } = this.state
    if (data.name === "severity") {
      const optionList = rules.data?.filter((item) =>
        (item.ActionAvailable !== false && item.Severity?.toLowerCase() === data?.value?.toLowerCase())).map((item) => {
          return {
            key: item.RuleId,
            text: `${item.RuleId} ${item.RuleName}`,
            value: item.RuleId
          }
        })
      const optionListAfterClear = rules.data?.filter((item) => item.ActionAvailable !== false).map((item) => {
        return {
          key: item.RuleId,
          text: `${item.RuleId} ${item.RuleName}`,
          value: item.RuleId
        }
      })
      this.setState({
        ...this.state,
        rules: { ...this.state.rules, options: (!e.target.className.includes('clear')) ? optionList : optionListAfterClear, isLoading: false },
        form: { rule: "", severity: data.value }
      })
    }
    else {
      if (form.rule === "") {
        let severity = rules.data?.find((item) => parseInt(item?.RuleId) === parseInt(data.value))?.Severity
        const optionList = rules.data.filter((item) =>
          (item.ActionAvailable !== false && item.Severity?.toLowerCase() === severity?.toLowerCase())).map((item) => {
            return {
              key: item.RuleId,
              text: `${item.RuleId} ${item.RuleName}`,
              value: item.RuleId
            }
          })
        this.setState({
          ...this.state, form: { rule: data.value, severity: severity },
          searchedResult: { ...this.state.searchedResult, data: [], displayView: false },
          rules: { ...this.state.rules, options: optionList }
        })
      }
      else {
        this.setState({
          ...this.state, form: { ...this.state.form, [data.name]: data.value },
          searchedResult: { ...this.state.searchedResult, data: [], displayView: false }
        })
      }
    }
  }
  advanceSearch = () => {
    const { form } = this.state
    this.setState({
      ...this.state,
      searchedResult: {
        ...this.state.searchedResult,
        isLoading: true,
        displayView: false
      }
    })

    let query = [
      {
        key: "RuleId",
        value: form.rule
      },
      {
        key: "Table",
        value: "ACCOUNT_ATTR_TABLE"
      }
    ]

    let search = ""

    query.map((item) => {
      if (search !== "") {
        if (item.value) {
          search += `&${item.key}=${item.value}`
        }
      }
      else {
        if (item.value) {
          search += `${item.key}=${item.value}`
        }
      }
    })
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    this.props.authState &&
      axios.get(`${selectedUrl}advance-search?${search}`, {
        headers: {
          Authorization: this.props.authState.accessToken.accessToken
        }
      }).then(res => {

        this.setState({
          ...this.state,
          searchedResult: {
            ...this.state.searchedResult,
            data: {
              body: {
                data: res.data?.body.data.map((item) => {
                  return {
                    ...item,
                    ActionEnabled: `${item?.ActionEnabled?.slice(0, 1).toUpperCase()}${item?.ActionEnabled?.slice(1).toLowerCase()}`
                  }
                })
              }
            },
            length: res.data?.body?.data?.length || 0,
            isLoading: false,
            displayView: true
          }
        })
      }).catch(err => {
        console.error(err)
        this.setState({
          ...this.state,
          searchedResult: {
            ...this.state.searchedResult,
            isLoading: false,
            displayView: false
          }
        })
      })
  }
  getConfigRuleLists = ()=>{
    this.setState({ rules: { ...this.state.rules, isLoading: true } })
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    this.props.authState.accessToken && axios.get(`${selectedUrl}configrules`, {
      headers: {
        Authorization: this.props.authState.accessToken.accessToken
      }
    }).then((res) => {
      const result = res?.data?.body?.data;
      const optionList = result.filter((item) => item.ActionAvailable !== false).map((item) => {
        return {
          key: item.RuleId,
          text: `${item.RuleId} ${item.RuleName}`,
          value: item.RuleId
        }
      })

      this.setState({ ...this.state, rules: { ...this.state.rules, data: result, options: optionList, isLoading: false } })
    }).catch(err => {
      console.error(err)
      this.setState({ ...this.state, rules: { ...this.state.rules, isLoading: false } })
    })
  }
  handleSelectCloudSpace = (event, data) => {
    localStorage.setItem(localStorageCloudValue,data?.value)
    this.setState((prevState) => ({
        ...prevState, [data.name]: data.value,
        searchedResult: { ...prevState.searchedResult, data: [], displayView: false, length: -1 },
        form: {rule: '', severity: ""}
    }), () => {
        this.getConfigRuleLists()
    });
};
  componentDidMount() {
    
    let value = localStorage.getItem(localStorageCloudValue)
    if(value !== null && value !==""){
      this.handleSelectCloudSpace({},{name:"selectCloud",value:value})
    }
    else{
      this.getConfigRuleLists()
    }
  }
  render() {
    const { form, searchedResult } = this.state
    return (
      <React.Fragment>
        <Segment
          size="mini"
          style={{
            padding: "30px",
            borderColor: "blue",
            borderStyle: "Solid",
            margin: "6px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
                    <Header as="h3" color="blue"> Auto-remediation report based on severity for rules.</Header>
                    <div>
                        <b style={{ fontSize: 13, marginBottom: 2 }}>Selected organization:</b>
                        <Select
                            fluid
                            label='Select Cloud'
                            options={cloudCompatibilityOption}
                            value={this.state.selectCloud}
                            name="selectCloud"
                            onChange={this.handleSelectCloudSpace}
                            placeholder='Select Cloud'
                            style={{ position: "centre", width: "150px" }}
                        />
                    </div>
                </div>
          <Form onSubmit={this.advanceSearch} id="rule-search">
            <Dimmer active={searchedResult.isLoading} inverted ><Loader /></Dimmer>
            <Form.Group >
              <Form.Field
                control={Select}
                options={[
                  {
                    key: 1,
                    text: severityKey[0],
                    value: severityKey[0]
                  },
                  {
                    key: 2,
                    text: severityKey[1],
                    value: severityKey[1]
                  },
                  {
                    key: 3,
                    text: severityKey[2],
                    value: severityKey[2]
                  },
                  {
                    key: 4,
                    text: severityKey[3],
                    value: severityKey[3]
                  },
                  {
                    key: 5,
                    text: severityKey[4],
                    value: severityKey[4]
                  }
                ]}
                label={{ children: 'Severity', htmlFor: 'form-select-severity' }}
                placeholder='Select or search severity'
                search
                clearable
                searchInput={{ id: 'form-select-severity' }}
                style={{ width: "21em" }}
                name="severity"
                value={form.severity}
                onChange={(e, data) => this.onChangeHandler(e, data)}
              />
              <Form.Field
                control={Select}
                options={this.state.rules.options}
                label={{ children: 'Rule Identifier', htmlFor: 'form-select-config-rule' }}
                placeholder='Select or search rule'
                clearable
                search
                searchInput={{ id: 'form-select-rule' }}
                style={{ width: "21em" }}
                name="rule"
                value={form.rule}
                loading={this.state.rules.isLoading}
                onChange={(e, data) => this.onChangeHandler(e, data)}
              />
              <Button style={{ height: "40px", marginTop: "23px" }} type='submit' disabled={form.rule === "" ? true : false}>Search</Button>
            </Form.Group>

          </Form>
          {(form.rule !== "") && searchedResult.displayView && searchedResult.data !== null
            ? (<SearchResult {...this.state} />)
            : searchedResult.data === null ? 'No account found!' : ('')}
        </Segment>
      </React.Fragment>

    )
  }
}
