import React, { Component } from 'react'
import Papa from 'papaparse'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { Table, Popup, Pagination, Icon, Input, Dropdown, Image, Button, Grid, Checkbox } from 'semantic-ui-react'
import { tableHeader, dataPerPageOptions } from './reportConstant'
import FilterBox from './FilterBox'
import csvIcon from '../../../../Assets/Images/csv_icon.png'
export default class SearchResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      dataPerPage: 10,
      sortTable: false,
      toggleTableView: true,
      form: {
        actionEnabled: '',
        exceptionsCheckEnabled: '',
        search: ''
      },
      sortTable: false,
      sortTableHeaderIndex: -1,
      storeAccountsData: [],
      storeCheckAccountsData: [],
      downloadMarkedDataRecord: [],
      downloadMarkedAllData: false,
      rules:[]
    }
    this.handlePageChange = this.handlePageChange.bind(this)
    this.onDropdownChangeHandler = this.onDropdownChangeHandler.bind(this)
    this.onSearchHandler = this.onSearchHandler.bind(this)
    this.handleDownloadCsv = this.handleDownloadCsv.bind(this)
    this.handleDownloadPdf = this.handleDownloadPdf.bind(this)
    this.checkboxHandler = this.checkboxHandler.bind(this)
    this.handleSortTable = this.handleSortTable.bind(this)
  }
  handlePageChange = (event, data) => {
    this.setState({ ...this.state, currentPage: data.activePage });
  }
  onSearchHandler = () => {
    const { form } = this.state
    const copyStoreAccountsData = this.props.searchedResult?.data?.body?.data

    let filterStoreAccountsData = []
    if (form.actionEnabled === "" && form.exceptionsCheckEnabled === "") {
      filterStoreAccountsData = copyStoreAccountsData
    }
    else if (form.actionEnabled !== "" && form.exceptionsCheckEnabled !== "") {
      filterStoreAccountsData = copyStoreAccountsData?.filter((item) => {
        return (item.ActionEnabled === form.actionEnabled)
          && (item.ExceptionsCheckEnabled === form.exceptionsCheckEnabled)
      })
    }
    else {
      filterStoreAccountsData = copyStoreAccountsData.filter(item => {
        if (form.actionEnabled !== "" && form.exceptionsCheckEnabled === "") {
          return item.ActionEnabled === form.actionEnabled
        }
        else if (form.actionEnabled === "" && (typeof form.exceptionsCheckEnabled === "boolean")) {
          return item.ExceptionsCheckEnabled === form.exceptionsCheckEnabled
        }
        else {
          return (item.ActionEnabled === form.actionEnabled)
            && (typeof item.ExceptionsCheckEnabled === typeof form.exceptionsCheckEnabled)
        }
      });
    }
    this.setState({ ...this.state, storeAccountsData: filterStoreAccountsData, currentPage: 1 })
  }
  onDropdownChangeHandler = (e, data) => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, [data.name]: data.value, search: '' }
    }, () => {
      this.onSearchHandler()
    })
  }
  handleDownloadCsv = (data, fileName) => {
    if (!data && !fileName) return
    const downloadData = data.map((item) => {
      return {
        RuleId: item.RuleId, RuleName: item.RuleName, AccountId: item.AccountId,
        AccountName: item.AccountName,
        RuleMonitored: item.ExceptionsCheckEnabled,
        ActionEnabled: item.ActionEnabled
      }
    })
    const csv = Papa.unparse(downloadData);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${fileName}.csv`);
    tempLink.click();
  }
  handleDownloadPdf = (body, header, fileName) => {
    if (!body && !header && !fileName) return
    const pdf = new jsPDF("p", "px")
    const now = new Date();
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    const orderedHeader = header.map((item) => item.text)
    const orderedBody = body?.map((item) => {
      return [
        item.AccountId,item.AccountName, item.ExceptionsCheckEnabled,item.ActionEnabled
      ]
    })
    const findText = this.props.rules.options?.find((item) => item.value === this.props.form.rule)?.text
    const splitText = findText?.split(" ")[1]
    const getSeverity = this.state.rules?.find((item)=>parseInt(item?.RuleId)===parseInt(this.props.form.rule))?.Severity
    pdf.setFontSize(10);
    pdf.text(`${getSeverity} - ${splitText} - ${this.props.form.rule} Account-level Remediation Report.`, 25, 20);
    autoTable(pdf, {
      head: [orderedHeader],
      body: orderedBody,
      theme: 'grid',
      styles: { fontSize: 8, cellWidth: "wrap" },
      columnStyles: {
        0: {
          cellWidth: 100
        },
        1: {
          cellWidth: 100
        },
        2: {
          cellWidth: 100
        }
      },
      didDrawPage: (data) => {
        var pageStr = `Page ${data.pageNumber}. `
        var dateTimeStr = `Report generated on ${now.toLocaleString('en-US', options)}`
        var pageSize = pdf.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        pdf.setFontSize(9)
        pdf.text(dateTimeStr, 30, pageHeight - 20);
        pdf.text(pageStr, pageSize.width - 50, pageHeight - 20);
      }
    })
    pdf.save(`${fileName}.pdf`);
  }
  checkboxHandler = (e, data, item) => {
    let checkedData = this.state.storeCheckAccountsData
    if (data.checked) {
      let isDataExists = checkedData.some(value => value.index === item.index)
      if (!isDataExists) {
        checkedData.push(item)
        this.setState({ ...this.state, storeCheckAccountsData: checkedData })
      }
    }
    else {
      this.setState({
        ...this.state,
        storeCheckAccountsData: checkedData?.filter((value) => value.index !== item.index)
      })
    }
  }
  handleSortTable = (value, index) => {
    this.setState({
      ...this.state,
      sortTable: !this.state.sortTable,
      sortTableHeaderIndex: index,
      currentPage: 1,
      storeAccountsData: this.state.storeAccountsData.sort((a, b) => {
        if (this.state.sortTable) {
          if (a[value] < b[value]) return -1;
          if (a[value] > b[value]) return 1;
        }
        else {
          if (a[value] > b[value]) return -1;
          if (a[value] < b[value]) return 1;
        }
        return 0;
      })
    })

  }
  componentDidMount() {
    const { searchedResult,rules } = this.props
    this.setState({ ...this.state, storeAccountsData: searchedResult.data?.body.data,rules:rules?.data },()=>{
      const element = document.getElementById("rule-search");
      element.scrollIntoView({ behavior: 'smooth' });
    })
  }
  render() {
    const { searchedResult } = this.props
    const { currentPage, dataPerPage, toggleTableView, form, storeAccountsData,
       downloadMarkedDataRecord, storeCheckAccountsData, downloadMarkedAllData ,rules} = this.state
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const addIndexInStoreAccountsData = storeAccountsData?.filter(item => {
      return JSON.stringify(item?.AccountId)?.toLowerCase()?.includes(form.search.trim().toLowerCase()) ||
        JSON.stringify(item?.ActionEnabled)?.toLowerCase()?.includes(form.search.trim().toLowerCase())||
        JSON.stringify(item?.AccountName)?.toLowerCase()?.includes(form.search.trim().toLowerCase())
    })?.map((item, index) => {
      return {
        ...item,
        index: index
      }
    })
    const currentData = searchedResult.data && addIndexInStoreAccountsData?.slice(indexOfFirstData, indexOfLastData);

    const RenderTableView = () => (
      <React.Fragment>
        <div style={{ width: "100%", overflow: 'scroll', maxHeight: "65vh", marginTop: "10px" }}>
          <Table celled striped sortable id="pdf-view">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Popup
                    content={true ? "Unmarked all" : "Marked all for download!"}
                    trigger={
                      <Checkbox checked={downloadMarkedDataRecord.includes(currentPage)} onChange={(e, data) => {
                        if (data.checked) {
                          let filterData = currentData.filter((item) => !storeCheckAccountsData.some((value) => value.index === item.index))
                          this.setState({
                            ...this.state, storeCheckAccountsData: storeCheckAccountsData.concat(filterData),
                            downloadMarkedAllData: !downloadMarkedAllData, downloadMarkedDataRecord: [...downloadMarkedDataRecord, currentPage]
                          })
                        }
                        else {
                          this.setState({
                            ...this.state, storeCheckAccountsData: storeCheckAccountsData.filter((item) => {
                              return !currentData.some((res) => res.index === item.index)
                            }), downloadMarkedAllData: !downloadMarkedAllData, downloadMarkedDataRecord: downloadMarkedDataRecord.filter((item) => item !== currentPage)
                          })
                        }
                      }} />
                    } />
                </Table.HeaderCell>
                {tableHeader && tableHeader.map((item, index) => (
                  <Table.HeaderCell
                    key={index}
                    sorted={this.state.sortTable && index === this.state.sortTableHeaderIndex ? 'ascending' : 'descending'}
                    onClick={() => this.handleSortTable(item.value, index)}

                  >
                    {item.text}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                currentData && currentData.length > 0 ? currentData.map((item, index) => (
                  <Table.Row key={index} >
                    <Table.Cell>
                      <Checkbox value={item.RuleId} checked={this.state.storeCheckAccountsData?.some((data) => data.index === item.index)} onChange={(e, data) => {
                        this.checkboxHandler(e, data, item)
                      }} />
                    </Table.Cell>
                    <Table.Cell style={{ minWidth: "70px" }}>
                      {item?.AccountId}
                    </Table.Cell>
                    <Table.Cell style={{ minWidth: "70px" }}>
                      {item?.AccountName}
                    </Table.Cell>
                    <Table.Cell style={{ minWidth: "70px" }}>
                      {item?.ExceptionsCheckEnabled !== undefined ? (item.ExceptionsCheckEnabled ? "true" : "false") : ""}
                    </Table.Cell>
                    <Table.Cell style={{ minWidth: "200px", maxWidth: "250px", wordBreak: "break-all" }}>
                      {item?.ActionEnabled}
                    </Table.Cell>
                  </Table.Row>
                )) : (
                  <Table.Row>
                    <Table.Cell colSpan={`colSpan=${searchedResult.length}`} >
                      No records found!
                    </Table.Cell>
                  </Table.Row>
                )
              }
            </Table.Body>
          </Table>
        </div>
        <div style={{ marginTop: "10px", width: "100%", display: "flex", justifyContent: "space-between" }}>
          <Pagination
            activePage={currentPage}
            totalPages={Math.ceil(addIndexInStoreAccountsData.length / dataPerPage)}
            onPageChange={this.handlePageChange}
            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
          />
          <h3>Results:{`(${addIndexInStoreAccountsData.length})`}</h3>
        </div>
      </React.Fragment>
    )
    return (
      <>
        <Grid id="result" style={{ height: "100vh" }}>
          <Grid.Row>
            <Grid.Column width={3} style={{ display: toggleTableView ? "block" : "none" }}>
              <FilterBox
                form={form}
                onDropdownChangeHandler={this.onDropdownChangeHandler}
              />
            </Grid.Column>
            <Grid.Column width={toggleTableView ? 13 : 16}>
              <div style={{ display: 'flex', width: "100%", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <Button style={{ marginTop: "20px" }} basic icon={toggleTableView ? "arrow left" : "filter"} onClick={() => {
                    this.setState({ ...this.state, toggleTableView: !this.state.toggleTableView })
                  }} />
                  <Input
                    placeholder="Search..."
                    icon="search"
                    style={{ width: "220px", marginTop: "20px" }}
                    value={form.search}
                    onChange={(e, data) => {
                      if (storeCheckAccountsData.length > 0) {
                        this.setState({ ...this.state, currentPage: 1, form: { ...form, search: data.value }, storeCheckAccountsData: [], downloadMarkedAllData: false, downloadMarkedDataRecord: [] })
                      } else {
                        this.setState({ ...this.state, currentPage: 1, form: { ...form, search: data.value } })
                      }
                    }}
                  />
                </div>
                <div style={{ display: 'flex', width: "100%", justifyContent: "flex-end" }}>
                  <div >
                    <Dropdown
                      placeholder='Record per page'
                      selection
                      options={dataPerPageOptions}
                      value={this.state.dataPerPage}
                      onChange={(e, data) => {
                        this.setState({ ...this.state, dataPerPage: data.value, currentPage: 1 })
                      }}
                      style={{ height: "35px", marginTop: "20px", minWidth: 0 }}
                    />
                  </div>
                  <div style={{ marginLeft: "5px", marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Popup
                      content="Download results to CSV"
                      trigger={<Button basic style={{ padding: "1px", height: "36px", width: "40px" }}
                        onClick={() => {
                          let downloadData = [];
                          if (storeCheckAccountsData.length > 0 && form.search === "") {
                            downloadData = storeCheckAccountsData?.map((item) => {
                              const {
                                index, LastModifiedBy, CreateTimeStamp, UpdateTimeStamp
                                , ...rest } = item
                              return rest
                            })
                          }
                          else {
                            downloadData = addIndexInStoreAccountsData?.map((item) => {
                              const {
                                index, LastModifiedBy, CreateTimeStamp, UpdateTimeStamp
                                , ...rest } = item
                              return rest
                            })
                          }
                          this.handleDownloadCsv(downloadData, `Rule_${this.props.form.rule}`)
                        }}
                      >
                        <Image src={csvIcon} style={{ marginLeft: "13px", height: "13.5px" }} />
                      </Button>}
                    />
                    <Popup
                      content="Download results to PDF"
                      trigger={<Button basic icon="file pdf" onClick={() => {
                        let downloadData = [];
                        let headerKey = tableHeader
                        let fileName = `Rule_${this.props.form.rule}`
                        if (storeCheckAccountsData.length > 0 && form.search === "") {
                          downloadData = storeCheckAccountsData?.map((item) => {
                            const {
                              index, LastModifiedBy, CreateTimeStamp, UpdateTimeStamp
                              , ...rest } = item
                            return rest
                          })
                        }
                        else {
                          downloadData = addIndexInStoreAccountsData?.map((item) => {
                            const {
                              index, LastModifiedBy, CreateTimeStamp, UpdateTimeStamp
                              , ...rest } = item
                            return rest
                          })
                        }
                        this.handleDownloadPdf(downloadData, headerKey, fileName)
                      }} />}
                    />
                  </div>
                </div>
              </div>
              <RenderTableView />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }
}
