import React, { useState } from 'react'
import { Button, Grid, Header, Segment, Portal } from 'semantic-ui-react'
import '../../App.css'
const CircularMenu = ({username,children}) =>{
    const [open,setOpen] = useState(false)
    const handleClose = () =>{
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }
    return (
        <Grid columns={2}>
            <Grid.Column>
                <Button
                    icon
                    circular
                    disabled={open}
                    onClick={handleOpen}
                    className="circular-menu-trigger"
                    style={{width:"40px",height:"40px"}}
                >
                   <b> {username ? username.charAt(0).toUpperCase() : '?'}</b>
                </Button>
                <Portal onClose={handleClose} open={open} >
                    <Segment
                        style={{
                            right: '10px',
                            position: 'fixed',
                            top:"50px",
                            zIndex: 1000,
                        }}
                        className="circular-menu" 
                    >
                    {children}
                    </Segment>
                </Portal>
            </Grid.Column>
        </Grid>
    )
}
export default CircularMenu