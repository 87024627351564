import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { severityKey } from './reportConstants'
export default class FilterBox extends Component {
    render() {
        const { onDropdownChangeHandler, form, configRuleOptionLists, exceptionStatusType, regionOptionLists } = this.props
        return (
            <React.Fragment>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginBottom: "10px", marginTop: "60px" }}><h3>Filters:</h3></div>
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <label style={{ fontWeight: "bold" }}>Rule Identifier </label>
                        <Dropdown
                            name={'rule'}
                            placeholder='Select or search rule'
                            selection
                            search
                            clearable
                            options={configRuleOptionLists}
                            value={form.rule}
                            onChange={onDropdownChangeHandler}
                        />
                    </div>

                    {/* <div style={{ display: 'flex', flexDirection: "column" }}>
                        <label style={{ fontWeight: "bold" }}>Status</label>
                        <Dropdown
                            name={'exceptionStatus'}
                            placeholder='Select or search status'
                            selection
                            search
                            clearable
                            value={form.exceptionStatus}
                            options={exceptionStatusType}
                            onChange={onDropdownChangeHandler}
                        />
                    </div> */}

                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <label style={{ fontWeight: "bold" }}>Region</label>
                        <Dropdown
                            name={'region'}
                            placeholder='Select or search region'
                            selection
                            search
                            clearable
                            value={form.region}
                            options={regionOptionLists}
                            onChange={onDropdownChangeHandler}
                        />
                    </div>
                                        <div style={{ display: 'flex', flexDirection: "column" }}>
                        <label style={{ fontWeight: "bold" }}>Severity</label>
                        <Dropdown
                            name={'severity'}
                            placeholder='Select Severity'
                            selection
                            search
                            clearable
                            options={[
                                {
                                    key: 1,
                                    text: severityKey[0],
                                    value: severityKey[0]
                                },
                                {
                                    key: 2,
                                    text: severityKey[1],
                                    value: severityKey[1]
                                },
                                {
                                    key: 3,
                                    text: severityKey[2],
                                    value: severityKey[2]
                                },
                                {
                                    key: 4,
                                    text: severityKey[3],
                                    value: severityKey[3]
                                },
                                {
                                    key: 5,
                                    text: severityKey[4],
                                    value: severityKey[4]
                                }
                            ]}
                            value={form.severity}
                            onChange={onDropdownChangeHandler}
                        />
                    </div>
                </div>

            </React.Fragment>
        )
    }
}
