import React from 'react';
import DropdownPointing from './Menu';
import {withRouter} from 'react-router-dom';

class MenuContainer extends React.Component{
  constructor(props){
      super(props)
      this.state ={
        clicked:'hi'
      }
      this.handleOptionSelection = this.handleOptionSelection.bind(this);
  }

  handleOptionSelection(props)
  {
    //console.log(props);
  }

  render(){
      //console.log(this.props.history.push)
      return(
       <DropdownPointing
       selectedMenuItem = {this.props.history.push}
       {...this.props}/>
      )
  }
}



export default withRouter(MenuContainer);