const initialState = {
    pageToBeLoaded: '',
    // search: [[]],
    // searchLength: 0,
    // searchResultLength: 0,
    // ActiveTable: '',
    configRuleData:[]
  };
  
  const mainPageSelectorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SELECTED_ITEM':
        return {pageToBeLoaded: action.payload};
      case 'GET_RULE_IDENTIFIER_DATA':
        return {
          configRuleData:action.payload
        };
      // case DECREASE_SEARCH_LENGTH:
      //   return {
      //     ...state,
      //     searchLength: state.searchLength - 1,
      //     search: state.search.filter((element, index) => {
      //       return index !== action.payload && element;
      //     }),
      //   };
      // case SET_SEARCH_ATTRIBUTE:
      //   return {
      //     ...state,
      //     search: state.search.filter((elements, index) => {
      //       if (index === action.payload[1]) elements[0] = action.payload[0];
      //       return elements;
      //     }),
      //   };
      // case SET_SEARCH_VALUE:
      //   return {
      //     ...state,
      //     search: state.search.filter((elements, index) => {
      //       if (index === action.payload[1]) elements[1] = action.payload[0];
      //       return elements;
      //     }),
      //   };
      // case SET_ACTIVETABLE:
      //   return {
      //     ...state,
      //     ActiveTable : action.tableName
      //   }
      // case TABLE_DATA_PRESIST:
      //   return {
      //     ...state,
      //     ...action.data,
      //     search: [[]],
      //     searchLength: 0,
      //     searchResultLength: 0,
      //   };
      // case SET_SERACH_RESULT_LENGTH:
      //   return { ...state, searchResultLength: action.value };
      default:
        return state;
    }
  };
  
  export default mainPageSelectorReducer;
  