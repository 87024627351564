import React, { Component } from 'react';
import { Segment,Confirm} from 'semantic-ui-react';

class ConfigAccountDeleteForm extends Component {
  
  createConfirmMsg=()=>{
    return 'Do you want to create the rule '+this.props.accessData.ruleName +'?'
  }

  render() {
      //console.log(this.props)
    return (
      <Segment size='mini' style={{padding:'20px',borderColor:'blue',borderStyle:'Solid',margin:'6px'}}>
        <Confirm
              content = {
                this.createConfirmMsg()
              }
              open={this.props.openDeletePopUp}
              onCancel={this.props.handleDeletePopUpClose}
              onConfirm={this.props.handleDeleteConfirm}
            />
      </Segment>
    )
  }
}

export default ConfigAccountDeleteForm;